import useSafeState from '../../utils/safe-state';
import React, { useEffect } from 'react';
import {Form, Row, Col, Input, Button, Switch, message, Select, Tooltip} from 'antd';
import { Trans } from '@lingui/macro';
import { ShopsModel } from '../../models/shops/shops';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { useAxios } from '../../utils/hooks';
import { AxiosResponse } from 'axios';
import { openNotification } from '../../utils/openNotification';
import {InfoCircleTwoTone} from "@ant-design/icons/lib";


interface Interface {
    data?: ShopsModel[];
    mode?: 'Edit' | 'Add new';
    onClose?: () => void;
}

const ShopsForm = (props: Interface) => {
    const data = props.data || {};
    const [form] = Form.useForm();

    const [loading, setLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');
    const [fileList, setFileList] = useSafeState<Array<any>>([]);
    const [needContactProtectionCertificate, setNeedContactProtectionCertificate] = useSafeState(false);
    const [regionalRepresentative, setRegionalRepresentative] = useSafeState([]);
    const [shopStatus, setShopStatus] = useSafeState<any>([
        { name: 'EXPORT' },
        { name: 'VISITED' },
        { name: 'CEASED' },
        { name: 'NOT_VISITED' },
        { name: 'OWN' },
    ]);

    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    React.useEffect(() => {
        getRegionalRepresentatives();
    }, []);

    type optionalType = {
        label: string;
        value: string;
    };

    const getRegionalRepresentatives = () => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .get('/resource/regional-representatives/get-ordered-list')
                .then(function (response: AxiosResponse<any>) {
                    setRegionalRepresentative(response.data);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const generateOptionsByLabelAndValue = (data: any, label: string, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { value: data[i][val], label: getLabel(data[i][label]) };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const getLabel = (label: any) => {
        switch (label) {
            case 'EXPORT':
                return <Trans>EXPORT</Trans>;
            case 'VISITED':
                return <Trans>VISITED</Trans>;
            case 'CEASED':
                return <Trans>CEASED</Trans>;
            case 'NOT_VISITED':
                return <Trans>NOT_VISITED</Trans>;
            case 'OWN':
                return <Trans>OWN</Trans>;
            default:
                return label;
        }
    };

    const generateNameOptions = (data: any): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = {
                value: data[i]['id'] + '||' + data[i]['name'] + '||' + data[i]['sfaCode'],
                label: data[i]['name']
            };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    useEffect(() => {
        form.resetFields();

        if (props.mode === 'Edit') {
            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                if (key == 'needContactProtectionCertificate') {
                    setNeedContactProtectionCertificate(value == true);
                } else {
                    fieldsValues.push({ name: key, value: value });
                }
            }
            form.setFields(fieldsValues);
        }
    }, []);

    const onReset = () => {
        form.resetFields();
    };

    const handleUploadCsv = () => {
        if (fcrAxiosInstance.current != null) {
            setLoading(true);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });
            fcrAxiosInstance.current
                .post(process.env.REACT_APP_API_BASE_URL + '/resource/shops/upload-csv', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const uploadProps = {
        name: 'pic',
        action: process.env.REACT_APP_API_BASE_URL + '/resource/shops/upload-csv',
        multiple: false,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading ', info.file, info.fileList);
            }
            if (status === 'done') {
                openNotification('customSuccess', `${info.file.name} "file uploaded successfully."`);
            } else if (status === 'error') {
                openNotification('customError', `${info.file.name} "file upload failed."`);
            }
        },
        beforeUpload(file: any) {
            let fileList2: Array<any> = fileList;
            fileList2.push(file);
            setFileList(fileList2);
            return false;
        },
    };

    const config = { headers: { 'Content-Type': 'application/json' } };

    const onChange = () => {
        setNeedContactProtectionCertificate(!needContactProtectionCertificate);
    };

    const handleEdit = () => {
        if (fcrAxiosInstance.current != null) {
            setLoading(true);
            fcrAxiosInstance.current
                .put(
                    '/resource/shops/id?id=' + form.getFieldValue(['code']),
                    {
                        code: form.getFieldValue(['code']),
                        name: form.getFieldValue(['name']),
                        address: {
                            postalCode: form.getFieldValue(['postalCode']),
                            city: form.getFieldValue(['city']),
                            streetHouseNumber: form.getFieldValue(['streetHouseNumber']),
                        },
                        groupCode: form.getFieldValue(['groupCode']),
                        shopStatus: form.getFieldValue(['shopStatus']),
                        chainName: form.getFieldValue(['chainName']),
                        regionalRepresentative:
                            form.getFieldValue(['regionalRepresentative']) !== undefined
                                ? {
                                    id: form.getFieldValue(['regionalRepresentative']).split('||')[0],
                                    sfaCode: form.getFieldValue(['regionalRepresentative']).split('||')[2],
                                }
                                : undefined,
                        remark: form.getFieldValue(['remark']),
                        needContactProtectionCertificate: needContactProtectionCertificate,
                        version: form.getFieldValue(['version']),
                        contactName: form.getFieldValue(['contactName']),
                        contactPhone: form.getFieldValue(['contactPhone']),
                        contactEmail: form.getFieldValue(['contactEmail']),
                    },
                    config,
                )
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const handleAddNew = () => {
        if (fcrAxiosInstance.current != null) {
            setLoading(true);
            fcrAxiosInstance.current
                .post(
                    '/resource/shops/',
                    {
                        code: form.getFieldValue(['code']),
                        name: form.getFieldValue(['name']),
                        address: {
                            postalCode: form.getFieldValue(['postalCode']),
                            city: form.getFieldValue(['city']),
                            streetHouseNumber: form.getFieldValue(['streetHouseNumber']),
                        },
                        groupCode: form.getFieldValue(['groupCode']),
                        chainName: form.getFieldValue(['chainName']),
                        regionalRepresentative:
                            form.getFieldValue(['regionalRepresentative']) !== undefined
                                ? {
                                    id: form.getFieldValue(['regionalRepresentative']).split('||')[0],
                                    sfaCode: form.getFieldValue(['regionalRepresentative']).split('||')[2],
                                }
                                : undefined,
                        remark: form.getFieldValue(['remark']),
                        needContactProtectionCertificate: needContactProtectionCertificate,
                        shopStatus: form.getFieldValue(['shopStatus']),
                        version: form.getFieldValue(['version']),
                        contactName: form.getFieldValue(['contactName']),
                        contactPhone: form.getFieldValue(['contactPhone']),
                        contactEmail: form.getFieldValue(['contactEmail']),
                    },
                    config,
                )
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    return (
        <Form
            id={props.mode}
            form={form}
            layout="vertical"
            onFinish={() => (props.mode === 'Edit' ? handleEdit() : handleAddNew())}
        >
            {props.mode === 'Edit' || props.mode === 'Add new' ? (
                <>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="code"
                                label={
                                    <>
                                        <Trans>Code</Trans>
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <span>
                                                        A bolt kód csak betűket, számokat, és a következő speciális karaktereket tartalmazhatja! (-_!().:,)
                                                </span>
                                            }
                                            children={
                                                <InfoCircleTwoTone
                                                    twoToneColor="#1890ff"
                                                    style={{ marginLeft: 5 }}
                                                />
                                            }
                                        />
                                    </>
                                }
                                children={
                                    props.mode === 'Edit' ? (
                                        <Input disabled={true} type={'text'} />
                                    ) : (
                                        <Input type={'text'} />
                                    )
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?!\s+$)[A-Za-z0-9áéíóöőúüűÁÉÍÓÖŐÚÜŰ\-_!().:,\s-]+$/),
                                        required: true,
                                        message: <Trans>Please enter a valid code</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label={<Trans>Name</Trans>}
                                children={<Input type={'text'} />}
                                rules={[
                                    {
                                        required: true,
                                        message: <Trans>Please enter a name</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item
                                label={<Trans>Postal Code</Trans>}
                                name="postalCode"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        message: <Trans>Please enter a postal code</Trans>,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={<Trans>Település</Trans>}
                                name="city"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        message: <Trans>Please enter a city</Trans>,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<Trans>Street House Number</Trans>}
                                name="streetHouseNumber"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        message: <Trans>Please enter a street house number</Trans>,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="chainName"
                                label={<Trans>Chain Name</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please enter a group code</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="regionalRepresentative"
                                label={<Trans>Regional Representative</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a regional representative</Trans>}
                                        options={generateNameOptions(regionalRepresentative)}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please enter a regional representative</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="remark"
                                label={<Trans>Remark</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please enter a remark</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="contactName"
                                label={<Trans>Contact Name</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please enter a contact name</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="contactPhone"
                                label={<Trans>Contact Phone</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please enter a contact phone</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="contactEmail"
                                label={<Trans>Contact Email</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please enter a contact email</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Form.Item
                                name="shopStatus"
                                label={<Trans>Shop status</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a shop status</Trans>}
                                        options={generateOptionsByLabelAndValue(shopStatus, 'name', 'name')}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please select a shop status</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={16}>
                            <div>
                                <Trans>Need Contact Protection Certificate</Trans>?
                            </div>
                            <div style={{ marginBottom: 8 }}>
                                <Switch checked={needContactProtectionCertificate} onChange={onChange} />
                            </div>
                        </Col>
                    </Row>
                    <br />
                </>
            ) : (
                <>
                    <Dragger {...uploadProps}>
                        <div></div>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            <Trans>Click or drag file to this area to upload</Trans>
                        </p>
                        <p className="ant-upload-hint">
                            <Trans>
                                Support for a single upload. Strictly prohibit from uploading company data or other band
                                files
                            </Trans>
                        </p>
                    </Dragger>
                    <br />
                </>
            )}
            {props.mode === 'Edit' ? (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
            ) : props.mode === 'Add new' ? (
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() => onReset()}>
                        <Trans>Visszaállítás</Trans>
                    </Button>
                    <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                        <Trans>Hozzáadás</Trans>
                    </Button>
                </>
            ) : (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => handleUploadCsv()}>
                    <Trans>Feltöltés</Trans>
                </Button>
            )}
        </Form>
    );
};
export default ShopsForm;
