import { Trans } from '@lingui/macro';
import {Tag as TagAntd, Tag} from 'antd';
import React, {CSSProperties, ReactElement} from 'react';
import { Column } from '../column';
import { ColumnMain } from '../column-main';
import { Tag as ModelsTag } from '../../models/Tag';
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons/lib";

const DeviceModel = (): ColumnMain => {
    const columns: Column[] = new Array();

    columns.push({ key: 'id', width: 100, sorter: true, fixed: "left", title: <Trans>#</Trans>,
        render: text => ({ props: { style: { background: '#fafafa' } }, children: <div><b>{text}</b></div> }),
    });

    const shopColor = '#ffffff';
    const shopColumns: Column[] = [];
    shopColumns.push({
        key: 'shop.code',
        dataIndex: ['shop', 'code'],
        sorter: true,
        title: <Trans>Shop Code</Trans>,
        width: 80,
        render: text => ({ props: { style: { background: shopColor } }, children: <div>{text}</div> }),
    });

    shopColumns.push({
        key: 'shop.name',
        dataIndex: ['shop', 'name'],
        sorter: true,
        title: <Trans>Shop Name</Trans>,
        width: 130,
        render: text => ({ props: { style: { background: shopColor } }, children: <div>{text}</div> }),
    });
    shopColumns.push({
        key: 'shop.address.county',
        dataIndex: ['shop', 'address', 'county'],
        sorter: false,
        filter: false,
        title: <Trans>County</Trans>,
        width: 108,
        render: text => ({ props: { style: { background: shopColor } }, children: <div>{text}</div> }),
    });
    shopColumns.push({
        key: 'shop.address.postalCode',
        dataIndex: ['shop', 'address', 'postalCode'],
        sorter: true,
        title: <Trans>Postal Code</Trans>,
        width: 75,
        render: text => ({ props: { style: { background: shopColor } }, children: <div>{text}</div> }),
    });
    shopColumns.push({
        key: 'shop.address.city',
        dataIndex: ['shop', 'address', 'city'],
        sorter: false,
        title: <Trans>City</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopColor } }, children: <div>{text}</div> };
        },
    });
    shopColumns.push({
        key: 'shop.address.streetHouseNumber',
        dataIndex: ['shop', 'address', 'streetHouseNumber'],
        sorter: true,
        title: <Trans>Address</Trans>,
        width: 100,
        render: text => ({ props: { style: { background: shopColor } }, children: <div>{text}</div> }),
    });
    shopColumns.push({
        key: 'shop.chainName',
        dataIndex: ['shop', 'chainName'],
        sorter: false,
        title: <Trans>Lánc név</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopColor } }, children: <div>{text}</div> };
        },
    });
    shopColumns.push({
        key: 'shop.regionalRepresentative.name',
        dataIndex: ['shop', 'regionalRepresentative', 'name'],
        sorter: true,
        title: <Trans>Regional Representative</Trans>,
        width: 130,
        render: text => ({ props: { style: { background: shopColor } }, children: <div>{text}</div> }),
    });
    columns.push({ key: 'shop', filter: false, sorter: false, title: <Trans>Shop</Trans>, children: shopColumns });

    const deviceColor = '#fffbe6';
    const deviceColumns: Column[] = new Array();

    deviceColumns.push({
        key: 'deviceNature',
        width: 90,
        sorter: true,
        title: <Trans>Nature</Trans>,
        render: text => {
            let element: ReactElement;
            const color = 'gray';
            if (text === 'OOH') {
                element = (
                    <Tag color={'blue'} key={text}>
                        <Trans>OOH</Trans>
                    </Tag>
                );
            } else if (text === 'RETAIL') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>RETAIL</Trans>
                    </Tag>
                );
            } else if (text === 'BM') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>BM</Trans>
                    </Tag>
                );
            } else {
                element = (
                    <Tag color={color} key={text}>
                        {text != null ? <Trans>text</Trans> : <Trans>NO_DATA</Trans>}
                    </Tag>
                );
            }

            return { props: { style: { background: '#fffbe6' } }, children: element };
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>RETAIL</Trans>,
                value: 'RETAIL',
            },
            {
                label: <Trans>OOH</Trans>,
                value: 'OOH',
            },
            {
                label: <Trans>BM</Trans>,
                value: 'BM',
            },
        ],
    });

    deviceColumns.push({
        key: 'deviceType.deviceTypeCategory',
        dataIndex: ['deviceType', 'deviceTypeCategory'],
        width: 100,
        sorter: true,
        title: <Trans>Típus</Trans>,
        render: text => {
            let color = 'gray';
            let element: ReactElement;
            if (text === 'COOLER') {
                element =
                    <Tag color={'geekblue'} key={text}>
                        <Trans>COOLER</Trans>
                    </Tag>;
            } else if (text === 'DECOR') {
                element =
                    <Tag color={'cyan'} key={text}>
                        <Trans>DECOR</Trans>
                    </Tag>;
            } else if (text === 'OTHER') {
                element =
                    <Tag color={'orange'} key={text}>
                        <Trans>OTHER</Trans>
                    </Tag>;
            } else {
                element =
                    <Tag color={color} key={text}>
                        {text}
                    </Tag>;
            }
            return { props: { style: { background: '#fffbe6' } }, children: element };
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>COOLER</Trans>,
                value: 'COOLER',
            },
            {
                label: <Trans>DECOR</Trans>,
                value: 'DECOR',
            },
            {
                label: <Trans>OTHER</Trans>,
                value: 'OTHER',
            },
        ],
    });

    deviceColumns.push({
        key: 'deviceType.name',
        dataIndex: ['deviceType', 'name'],
        sorter: true,
        title: <Trans>Device Name</Trans>,
        width: 150,
        render: text => ({ props: { style: { background: deviceColor } }, children: <div>{text}</div> }),
    });
    deviceColumns.push({
        key: 'serialNumber',
        sorter: true,
        title: <Trans>Serial Number</Trans>,
        width: 140,
        render: text => ({ props: { style: { background: deviceColor } }, children: <div>{text}</div> }),
    });
    deviceColumns.push({
        key: 'inventoryNumber',
        sorter: true,
        title: <Trans>Inventory Number</Trans>,
        width: 138,
        render: text => ({ props: { style: { background: deviceColor } }, children: <div>{text}</div> }),
    });
    deviceColumns.push({
        key: 'decor.name',
        dataIndex: ['decor', 'name'],
        sorter: true,
        title: <Trans>Decoration</Trans>,
        width: 140,
        render: text => ({ props: { style: { background: deviceColor } }, children: <div>{text}</div> }),
    });
    deviceColumns.push({
        key: 'quantity',
        dataIndex: ['quantity'],
        sorter: true,
        title: <Trans>Quantity</Trans>,
        width: 140,
        render: text => ({ props: { style: { background: deviceColor } }, children: <div>{text}</div> }),
    });
    deviceColumns.push({
        key: 'purchasingDate',
        dateFilter: true,
        width: 130,
        dataIndex: ['purchasingDate'],
        sorter: true,
        title: <Trans>Purchasing Date</Trans>,
        render: text => ({ props: { style: { background: deviceColor } }, children: <div>{text}</div> }),
    });
    deviceColumns.push({
        key: 'warrantyDate',
        dateFilter: true,
        width: 130,
        dataIndex: ['warrantyDate'],
        sorter: true,
        title: <Trans>Warranty Date</Trans>,
        render: text => ({ props: { style: { background: deviceColor } }, children: <div>{text}</div> }),
    });
    deviceColumns.push({
        key: 'maintanedAt',
        dateFilter: true,
        width: 150,
        dataIndex: ['maintanedAt'],
        sorter: true,
        title: <Trans>Maintaned At</Trans>,
        render: text => ({ props: { style: { background: deviceColor } }, children: <div>{text}</div> }),
    });
    deviceColumns.push({
        key: 'deviceStatus',
        width: 160,
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let element: ReactElement;
            const color = 'geekblue';
            if (text === 'TRANSPORTABLE_FROM_SERVICE') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>TRANSPORTABLE_FROM_SERVICE</Trans>
                    </Tag>
                );
            } else if (text === 'TRANSPORTABLE') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>TRANSPORTABLE</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_EXAMINED') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>NEED_TO_BE_EXAMINED</Trans>
                    </Tag>
                );
            } else if (text === 'OUTSOURCED') {
                element = (
                    <Tag color={'green'} key={text}>
                        <Trans>OUTSOURCED</Trans>
                    </Tag>
                );
            } else if (text === 'PUBLISHED') {
                element = (
                    <Tag color={'green'} key={text}>
                        <Trans>PUBLISHED</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_DISCARDED') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>NEED_TO_BE_DISCARDED</Trans>
                    </Tag>
                );
            } else if (text === 'SERVICED') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>SERVICED</Trans>
                    </Tag>
                );
            } else if (text === 'DURING_DELIVERY') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>DURING_DELIVERY</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_DELIVERED') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>NEED_TO_BE_DELIVERED</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_DISMANTLED') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>NEED_TO_BE_DISMANTLED</Trans>
                    </Tag>
                );
            } else if (text === 'INACTIVE') {
                element = (
                    <Tag color={'gray'} key={text}>
                        <Trans>INACTIVE</Trans>
                    </Tag>
                );
            } else if (text === 'DECOR_DAMAGED') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>DECOR_DAMAGED</Trans>
                    </Tag>
                );
            } else if (text === 'USED') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>USED</Trans>
                    </Tag>
                );
            } else if (text === 'WAIT_FOR_PERMISSION') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>WAIT_FOR_PERMISSION</Trans>
                    </Tag>
                );
            } else if (text === 'DISCARD') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>DISCARD</Trans>
                    </Tag>
                );
            } else if (text === 'REQUIRED') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>REQUIRED</Trans>
                    </Tag>
                );
            } else if (text === 'WAIT_FOR_COMMISSIONING') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>WAIT_FOR_COMMISSIONING</Trans>
                    </Tag>
                );
            } else if (text === 'FOUND_AT_STOCK_TAKING') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>FOUND_AT_STOCK_TAKING</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_REPAIRED') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>NEED_TO_BE_REPAIRED</Trans>
                    </Tag>
                );
            } else {
                element = (
                    <Tag color={color} key={text}>
                        {text}
                    </Tag>
                );
            }
            return { props: { style: { background: deviceColor } }, children: element };
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>TRANSPORTABLE_FROM_SERVICE</Trans>,
                value: 'TRANSPORTABLE_FROM_SERVICE',
            },
            {
                label: <Trans>TRANSPORTABLE</Trans>,
                value: 'TRANSPORTABLE',
            },
            {
                label: <Trans>NEED_TO_BE_EXAMINED</Trans>,
                value: 'NEED_TO_BE_EXAMINED',
            },
            {
                label: <Trans>OUTSOURCED</Trans>,
                value: 'OUTSOURCED',
            },
            {
                label: <Trans>PUBLISHED</Trans>,
                value: 'PUBLISHED',
            },
            {
                label: <Trans>NEED_TO_BE_DISCARDED</Trans>,
                value: 'NEED_TO_BE_DISCARDED',
            },
            {
                label: <Trans>SERVICED</Trans>,
                value: 'SERVICED',
            },
            {
                label: <Trans>DURING_DELIVERY</Trans>,
                value: 'DURING_DELIVERY',
            },
            {
                label: <Trans>NEED_TO_BE_DELIVERED</Trans>,
                value: 'NEED_TO_BE_DELIVERED',
            },
            {
                label: <Trans>NEED_TO_BE_REPAIRED</Trans>,
                value: 'NEED_TO_BE_REPAIRED',
            },
            {
                label: <Trans>NEED_TO_BE_DISMANTLED</Trans>,
                value: 'NEED_TO_BE_DISMANTLED',
            },
            {
                label: <Trans>INACTIVE</Trans>,
                value: 'INACTIVE',
            },
            {
                label: <Trans>DECOR_DAMAGED</Trans>,
                value: 'DECOR_DAMAGED',
            },
            {
                label: <Trans>USED</Trans>,
                value: 'USED',
            },
            {
                label: <Trans>WAIT_FOR_PERMISSION</Trans>,
                value: 'WAIT_FOR_PERMISSION',
            },
            {
                label: <Trans>DISCARD</Trans>,
                value: 'DISCARD',
            },
            {
                label: <Trans>REQUIRED</Trans>,
                value: 'REQUIRED',
            },
            {
                label: <Trans>WAIT_FOR_COMMISSIONING</Trans>,
                value: 'WAIT_FOR_COMMISSIONING',
            },
            {
                label: <Trans>FOUND_AT_STOCK_TAKING</Trans>,
                value: 'FOUND_AT_STOCK_TAKING',
            },
        ],
    });
    deviceColumns.push({
        key: 'deviceGroupSerial',
        sorter: true,
        title: <Trans>Device Group Serial</Trans>,
        width: 140,
        render: text => ({ props: { style: { background: deviceColor } }, children: <div>{text}</div> }),
    });

    deviceColumns.push({
        key: 'needStockTaking',
        sorter: true,
        title: <Trans>Need Stock Taking</Trans>,
        width: 150,
        render: text => ({ props: { style: { background: deviceColor } },
            children: <div>{text == true ? <Trans>Igen</Trans> : <Trans>Nem</Trans>}</div> }),
        uniqueSelectFilterData: [
            {
                label: <Trans>Mind</Trans>,
                value: null,
            },
            {
                label: <Trans>Igen</Trans>,
                value: 'true',
            },
            {
                label: <Trans>Nem</Trans>,
                value: 'false',
            },
        ],
    });

    columns.push({
        key: 'device',
        filter: false,
        sorter: false,
        title: <Trans>Device</Trans>,
        children: deviceColumns,
    });

    const warehouseCompanyColor = '#e6fffb';
    const warehouseCompanyColumns: Column[] = [];
    warehouseCompanyColumns.push({
        key: 'warehouseCompany.code',
        dataIndex: ['warehouseCompany', 'code'],
        sorter: true,
        title: <Trans>Code</Trans>,
        width: 80,
        render: text => ({ props: { style: { background: warehouseCompanyColor } }, children: <div>{text}</div> }),
    });
    warehouseCompanyColumns.push({
        key: 'warehouseCompany.name',
        dataIndex: ['warehouseCompany', 'name'],
        sorter: true,
        title: <Trans>Name</Trans>,
        width: 130,
        render: text => ({ props: { style: { background: warehouseCompanyColor } }, children: <div>{text}</div> }),
    });
    warehouseCompanyColumns.push({
        key: 'warehouseCompany.address.postalCode',
        dataIndex: ['warehouseCompany', 'address', 'postalCode'],
        sorter: true,
        title: <Trans>Postal Code</Trans>,
        width: 75,
        render: text => ({ props: { style: { background: warehouseCompanyColor } }, children: <div>{text}</div> }),
    });
    warehouseCompanyColumns.push({
        key: 'warehouseCompany.address.city',
        dataIndex: ['warehouseCompany', 'address', 'city'],
        sorter: false,
        title: <Trans>City</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: warehouseCompanyColor } }, children: <div>{text}</div> };
        },
    });
    warehouseCompanyColumns.push({
        key: 'warehouseCompany.address.streetHouseNumber',
        dataIndex: ['warehouseCompany', 'address', 'streetHouseNumber'],
        sorter: true,
        title: <Trans>Address</Trans>,
        width: 100,
        render: text => ({ props: { style: { background: warehouseCompanyColor } }, children: <div>{text}</div> }),
    });
    columns.push({ key: 'warehouseCompany', filter: false, sorter: false, title: <Trans>Warehouse Company</Trans>, children: warehouseCompanyColumns });


    const serviceCompanyColor = '#f6ffed';
    const serviceCompanyColumns: Column[] = [];
    serviceCompanyColumns.push({
        key: 'serviceCompany.code',
        dataIndex: ['serviceCompany', 'code'],
        sorter: true,
        title: <Trans>Code</Trans>,
        width: 80,
        render: text => ({ props: { style: { background: serviceCompanyColor } }, children: <div>{text}</div> }),
    });
    serviceCompanyColumns.push({
        key: 'serviceCompany.name',
        dataIndex: ['serviceCompany', 'name'],
        sorter: true,
        title: <Trans>Name</Trans>,
        width: 130,
        render: text => ({ props: { style: { background: serviceCompanyColor } }, children: <div>{text}</div> }),
    });
    serviceCompanyColumns.push({
        key: 'serviceCompany.address.postalCode',
        dataIndex: ['serviceCompany', 'address', 'postalCode'],
        sorter: true,
        title: <Trans>Postal Code</Trans>,
        width: 75,
        render: text => ({ props: { style: { background: serviceCompanyColor } }, children: <div>{text}</div> }),
    });
    serviceCompanyColumns.push({
        key: 'serviceCompany.address.city',
        dataIndex: ['serviceCompany', 'address', 'city'],
        sorter: false,
        title: <Trans>City</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: serviceCompanyColor } }, children: <div>{text}</div> };
        },
    });
    serviceCompanyColumns.push({
        key: 'serviceCompany.address.streetHouseNumber',
        dataIndex: ['serviceCompany', 'address', 'streetHouseNumber'],
        sorter: true,
        title: <Trans>Address</Trans>,
        width: 100,
        render: text => ({ props: { style: { background: serviceCompanyColor } }, children: <div>{text}</div> }),
    });
    columns.push({ key: 'serviceCompany', filter: false, sorter: false, title: <Trans>Service Company</Trans>, children: serviceCompanyColumns });

    columns.push({ key: 'createdBy', width: 140, sorter: true, title: <Trans>Created By</Trans> });
    columns.push({ key: 'createdDate', filter: false, width: 140, sorter: true, title: <Trans>Created Date</Trans> });
    columns.push({ key: 'updatedBy', width: 140, sorter: true, title: <Trans>Updated By</Trans> });
    columns.push({
        key: 'updatedDate',
        filter: false,
        width: 140,
        sorter: true,
        title: <Trans>Updated Date</Trans>,
        direction: 'desc',
    });

    const render = (data: any, style: CSSProperties) => {
        if (data === true) {
            return { props: { style: style }, children: <CheckCircleOutlined style={{color: "green"}} /> }
        } else if (data === false) {
            return { props: { style: style }, children:  <CloseCircleOutlined style={{color: "red"}} /> }
        }
        return data;
    };

    return {
        columns,
        url: '/resource/device-register',
    };
};
export default DeviceModel();
