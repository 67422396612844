import useSafeState from '../../utils/safe-state';
import { Button, Form, Modal, Select } from 'antd';
import React, { ReactElement } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../utils/hooks';
import {openNotification} from "../../utils/openNotification";


const { confirm } = Modal;

interface Interface {
    title?: ReactElement;
    children: ReactElement;
    onCustomClose?: () => void;
    customClose?: () => void;
    name?: string;
}

const RoleSettingForm = (props: Interface) => {
    const axiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + '');

    const [visible, setVisible] = useSafeState(false);
    const [role, setRole] = useSafeState('');
    const [mode, setMode] = useSafeState('Edit');
    const [form] = Form.useForm();
    form.resetFields();

    let selectedRole: any = [];

    type optionalType = {
        label: string;
        value: string;
    };

    const generateRoleOptions = (data: any): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            if (!data[i]['name'].startsWith('FCR_')) {
                continue;
            }

            let currentItem: optionalType = {
                value: '{"id": "' + data[i]['id'] + '", "name": "' + data[i]['name'] + '"}',
                label: data[i]['name'],
            };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const handleOnChange = (value: any) => {
        selectedRole = [];
        selectedRole.push(JSON.parse(value));
        setRole(value);
    };

    const open = (mode?: 'Add new' | 'Edit' | '', recordData?: any) => {
        setMode(mode ? mode : '');
        setVisible(true);
    };

    const addRoleCall = (id: any, roles: any) => {
        if (!axiosInstance.current) {
            return;
        }

        if (axiosInstance.current != null && selectedRole.length > 0) {
            axiosInstance
                .current({
                    url:
                        process.env.REACT_APP_API_KEYCLOAK_URL +
                        '/auth/admin/realms/friesland-cooler-register/users/' +
                        id +
                        '/role-mappings/realm',
                    method: 'DELETE',
                    data: roles,
                })
                .then(() => {
                    if (axiosInstance.current != null) {
                        axiosInstance.current
                            .post(
                                '/auth/admin/realms/friesland-cooler-register/users/' + id + '/role-mappings/realm',
                                selectedRole,
                            )
                            .then(() => {
                                openNotification('success', props, 'Sikeres mentés!');
                            })
                            .catch(function (error: any) {
                                openNotification('error', error);
                            });
                    }
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                });
        }
    };

    const showPromiseConfirm = (recordData?: any) => {
        confirm({
            title: props.title,
            icon: <ExclamationCircleOutlined />,
            content: (
                <Select
                    allowClear
                    showSearch={true}
                    style={{ width: '100%' }}
                    placeholder={recordData.role}
                    options={generateRoleOptions(recordData.roles)}
                    onChange={(value: any) => handleOnChange(value)}
                />
            ),
            visible: visible,
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    addRoleCall(recordData.userId, recordData.roles);
                }).catch(() => console.log('Oops error!'));
            },
            onCancel() {},
        });
    };

    return { open, showPromiseConfirm };
};
export default RoleSettingForm;
