import useSafeState from '../../utils/safe-state';
import React from 'react';
import { Breadcrumb, Button, message, Popconfirm, Divider, Popover } from 'antd';
import Drawer from '../../components/drawer/drawer';
import { Trans } from '@lingui/macro';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons/lib';
import RegionalRepresentativeForm from '../../forms/regional-representative-drawer/regional-representative-drawer';
import RegionalRepresentativesModel from '../../models/dto/regional-representative-model';
import DtoTable from '../../components/dto-table/dto-table';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useAxios } from '../../utils/hooks';

import ButtonWithIcon from "../../components/buttons/button-with-icon";
import {openNotification} from "../../utils/openNotification";

const RegionalRepresentativesScreen = () => {
    const [templateLoading, setTemplateLoading] = useSafeState(false);
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const text = <Trans>Területi képviselő</Trans>;
    const drawer = Drawer({
        title: text,
        children: <RegionalRepresentativeForm />,
        customClose: () => setTrigger(!trigger),
    });

    const handleDownloadTemplateXls = () => {
        setTemplateLoading(true);
        if (fcrAxiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            fcrAxiosInstance
                .current({
                    url:
                        process.env.REACT_APP_API_BASE_URL +
                        '/resource/regional-representatives/' +
                        lang +
                        '/template/xls/',
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'regional-representative.xls');
                    setTemplateLoading(false);
                });
        }
    };

    const [trigger, setTrigger] = useSafeState(false);

    const handleDelete = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .delete('/resource/regional-representatives/id?id=' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott sor sikeresen törölve lett.')
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                    setTrigger(false);
                    setTrigger(!trigger);
                });
        }
    };

    const action = (text: string, record: any) => (
        <Popover
            content={
                <>
                    <EditOutlined
                        style={{ color: '#40a9ff' }}
                        className={'ActionButtons'}
                        onClick={() =>
                            drawer.open('Edit', {
                                id: record.id,
                                name: record.name,
                                username: record.username,
                                password: record.password,
                                rank: record.rank,
                                region: record.region,
                                sfaCode: record.sfaCode,
                                email: record.email,
                                phone: record.phone,
                                version: record.version,
                            })
                        }
                    />
                    <Popconfirm
                        title={<Trans>Are you sure to delete this regional representative?</Trans>}
                        onConfirm={() => handleDelete(record.id)}
                        okText={<Trans>Yes</Trans>}
                        cancelText={<Trans>No</Trans>}
                    >
                        <DeleteOutlined style={{ color: '#ff4d4f' }} className={'ActionButtons'} />
                    </Popconfirm>
                </>
            }
            trigger="hover"
        >
            <EllipsisOutlined style={{ fontSize: 'x-large' }} />
        </Popover>
    );

    const actions = {
        width: 50,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center',
    };

    const header = (
        <>
            <Button type={'primary'} onClick={() => drawer.open('Add new')} style={{ float: 'left' }}>
                <ButtonWithIcon icon={<PlusOutlined />} text={<Trans>Új hozzáadása</Trans>} />
            </Button>

            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button loading={templateLoading} onClick={() => handleDownloadTemplateXls()} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<DownloadOutlined />}
                    text={<Trans>Excel Sablon</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button onClick={() => drawer.open('Upload File')} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<UploadOutlined />}
                    text={<Trans>Csv Feltöltés</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
        </>
    );

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Törzsadatok</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Területi képviselők</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            {drawer.component}
            <DtoTable
                model={RegionalRepresentativesModel}
                tableHeader={header}
                allowExport={true}
                apiUrl={'regional-representatives'}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default RegionalRepresentativesScreen;
