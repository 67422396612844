import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import { Tag } from 'antd';

const DeviceTypeModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();
    columns.push({
        key: 'name',
        width: 400,
        fixed: 'left',
        sorter: true,
        title: <Trans>Név</Trans>,
        render: text => {
            return <b>{text}</b>;
        },
    });
    columns.push({ key: 'prodNum', width: 150, direction: 'asc', sorter: true, title: <Trans>ProdNum</Trans> });
    columns.push({
        key: 'deviceTypeCategory',
        width: 100,
        sorter: true,
        title: <Trans>Típus</Trans>,
        render: text => {
            let color = 'gray';
            if (text === 'COOLER') {
                return (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>COOLER</Trans>
                    </Tag>
                );
            } else if (text === 'DECOR') {
                return (
                    <Tag color={'cyan'} key={text}>
                        <Trans>DECOR</Trans>
                    </Tag>
                );
            } else if (text === 'OTHER') {
                return (
                    <Tag color={'orange'} key={text}>
                        <Trans>OTHER</Trans>
                    </Tag>
                );
            }
            return (
                <Tag color={color} key={text}>
                    {text}
                </Tag>
            );
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>COOLER</Trans>,
                value: 'COOLER',
            },
            {
                label: <Trans>DECOR</Trans>,
                value: 'DECOR',
            },
            {
                label: <Trans>OTHER</Trans>,
                value: 'OTHER',
            },
        ],
    });
    columns.push({
        key: 'methodOfRegistration',
        width: 150,
        sorter: true,
        title: <Trans>Method of Registration</Trans>,
        render: text => {
            let color = 'gray';
            if (text === 'BASED_ON_SERIAL_NUMBER') {
                return (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>BASED_ON_SERIAL_NUMBER</Trans>
                    </Tag>
                );
            } else if (text === 'BASED_ON_QUANTITY') {
                return (
                    <Tag color={'cyan'} key={text}>
                        <Trans>BASED_ON_QUANTITY</Trans>
                    </Tag>
                );
            } else if (text === 'NO_REGISTRATION_REQUIRED') {
                return (
                    <Tag color={'orange'} key={text}>
                        <Trans>NO_REGISTRATION_REQUIRED</Trans>
                    </Tag>
                );
            }
            return (
                <Tag color={color} key={text}>
                    {text}
                </Tag>
            );
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>BASED_ON_SERIAL_NUMBER</Trans>,
                value: 'BASED_ON_SERIAL_NUMBER',
            },
            {
                label: <Trans>BASED_ON_QUANTITY</Trans>,
                value: 'BASED_ON_QUANTITY',
            },
            {
                label: <Trans>NO_REGISTRATION_REQUIRED</Trans>,
                value: 'NO_REGISTRATION_REQUIRED',
            },
        ],
    });

    columns.push({ key: 'description', width: 150, sorter: true, title: <Trans>Megjegyzés</Trans> });
    columns.push({
        key: 'groupable',
        width: 150,
        uniqueSelectFilterData: [
            {
                label: <Trans>Mind</Trans>,
                value: null,
            },
            {
                label: <Trans>Igen</Trans>,
                value: 'true',
            },
            {
                label: <Trans>Nem</Trans>,
                value: 'false',
            },
        ],
        sorter: true,
        title: <Trans>Csoportosítható</Trans>,
    });
    columns.push({ key: 'createdBy', width: 150, sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({
        key: 'createdDate',
        filter: false,
        width: 150,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Létrehozva</Trans>,
    });
    columns.push({ key: 'updatedBy', width: 150, sorter: true, title: <Trans>Módosította</Trans> });
    columns.push({
        key: 'updatedDate',
        filter: false,
        width: 150,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Módosítva</Trans>,
    });

    return {
        columns: columns,
        url: '/resource/device-types',
    };
};
export default DeviceTypeModel();
