import useSafeState from '../../utils/safe-state';
import React from 'react';
import { Breadcrumb, Space } from 'antd';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import { FolderViewOutlined } from '@ant-design/icons/lib';
import DtoTable from '../../components/dto-table/dto-table';
import ElixirFileModel from '../../models/dto/elixir-file-model';

const ElixirSyncScreen = () => {
    const [trigger, setTrigger] = useSafeState(false);

    const actions = {
        title: 'Actions',
        key: 'action',
        width: 80,
        fixed: 'right',
        align: 'center',
        render: (info: any) => (
            <Space size="middle">
                <Link to={'/elixir-sync-file/' + info.id}>
                    <FolderViewOutlined className={'ActionButtons'} style={{ color: '#40a9ff' }} />
                </Link>
            </Space>
        ),
    };

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Külső adatkapcsolat</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Elixir Sync</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable
                model={ElixirFileModel}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default ElixirSyncScreen;
