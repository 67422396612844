import {InfoCircleTwoTone} from '@ant-design/icons/lib';
import { Trans } from '@lingui/macro';
import React, { ReactElement } from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import {Tag, Tooltip} from 'antd';

const WorkRequestsModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();

    const workRequestColor = '#fafafa';
    const workRequestColumns: Array<Column> = new Array();
    workRequestColumns.push({
        key: 'requestType',
        fixed: 'left',
        width: 120,
        sorter: true,
        title: <Trans>Work Request Type</Trans>,
        render: text => {
            let element: ReactElement;
            let color = 'gray';
            if (text === 'DISMANTLING') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>DISMANTLING</Trans>
                    </Tag>
                );
            } else if (text === 'DELIVERY') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>DELIVERY</Trans>
                    </Tag>
                );
            } else if (text === 'TRANSIT') {
                element = (
                    <Tag color={'cyan'} key={text}>
                        <Trans>TRANSIT</Trans>
                    </Tag>
                );
            } else if (text === 'SERVICE') {
                element = (
                    <Tag color={'volcano'} key={text}>
                        <Trans>SERVICE</Trans>
                    </Tag>
                );
            } else if (text === 'REPLACEMENT') {
                element = (
                    <Tag color={'purple'} key={text}>
                        <Trans>REPLACEMENT</Trans>
                    </Tag>
                );
            } else {
                element = (
                    <Tag color={color} key={text}>
                        {text}
                    </Tag>
                );
            }

            return { props: { style: { background: workRequestColor } }, children: element };
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>DISMANTLING</Trans>,
                value: 'DISMANTLING',
            },
            {
                label: <Trans>DELIVERY</Trans>,
                value: 'DELIVERY',
            },
            {
                label: <Trans>TRANSIT</Trans>,
                value: 'TRANSIT',
            },
            {
                label: <Trans>SERVICE</Trans>,
                value: 'SERVICE',
            },
            {
                label: <Trans>REPLACEMENT</Trans>,
                value: 'REPLACEMENT',
            },
        ],
    });

    workRequestColumns.push({
        key: 'requestStatus',
        fixed: 'left',
        width: 230,
        sorter: true,
        title: <Trans>Work Request Status</Trans>,
        render: text => {
            let color = 'gray';

            if (text == null) {
                return (
                    <Tag color={'gray'} key={text}>
                        <Trans>...</Trans>
                    </Tag>
                );
            }

            switch (text) {
                case 'DEVICE_STATUS_CHANGED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'orange'} key={text}>
                                <Trans>DEVICE_STATUS_CHANGED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'REQUIRED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'orange'} key={text}>
                                <Trans>REQUIRED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'MODIFIED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'green'} key={text}>
                                <Trans>MODIFIED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'APPROVED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'green'} key={text}>
                                <Trans>APPROVED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'COMPLETED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'blue'} key={text}>
                                <Trans>COMPLETED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'FAILED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'red'} key={text}>
                                <Trans>FAILED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'LACK_OF_PARTS': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'red'} key={text}>
                                <Trans>LACK_OF_PARTS</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'CALORIC_OR_COMPRESSOR': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'red'} key={text}>
                                <Trans>CALORIC_OR_COMPRESSOR</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'DELETED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'red'} key={text}>
                                <Trans>DELETED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'DENIED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'red'} key={text}>
                                <Trans>DENIED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'IN_PROGRESS': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'geekblue'} key={text}>
                                <Trans>IN_PROGRESS</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'ARRIVED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'purple'} key={text}>
                                <Trans>ARRIVED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'LEAVED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'cyan'} key={text}>
                                <Trans>LEAVED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'ACCEPTED_DEADLINE_MODIFIED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'orange'} key={text}>
                                <Trans>ACCEPTED_DEADLINE_MODIFIED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'REPAIR_OFFER_ARRIVED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'orange'} key={text}>
                                <Trans>REPAIR_OFFER_ARRIVED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'REPAIR_OFFER_APPROVED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'green'} key={text}>
                                <Trans>REPAIR_OFFER_APPROVED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'REPAIR_OFFER_DENIED': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'red'} key={text}>
                                <Trans>REPAIR_OFFER_DENIED</Trans>
                            </Tag>
                        ),
                    };
                }
                case 'CANNOT_BE_REPAIRED_ON_SITE': {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={'red'} key={text}>
                                <Trans>CANNOT_BE_REPAIRED_ON_SITE</Trans>
                            </Tag>
                        ),
                    };
                }
                default: {
                    return {
                        props: { style: { background: workRequestColor } },
                        children: (
                            <Tag color={color} key={text}>
                                {text}
                            </Tag>
                        ),
                    };
                }
            }
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>APPROVED</Trans>,
                value: 'APPROVED',
            },
            {
                label: <Trans>DENIED</Trans>,
                value: 'DENIED',
            },
            {
                label: <Trans>COMPLETED</Trans>,
                value: 'COMPLETED',
            },
            {
                label: <Trans>FAILED</Trans>,
                value: 'FAILED',
            },
            {
                label: <Trans>LACK_OF_PARTS</Trans>,
                value: 'LACK_OF_PARTS',
            },
            {
                label: <Trans>CALORIC_OR_COMPRESSOR</Trans>,
                value: 'CALORIC_OR_COMPRESSOR',
            },
            {
                label: <Trans>REQUIRED</Trans>,
                value: 'REQUIRED',
            },
            {
                label: <Trans>DELETED</Trans>,
                value: 'DELETED',
            },
            {
                label: <Trans>MODIFIED</Trans>,
                value: 'MODIFIED',
            },
            {
                label: <Trans>IN_PROGRESS</Trans>,
                value: 'IN_PROGRESS',
            },
            {
                label: <Trans>ARRIVED</Trans>,
                value: 'ARRIVED',
            },
            {
                label: <Trans>LEAVED</Trans>,
                value: 'LEAVED',
            },
            {
                label: <Trans>REPAIR_OFFER_ARRIVED</Trans>,
                value: 'REPAIR_OFFER_ARRIVED',
            },
            {
                label: <Trans>REPAIR_OFFER_APPROVED</Trans>,
                value: 'REPAIR_OFFER_APPROVED',
            },
            {
                label: <Trans>REPAIR_OFFER_DENIED</Trans>,
                value: 'REPAIR_OFFER_DENIED',
            },
            {
                label: <Trans>ACCEPTED_DEADLINE_MODIFIED</Trans>,
                value: 'ACCEPTED_DEADLINE_MODIFIED',
            },
            {
                label: <Trans>CANNOT_BE_REPAIRED_ON_SITE</Trans>,
                value: 'CANNOT_BE_REPAIRED_ON_SITE',
            },
            {
                label: <Trans>DEVICE_STATUS_CHANGED</Trans>,
                value: 'DEVICE_STATUS_CHANGED',
            }
        ],
    });
    columns.push({
        key: 'request',
        width: 157,
        sorter: false,
        filter: false,
        fixed: 'left',
        title: <Trans>Work Request</Trans>,
        children: workRequestColumns,
    });

    const shopToColor = '#fffbe6';
    const shopToColumns: Array<Column> = new Array();
    shopToColumns.push({
        key: 'shopTo.code',
        dataIndex: ['shopTo', 'code'],
        sorter: true,
        title: <Trans>Shop Code</Trans>,
        width: 80,
        render: (text, row) => {
            return { props: { style: { background: shopToColor } }, children: <>{text}</> };
        },
    });

    shopToColumns.push({
        key: 'shopTo.name',
        dataIndex: ['shopTo', 'name'],
        sorter: true,
        title: <Trans>Shop Name</Trans>,
        width: 130,
        render: (text, row) => {
            return { props: { style: { background: shopToColor } }, children: <>{text}</> };
        },
    });
    shopToColumns.push({
        key: 'shopTo.address.county',
        dataIndex: ['shopTo', 'address', 'county'],
        sorter: false,
        filter: false,
        title: <Trans>County</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopToColor } }, children: <>{text}</> };
        },
    });
    shopToColumns.push({
        key: 'shopTo.address.postalCode',
        dataIndex: ['shopTo', 'address', 'postalCode'],
        sorter: true,
        title: <Trans>Postal Code</Trans>,
        width: 75,
        render: (text, row) => {
            return { props: { style: { background: shopToColor } }, children: <>{text}</> };
        },
    });
    shopToColumns.push({
        key: 'shopTo.address.city',
        dataIndex: ['shopTo', 'address', 'city'],
        sorter: false,
        title: <Trans>City</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopToColor } }, children: <>{text}</> };
        },
    });
    shopToColumns.push({
        key: 'shopTo.address.streetHouseNumber',
        dataIndex: ['shopTo', 'address', 'streetHouseNumber'],
        sorter: true,
        title: <Trans>Address</Trans>,
        width: 100,
        render: (text, row) => {
            return { props: { style: { background: shopToColor } }, children: <>{text}</> };
        },
    });
    shopToColumns.push({
        key: 'shopTo.chainName',
        dataIndex: ['shopTo', 'chainName'],
        sorter: false,
        title: <Trans>Lánc név</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopToColor } }, children: <>{text}</> };
        },
    });
    shopToColumns.push({
        key: 'shopTo.regionalRepresentative.name',
        dataIndex: ['shopTo', 'regionalRepresentative', 'name'],
        sorter: true,
        title: <Trans>Regional Representative</Trans>,
        width: 130,
        render: (text, row) => {
            return { props: { style: { background: shopToColor } }, children: <>{text}</> };
        },
    });
    columns.push({
        key: 'shopTo',
        filter: false,
        sorter: false,
        title: <Trans>Shop (target)</Trans>,
        children: shopToColumns,
    });

    const shopFromColor = '#f0f5ff';
    const shopFromColumns: Array<Column> = new Array();
    shopFromColumns.push({
        key: 'shopFrom.code',
        dataIndex: ['shopFrom', 'code'],
        sorter: true,
        title: <Trans>Shop Code</Trans>,
        width: 80,
        render: (text, row) => {
            return { props: { style: { background: shopFromColor } }, children: <>{text}</> };
        },
    });

    shopFromColumns.push({
        key: 'shopFrom.name',
        dataIndex: ['shopFrom', 'name'],
        sorter: true,
        title: <Trans>Shop Name</Trans>,
        width: 130,
        render: (text, row) => {
            return { props: { style: { background: shopFromColor } }, children: <>{text}</> };
        },
    });
    shopFromColumns.push({
        key: 'shopFrom.address.county',
        dataIndex: ['shopFrom', 'address', 'county'],
        sorter: false,
        filter: false,
        title: <Trans>County</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopFromColor } }, children: <>{text}</> };
        },
    });
    shopFromColumns.push({
        key: 'shopFrom.address.postalCode',
        dataIndex: ['shopFrom', 'address', 'postalCode'],
        sorter: true,
        title: <Trans>Postal Code</Trans>,
        width: 75,
        render: (text, row) => {
            return { props: { style: { background: shopFromColor } }, children: <>{text}</> };
        },
    });
    shopFromColumns.push({
        key: 'shopFrom.address.city',
        dataIndex: ['shopFrom', 'address', 'city'],
        sorter: false,
        title: <Trans>City</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopFromColor } }, children: <>{text}</> };
        },
    });
    shopFromColumns.push({
        key: 'shopFrom.address.streetHouseNumber',
        dataIndex: ['shopFrom', 'address', 'streetHouseNumber'],
        sorter: true,
        title: <Trans>Address</Trans>,
        width: 100,
        render: (text, row) => {
            return { props: { style: { background: shopFromColor } }, children: <>{text}</> };
        },
    });
    shopFromColumns.push({
        key: 'shopFrom.chainName',
        dataIndex: ['shopFrom', 'chainName'],
        sorter: false,
        title: <Trans>Lánc név</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopFromColor } }, children: <>{text}</> };
        },
    });
    shopFromColumns.push({
        key: 'shopFrom.regionalRepresentative.name',
        dataIndex: ['shopFrom', 'regionalRepresentative', 'name'],
        sorter: true,
        title: <Trans>Regional Representative</Trans>,
        width: 130,
        render: (text, row) => {
            return { props: { style: { background: shopFromColor } }, children: <>{text}</> };
        },
    });
    columns.push({
        key: 'shopFrom',
        filter: false,
        sorter: false,
        title: <Trans>Shop (source)</Trans>,
        children: shopFromColumns,
    });

    const deviceToColor = '#ffeac1';
    const device1Columns: Array<Column> = new Array();
    device1Columns.push({
        key: 'deviceRegisterTo.deviceType.name',
        dataIndex: ['deviceRegisterTo', 'deviceType', 'name'],
        filter: true,
        sorter: true,
        title: <Trans>Device 1 Name</Trans>,
        width: 150,
        render: text => {
            return { props: { style: { background: deviceToColor } }, children: <div>{text}</div> };
        },
    });
    device1Columns.push({
        key: 'deviceRegisterTo.serialNumber',
        dataIndex: ['deviceRegisterTo', 'serialNumber'],
        filter: true,
        sorter: true,
        title: <Trans>Device 1 Serial Number</Trans>,
        width: 140,
        render: text => {
            return { props: { style: { background: deviceToColor } }, children: <div>{text}</div> };
        },
    });
    device1Columns.push({
        key: 'deviceRegisterTo.inventoryNumber',
        dataIndex: ['deviceRegisterTo', 'inventoryNumber'],
        filter: true,
        sorter: true,
        title: <Trans>Device 1 Inventory Number</Trans>,
        width: 138,
        render: text => {
            return { props: { style: { background: deviceToColor } }, children: <div>{text}</div> };
        },
    });
    device1Columns.push({
        key: 'deviceRegisterTo.decor.name',
        dataIndex: ['deviceRegisterTo', 'decor', 'name'],
        filter: true,
        sorter: true,
        title: <Trans>Device 1 Decoration</Trans>,
        width: 140,
        render: text => {
            return { props: { style: { background: deviceToColor } }, children: <div>{text}</div> };
        },
    });
    device1Columns.push({
        key: 'deviceRegisterTo.deviceStatus',
        dataIndex: ['deviceRegisterTo', 'deviceStatus'],
        width: 160,
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let element: ReactElement;
            const color = 'geekblue';
            if (text === 'TRANSPORTABLE_FROM_SERVICE') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>TRANSPORTABLE_FROM_SERVICE</Trans>
                    </Tag>
                );
            } else if (text === 'TRANSPORTABLE') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>TRANSPORTABLE</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_EXAMINED') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>NEED_TO_BE_EXAMINED</Trans>
                    </Tag>
                );
            } else if (text === 'OUTSOURCED') {
                element = (
                    <Tag color={'green'} key={text}>
                        <Trans>OUTSOURCED</Trans>
                    </Tag>
                );
            } else if (text === 'PUBLISHED') {
                element = (
                    <Tag color={'green'} key={text}>
                        <Trans>PUBLISHED</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_DISCARDED') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>NEED_TO_BE_DISCARDED</Trans>
                    </Tag>
                );
            } else if (text === 'SERVICED') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>SERVICED</Trans>
                    </Tag>
                );
            } else if (text === 'DURING_DELIVERY') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>DURING_DELIVERY</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_DELIVERED') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>NEED_TO_BE_DELIVERED</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_DISMANTLED') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>NEED_TO_BE_DISMANTLED</Trans>
                    </Tag>
                );
            } else if (text === 'INACTIVE') {
                element = (
                    <Tag color={'gray'} key={text}>
                        <Trans>INACTIVE</Trans>
                    </Tag>
                );
            } else if (text === 'DECOR_DAMAGED') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>DECOR_DAMAGED</Trans>
                    </Tag>
                );
            } else if (text === 'USED') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>USED</Trans>
                    </Tag>
                );
            } else if (text === 'WAIT_FOR_PERMISSION') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>WAIT_FOR_PERMISSION</Trans>
                    </Tag>
                );
            } else if (text === 'DISCARD') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>DISCARD</Trans>
                    </Tag>
                );
            } else if (text === 'REQUIRED') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>REQUIRED</Trans>
                    </Tag>
                );
            } else if (text === 'WAIT_FOR_COMMISSIONING') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>WAIT_FOR_COMMISSIONING</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_REPAIRED') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>NEED_TO_BE_REPAIRED</Trans>
                    </Tag>
                );
            } else if (text === 'FOUND_AT_STOCK_TAKING') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>FOUND_AT_STOCK_TAKING</Trans>
                    </Tag>
                );
            } else {
                element = (<div>{text}</div>);
            }
            return { props: { style: { background: deviceToColor } }, children: element };
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>TRANSPORTABLE_FROM_SERVICE</Trans>,
                value: 'TRANSPORTABLE_FROM_SERVICE',
            },
            {
                label: <Trans>TRANSPORTABLE</Trans>,
                value: 'TRANSPORTABLE',
            },
            {
                label: <Trans>NEED_TO_BE_EXAMINED</Trans>,
                value: 'NEED_TO_BE_EXAMINED',
            },
            {
                label: <Trans>OUTSOURCED</Trans>,
                value: 'OUTSOURCED',
            },
            {
                label: <Trans>PUBLISHED</Trans>,
                value: 'PUBLISHED',
            },
            {
                label: <Trans>NEED_TO_BE_DISCARDED</Trans>,
                value: 'NEED_TO_BE_DISCARDED',
            },
            {
                label: <Trans>SERVICED</Trans>,
                value: 'SERVICED',
            },
            {
                label: <Trans>DURING_DELIVERY</Trans>,
                value: 'DURING_DELIVERY',
            },
            {
                label: <Trans>NEED_TO_BE_DELIVERED</Trans>,
                value: 'NEED_TO_BE_DELIVERED',
            },
            {
                label: <Trans>NEED_TO_BE_DISMANTLED</Trans>,
                value: 'NEED_TO_BE_DISMANTLED',
            },
            {
                label: <Trans>NEED_TO_BE_REPAIRED</Trans>,
                value: 'NEED_TO_BE_REPAIRED',
            },
            {
                label: <Trans>INACTIVE</Trans>,
                value: 'INACTIVE',
            },
            {
                label: <Trans>DECOR_DAMAGED</Trans>,
                value: 'DECOR_DAMAGED',
            },
            {
                label: <Trans>USED</Trans>,
                value: 'USED',
            },
            {
                label: <Trans>WAIT_FOR_PERMISSION</Trans>,
                value: 'WAIT_FOR_PERMISSION',
            },
            {
                label: <Trans>DISCARD</Trans>,
                value: 'DISCARD',
            },
            {
                label: <Trans>REQUIRED</Trans>,
                value: 'REQUIRED',
            },
            {
                label: <Trans>WAIT_FOR_COMMISSIONING</Trans>,
                value: 'WAIT_FOR_COMMISSIONING',
            },
            {
                label: <Trans>FOUND_AT_STOCK_TAKING</Trans>,
                value: 'FOUND_AT_STOCK_TAKING',
            },
        ],
    });
    device1Columns.push({
        key: 'deviceRegisterTo.children',
        dataIndex: ['deviceRegisterTo', 'children'],
        filter: false,
        sorter: true,
        title: <Trans>Device Group</Trans>,
        width: 150,
        render: (text, record) => {
            if(record['isBulk']){
                return createDevices(deviceToColor, text);
            }else{
                return { props: { style: { background: deviceToColor } }, children: <></> };
            }
        },
    });

    device1Columns.push({
        key: 'deviceRegisterTo.warehouseCompany.name',
        dataIndex: ['deviceRegisterTo', 'warehouseCompany', 'name'],
        sorter: true,
        title: <Trans>Warehouse Company</Trans>,
        width: 150,
        render: text => {
            return { props: { style: { background: deviceToColor } }, children: <div>{text}</div> };
        },
    });

    columns.push({
        key: 'toDevice',
        filter: false,
        sorter: false,
        title: <Trans>Device 1</Trans>,
        children: device1Columns,
    });

    const device2Columns: Array<Column> = new Array();
    device2Columns.push({
        key: 'deviceRegisterFrom.deviceType.name',
        dataIndex: ['deviceRegisterFrom', 'deviceType', 'name'],
        sorter: true,
        title: <Trans>Device 2 Name</Trans>,
        width: 150,
        render: text => {
            return { props: { style: { background: '#f6ffed' } }, children: <div>{text}</div> };
        },
    });
    device2Columns.push({
        key: 'deviceRegisterFrom.serialNumber',
        dataIndex: ['deviceRegisterFrom', 'serialNumber'],
        sorter: true,
        title: <Trans>Device 2 Serial Number</Trans>,
        width: 140,
        render: text => {
            return { props: { style: { background: '#f6ffed' } }, children: <div>{text}</div> };
        },
    });
    device2Columns.push({
        key: 'deviceRegisterFrom.inventoryNumber',
        dataIndex: ['deviceRegisterFrom', 'inventoryNumber'],
        sorter: true,
        title: <Trans>Device 2 Inventory Number</Trans>,
        width: 138,
        render: text => {
            return { props: { style: { background: '#f6ffed' } }, children: <div>{text}</div> };
        },
    });
    device2Columns.push({
        key: 'deviceRegisterFrom.decor.name',
        dataIndex: ['deviceRegisterFrom', 'decor', 'name'],
        sorter: true,
        title: <Trans>Device 2 Decoration</Trans>,
        width: 140,
        render: text => {
            return { props: { style: { background: '#f6ffed' } }, children: <div>{text}</div> };
        },
    });
    device2Columns.push({
        key: 'deviceRegisterFrom.deviceStatus',
        dataIndex: ['deviceRegisterFrom', 'deviceStatus'],
        width: 180,
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let element: ReactElement;
            const color = 'geekblue';
            if (text === 'TRANSPORTABLE_FROM_SERVICE') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>TRANSPORTABLE_FROM_SERVICE</Trans>
                    </Tag>
                );
            } else if (text === 'TRANSPORTABLE') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>TRANSPORTABLE</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_EXAMINED') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>NEED_TO_BE_EXAMINED</Trans>
                    </Tag>
                );
            } else if (text === 'OUTSOURCED') {
                element = (
                    <Tag color={'green'} key={text}>
                        <Trans>OUTSOURCED</Trans>
                    </Tag>
                );
            } else if (text === 'PUBLISHED') {
                element = (
                    <Tag color={'green'} key={text}>
                        <Trans>PUBLISHED</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_DISCARDED') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>NEED_TO_BE_DISCARDED</Trans>
                    </Tag>
                );
            } else if (text === 'SERVICED') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>SERVICED</Trans>
                    </Tag>
                );
            } else if (text === 'DURING_DELIVERY') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>DURING_DELIVERY</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_DELIVERED') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>NEED_TO_BE_DELIVERED</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_DISMANTLED') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>NEED_TO_BE_DISMANTLED</Trans>
                    </Tag>
                );
            } else if (text === 'INACTIVE') {
                element = (
                    <Tag color={'gray'} key={text}>
                        <Trans>INACTIVE</Trans>
                    </Tag>
                );
            } else if (text === 'DECOR_DAMAGED') {
                element = (
                    <Tag color={'red'} key={text}>
                        <Trans>DECOR_DAMAGED</Trans>
                    </Tag>
                );
            } else if (text === 'USED') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>USED</Trans>
                    </Tag>
                );
            } else if (text === 'WAIT_FOR_PERMISSION') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>WAIT_FOR_PERMISSION</Trans>
                    </Tag>
                );
            } else if (text === 'DISCARD') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>DISCARD</Trans>
                    </Tag>
                );
            } else if (text === 'REQUIRED') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>REQUIRED</Trans>
                    </Tag>
                );
            } else if (text === 'WAIT_FOR_COMMISSIONING') {
                element = (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>WAIT_FOR_COMMISSIONING</Trans>
                    </Tag>
                );
            } else if (text === 'NEED_TO_BE_REPAIRED') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>NEED_TO_BE_REPAIRED</Trans>
                    </Tag>
                );
            } else if (text === 'FOUND_AT_STOCK_TAKING') {
                element = (
                    <Tag color={'orange'} key={text}>
                        <Trans>FOUND_AT_STOCK_TAKING</Trans>
                    </Tag>
                );
            } else {
                element = (<div>{text}</div>);
            }
            return { props: { style: { background: '#f6ffed' } }, children: element };
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>TRANSPORTABLE_FROM_SERVICE</Trans>,
                value: 'TRANSPORTABLE_FROM_SERVICE',
            },
            {
                label: <Trans>TRANSPORTABLE</Trans>,
                value: 'TRANSPORTABLE',
            },
            {
                label: <Trans>NEED_TO_BE_EXAMINED</Trans>,
                value: 'NEED_TO_BE_EXAMINED',
            },
            {
                label: <Trans>OUTSOURCED</Trans>,
                value: 'OUTSOURCED',
            },
            {
                label: <Trans>PUBLISHED</Trans>,
                value: 'PUBLISHED',
            },
            {
                label: <Trans>NEED_TO_BE_DISCARDED</Trans>,
                value: 'NEED_TO_BE_DISCARDED',
            },
            {
                label: <Trans>SERVICED</Trans>,
                value: 'SERVICED',
            },
            {
                label: <Trans>DURING_DELIVERY</Trans>,
                value: 'DURING_DELIVERY',
            },
            {
                label: <Trans>NEED_TO_BE_DELIVERED</Trans>,
                value: 'NEED_TO_BE_DELIVERED',
            },
            {
                label: <Trans>NEED_TO_BE_DISMANTLED</Trans>,
                value: 'NEED_TO_BE_DISMANTLED',
            },
            {
                label: <Trans>NEED_TO_BE_REPAIRED</Trans>,
                value: 'NEED_TO_BE_REPAIRED',
            },
            {
                label: <Trans>INACTIVE</Trans>,
                value: 'INACTIVE',
            },
            {
                label: <Trans>DECOR_DAMAGED</Trans>,
                value: 'DECOR_DAMAGED',
            },
            {
                label: <Trans>USED</Trans>,
                value: 'USED',
            },
            {
                label: <Trans>WAIT_FOR_PERMISSION</Trans>,
                value: 'WAIT_FOR_PERMISSION',
            },
            {
                label: <Trans>DISCARD</Trans>,
                value: 'DISCARD',
            },
            {
                label: <Trans>REQUIRED</Trans>,
                value: 'REQUIRED',
            },
            {
                label: <Trans>WAIT_FOR_COMMISSIONING</Trans>,
                value: 'WAIT_FOR_COMMISSIONING',
            },
            {
                label: <Trans>FOUND_AT_STOCK_TAKING</Trans>,
                value: 'FOUND_AT_STOCK_TAKING',
            },
        ],
    });
    device2Columns.push({
        key: 'deviceRegisterFrom.children',
        dataIndex: ['deviceRegisterFrom', 'children'],
        filter: false,
        sorter: true,
        title: <Trans>Device Group</Trans>,
        width: 150,
        render: (text, record) => {

            if(record['isBulk']){
                return createDevices('#f6ffed', text);
            }else{
                return { props: { style: { background: '#f6ffed' } }, children: <></> };
            }
        },
    });
    device2Columns.push({
        key: 'deviceRegisterFrom.serviceCompany.name',
        dataIndex: ['deviceRegisterFrom', 'serviceCompany', 'name'],
        sorter: true,
        title: <Trans>Service Company</Trans>,
        width: 150,
        render: text => {
            return { props: { style: { background: '#f6ffed' } }, children: <div>{text}</div> };
        },
    });
    device2Columns.push({
        key: 'deviceRegisterFrom.warehouseCompany.name',
        dataIndex: ['deviceRegisterFrom', 'warehouseCompany', 'name'],
        sorter: true,
        title: <Trans>Warehouse Company</Trans>,
        width: 150,
        render: text => {
            return { props: { style: { background: '#f6ffed' } }, children: <div>{text}</div> };
        },
    });
    columns.push({
        key: 'fromDevice',
        filter: false,
        sorter: false,
        title: <Trans>Device 2</Trans>,
        children: device2Columns,
    });

    columns.push({ key: 'remark', width: 200, sorter: true, title: <Trans>Remark</Trans> });
    columns.push({
        key: 'documentsUrl',
        width: 160,
        sorter: true,
        title: <Trans>documentsUrl</Trans>,
        render: text => {
            return createLinks(text);
        },
    });

    const deadlineColumns: Array<Column> = new Array();
    deadlineColumns.push({
        key: 'requestedDate',
        width: 140,
        dateFilter: true,
        sorter: true,
        title: <Trans>Requested Date</Trans>,
    });
    deadlineColumns.push({
        key: 'acceptedDate',
        width: 140,
        dateFilter: true,
        sorter: true,
        title: <Trans>Accepted Date</Trans>,
    });
    columns.push({
        key: 'deadlines',
        filter: false,
        sorter: false,
        title: <Trans>Deadline</Trans>,
        children: deadlineColumns,
    });

    columns.push({
        key: 'completedDate',
        width: 140,
        dateFilter: true,
        sorter: true,
        title: <Trans>Completed Date</Trans>,
    });
    columns.push({ key: 'createdBy', width: 140, sorter: true, title: <Trans>Created By</Trans> });
    columns.push({
        key: 'createdDate',
        width: 140,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Created Date</Trans>,
    });
    columns.push({ key: 'updatedBy', width: 140, sorter: true, title: <Trans>Updated By</Trans> });
    columns.push({
        key: 'updatedDate',
        width: 140,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Updated Date</Trans>,
        direction: 'desc',
    });

    columns.push({
        key: 'auroraNumber',
        width: 128,
        sorter: true,
        title: <Trans>HVillszer Nr.</Trans>,
        render: text => {
            return {
                props: { style: { background: '#fff0f0' } },
                children: (
                    <div>
                        <b>{text}</b>
                    </div>
                ),
            };
        },
    });

    columns.push({
        key: 'primeSolsNumber',
        width: 128,
        sorter: true,
        title: <Trans>PrimeSols Nr.</Trans>,
        render: text => {
            return {
                props: { style: { background: '#fff0f0' } },
                children: (
                    <div>
                        <b>{text}</b>
                    </div>
                ),
            };
        },
    });

    columns.push({
        key: 'projectId',
        width: 140,
        filter: true,
        sorter: true,
        title: <Trans>Project Id</Trans>,
    });

    const createLinks = (urls: any) => {
        let div = null;
        if (urls != null) {
            div = (
                <div>
                    {urls.split(',').map((item: string | undefined) => (
                        <div>
                            <a href={item} target={'_blank'}>
                                {item!.split('/').pop()}
                            </a>
                        </div>
                    ))}
                </div>
            );
        }

        return {
            children: <>{div}</>,
        };
    };

    const createDevices = (bgcolor: string, devices: any[]) => {
        let div = null;
        if (devices != null) {
            div = (
                <div>
                    {devices.map((item: any) => (
                        <Tooltip
                            placement="top"
                            title={
                                <div>
                                    <span style={{fontWeight: 'bold'}}><Trans>Device Type</Trans>: </span><div>{item['deviceType']['name']}</div>
                                    <span style={{fontWeight: 'bold'}}><Trans>Serial Number</Trans>: </span><div>{item['serialNumber']}</div>
                                    <span style={{fontWeight: 'bold'}}><Trans>Inventory Number</Trans>: </span><div>{item['inventoryNumber']}</div>
                                </div>
                            }
                            children={
                                <InfoCircleTwoTone
                                    twoToneColor="#1890ff"
                                    style={{ marginLeft: 5, fontSize: 'x-large' }}
                                />
                            }
                        />

                    ))
                    }
                </div>
            );
        }
        return {
            props: { style: { background: bgcolor, textAlign: "center" } },
            children: <>{div}</>,
        };
    };

    return {
        columns: columns,
        url: '/resource/work-requests',
    };
};
export default WorkRequestsModel();
