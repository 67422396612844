import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import { Tag } from 'antd';

const StockTakingHeadModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();

    const shopColor = '#f3fbf1';
    const shopColumns: Array<Column> = new Array();
    shopColumns.push({
        key: 'shop.code',
        dataIndex: ['shop', 'code'],
        sorter: true,
        title: <Trans>Shop Code</Trans>,
        width: 80,
        render: (text, row) => {
            return {
                props: { style: { background: shopColor } },
                children: (
                    <div>
                        <b>{text}</b>
                    </div>
                ),
            };
        },
    });
    shopColumns.push({
        key: 'shop.name',
        dataIndex: ['shop', 'name'],
        sorter: true,
        title: <Trans>Shop Name</Trans>,
        width: 130,
        render: (text, row) => {
            return { props: { style: { background: shopColor } }, children: <div>{text}</div> };
        },
    });
    shopColumns.push({
        key: 'shop.address.county',
        dataIndex: ['shop', 'address', 'county'],
        sorter: false,
        filter: false,
        title: <Trans>County</Trans>,
        width: 150,
        render: (text, row) => {
            return { props: { style: { background: shopColor } }, children: <div>{text}</div> };
        },
    });
    shopColumns.push({
        key: 'shop.address.postalCode',
        dataIndex: ['shop', 'address', 'postalCode'],
        sorter: true,
        title: <Trans>Postal Code</Trans>,
        width: 75,
        render: (text, row) => {
            return { props: { style: { background: shopColor } }, children: <div>{text}</div> };
        },
    });
    shopColumns.push({
        key: 'shop.address.city',
        dataIndex: ['shop', 'address', 'city'],
        sorter: false,
        title: <Trans>City</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopColor } }, children: <div>{text}</div> };
        },
    });
    shopColumns.push({
        key: 'shop.address.streetHouseNumber',
        dataIndex: ['shop', 'address', 'streetHouseNumber'],
        sorter: true,
        title: <Trans>Address</Trans>,
        width: 150,
        render: (text, row) => {
            return { props: { style: { background: shopColor } }, children: <div>{text}</div> };
        },
    });
    shopColumns.push({
        key: 'shop.chainName',
        dataIndex: ['shop', 'chainName'],
        sorter: false,
        title: <Trans>Lánc név</Trans>,
        width: 108,
        render: (text, row) => {
            return { props: { style: { background: shopColor } }, children: <div>{text}</div> };
        },
    });
    shopColumns.push({
        key: 'shop.regionalRepresentative.name',
        dataIndex: ['shop', 'regionalRepresentative', 'name'],
        sorter: true,
        title: <Trans>Regional Representative</Trans>,
        width: 130,
        render: (text, row) => {
            return { props: { style: { background: shopColor } }, children: <div>{text}</div> };
        },
    });
    columns.push({ key: 'shop', filter: false, sorter: false, title: <Trans>Shop</Trans>, children: shopColumns });

    columns.push({ key: 'date', width: 100, sorter: true, dateFilter: true, title: <Trans>Date</Trans> });

    columns.push({
        key: 'status',
        width: 150,
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let color = 'gray';

            if (text != null && text == 'IN_PROGRESS') {
                return (
                    <Tag color={'blue'} key={text}>
                        <Trans>IN_PROGRESS</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'CLOSED') {
                return (
                    <Tag color={'orange'} key={text}>
                        <Trans>CLOSED</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'APPROVED') {
                return (
                    <Tag color={'green'} key={text}>
                        <Trans>APPROVED</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'DENIED') {
                return (
                    <Tag color={'red'} key={text}>
                        <Trans>DENIED</Trans>
                    </Tag>
                );
            }
            return (
                <Tag color={color} key={text}>
                    {text}
                </Tag>
            );
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>IN_PROGRESS</Trans>,
                value: 'IN_PROGRESS',
            },
            {
                label: <Trans>CLOSED</Trans>,
                value: 'CLOSED',
            },
            {
                label: <Trans>APPROVED</Trans>,
                value: 'APPROVED',
            },
            {
                label: <Trans>DENIED</Trans>,
                value: 'DENIED',
            },
        ],
    });

    columns.push({ key: 'createdBy', width: 200, sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({
        key: 'createdDate',
        filter: false,
        width: 200,
        direction: 'desc',
        sorter: true,
        title: <Trans>Létrehozva</Trans>,
    });

    return {
        columns: columns,
        url: '/resource/stock-taking-heads',
    };
};
export default StockTakingHeadModel();
