import { Trans } from '@lingui/macro';
import React from 'react';
import { Breadcrumb } from 'antd';
import DtoTable from '../../components/dto-table/dto-table';
import ElixirFileContentModel from '../../models/dto/elixir-file-content-model';

const ElixirSyncFileScreen = () => {
    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Külső adatkapcsolat</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Elixir Sync</Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable model={ElixirFileContentModel} pageSize={100} scroll={{ x: 1300, y: '65vh' }} />
        </>
    );
};
export default ElixirSyncFileScreen;
