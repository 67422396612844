import useSafeState from '../../utils/safe-state';
import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, message } from 'antd';
import { Trans } from '@lingui/macro';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons/lib';
import { useAxios } from '../../utils/hooks';
import { MessageModel } from '../../models/message/message';
import { openNotification } from '../../utils/openNotification';


interface Interface {
    data?: MessageModel;
    mode?: 'Edit' | 'Add new' | 'Upload File';
    onClose?: () => void;
}

const MessageForm = (props: Interface) => {
    const [loading, setLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const data = props.data || {};
    const [form] = Form.useForm();
    const [fileList, setFileList] = useSafeState<Array<any>>([]);
    const [uploading, setUploading] = useSafeState(false);

    let isEditMode = false;

    type optionalType = {
        label: string;
        value: string;
    };

    useEffect(() => {
        form.resetFields();

        if (props.mode === 'Edit') {
            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({ name: key, value: value });
            }
            form.setFields(fieldsValues);

            isEditMode = true;
        } else if (props.mode === 'Add new') {
            //let fieldsValues = [{name: "deviceTypeCategory", value: "COOLER"}];
            //form.setFields(fieldsValues);
            isEditMode = false;
        }
    }, []);

    const onReset = () => {
        form.resetFields();
    };

    const config = { headers: { 'Content-Type': 'application/json' } };

    const handleEdit = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current
                .put(
                    '/resource/messages/id/' +
                        form.getFieldValue(['language']) +
                        '/' +
                        form.getFieldValue(['messageCode']),
                    {
                        messageId: {
                            language: form.getFieldValue(['language']),
                            messageCode: form.getFieldValue(['messageCode']),
                        },
                        translated: form.getFieldValue(['translated']),
                        version: form.getFieldValue(['version']),
                    },
                    config,
                )
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const handleUploadCsv = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });

            axiosInstance.current
                .post('/resource/messages/upload-csv', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const uploadProps = {
        name: 'pic',
        action: process.env.REACT_APP_API_BASE_URL + '/resource/messages/upload-csv',
        multiple: false,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading', info.file, info.fileList);
            }
            if (status === 'done') {
                openNotification('customSuccess', `${info.file.name} "file uploaded successfully."`);
            } else if (status === 'error') {
                openNotification('customError', `${info.file.name} "file upload failed."`);
            }
        },
        beforeUpload(file: any) {
            let fileList2: Array<any> = fileList;
            fileList2.push(file);
            setFileList(fileList2);
            return false;
        },
    };

    return (
        <Form id={props.mode} form={form} layout="vertical" onFinish={() => handleEdit()}>
            {props.mode === 'Edit' || props.mode === 'Add new' ? (
                <>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="language"
                                label={<Trans>Language</Trans>}
                                children={<Input disabled={true} />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please type a language</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="messageCode"
                                label={<Trans>Message code</Trans>}
                                children={<Input disabled={true} />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please type a message code</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="translated"
                                label={<Trans>Translate</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please select a type</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Form.Item
                        name="fenykep"
                        children={
                            <Dragger {...uploadProps}>
                                <div></div>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    <Trans>Click or drag file to this area to upload</Trans>
                                </p>
                                <p className="ant-upload-hint">
                                    <Trans>
                                        Support for a single upload. Strictly prohibit from uploading company data or
                                        other band files
                                    </Trans>
                                </p>
                            </Dragger>
                        }
                    />
                    <br />
                </>
            )}
            {props.mode === 'Edit' ? (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
            ) : props.mode === 'Add new' ? (
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() => onReset()}>
                        <Trans>Vissza</Trans>
                    </Button>
                    <Button
                        loading={loading}
                        htmlType="button"
                        type="primary"
                        style={{ marginRight: 8 }}
                        onClick={() => form.submit()}
                    >
                        <Trans>Mentés</Trans>
                    </Button>
                </>
            ) : (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => handleUploadCsv()}>
                    <Trans>Feltöltés</Trans>
                </Button>
            )}
        </Form>
    );
};
export default MessageForm;
