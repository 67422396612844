import moment from 'moment';
import useSafeState from '../utils/safe-state';
import { useAxios } from '../utils/hooks';
import * as React from 'react';
import { BrowserRouter as Router, Link, Switch } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { PrivateRoute } from './utils';
import { Button, Col, DatePicker, Dropdown, Form, Layout, Menu, Modal, Row, Tooltip } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import LandingScreen from '../screens/landing/landing';
import ShopScreen from '../screens/shops/shops';
import MovementHistoryScreen from '../screens/movement-history/movement-history';
import LanguageChange from '../components/language-change/language-change';
import { i18n } from '@lingui/core';
import { Language } from '../models/language/language';

import hu from '../locales/hu/messages.json';
import en from '../locales/en/messages.json';
import { Trans } from '@lingui/macro';
import ElixirSyncScreen from '../screens/elixir-sync/elixir-sync';
import ElixirSyncFileScreen from '../screens/elixir-sync/elixir-sync-file';
import StockTakingScreen from '../screens/stock-taking/stock-taking';
import StockTakingLineScreen from '../screens/stock-taking/stock-taking-line';
import RegionalRepresentativesScreen from '../screens/regional-representatives/regional-representatives';
import DeviceRegisterScreen from '../screens/device-register/device-register';
import WorkRequestsScreen from '../screens/work-requests/work-requests';
import UserManagementScreen from '../screens/user-management/user-management';
import AuroraConnectionScreen from '../screens/aurora-connection/aurora-connection';
import RepresentativeDeputiesScreen from '../screens/representative-deputies/representative-deputies';

import '../routes/App.css';
import './App.css';
import 'antd/dist/antd.css';
import '../routes/index.css';
import logo from '../images/head_logo.png';
import huFlag from '../icons/hu.png';
import enFlag from '../icons/en.png';
import {
    DatabaseOutlined,
    FormOutlined,
    HistoryOutlined,
    HomeOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    ContactsOutlined,
    CopyrightOutlined,
    ExclamationCircleOutlined,
    FileDoneOutlined,
    FlagOutlined,
    KeyOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    ShrinkOutlined,
    SwapOutlined,
    TeamOutlined,
    UsergroupAddOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons/lib';

import { Footer } from 'antd/lib/layout/layout';
import DeviceTypesScreen from '../screens/device-types/device-types';
import MessageScreen from '../screens/message/message';
import { AxiosResponse } from 'axios';
import { openNotification } from '../utils/openNotification';
import { antdContext } from '../utils/antdContext';
import SupportIcon from '../components/icons/support-icon';
import Icon from '@ant-design/icons';
import ServiceCompaniesScreen from "../screens/service-companies/service-companies";
import WarehouseCompaniesScreen from "../screens/warehouse-companies/warehouse-companies";
import PrimeSolsRequestScreen from '../screens/prime-sols-requests/prime-sols-requests';

const { Header, Sider, Content } = Layout;

const { confirm } = Modal;

export const AppRouter = () => {
    const SupportIco = (props: any) => <Icon component={SupportIcon} {...props} />;

    const { initialized, keycloak } = useKeycloak();
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');
    const myStorage = window.localStorage;

    const languageModel: Language[] = [
        {
            language: 'hu',
            icon: huFlag,
        },
        {
            language: 'en',
            icon: enFlag,
        },
    ];

    const [loggedUser, setLoggedUser] = useSafeState<any>('');
    const [deputies, setDeputies] = useSafeState<any>([]);
    const [deputized, setDeputized] = useSafeState<any>([]);
    const [outOfOfficeEndDate, setOutOfOfficeEndDate] = useSafeState<any>('');
    const [collapsed, setCollapsed] = useSafeState(false);
    const [isVisibleDeputies, setIsVisibleDeputies] = useSafeState(false);
    const [isVisibleDeputized, setIsVisibleDeputized] = useSafeState(false);
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const [form] = Form.useForm();
    form.resetFields();

    const isAutherized = (roles: any[]) => {
        if (keycloak != null && keycloak.authenticated && keycloak && roles) {
            return roles.some(r => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    };

    React.useEffect(() => {
        if (keycloak && keycloak.tokenParsed) {
            let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

            if (keycloak.tokenParsed != null) {
                setLoggedUser(obj.preferred_username);
                localStorage.setItem('username', obj.preferred_username);
            }
            if (fcrAxiosInstance.current != null) {
                fcrAxiosInstance.current
                    .get('/resource/regional-representatives/username?username=' + obj.preferred_username)
                    .then(function (response: AxiosResponse<any>) {
                        setOutOfOfficeEndDate(response.data.outOfOfficeEndDate);
                        localStorage.setItem('outOfOfficeEndDate', response.data.outOfOfficeEndDate);
                        localStorage.setItem('rank', response.data.rank);
                        localStorage.setItem('region', response.data.region);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });

                fcrAxiosInstance.current
                    .get('/resource/regional-representatives/deputies')
                    .then(function (response: AxiosResponse<any>) {
                        setDeputies(response.data);
                        setIsVisibleDeputies(response.data.length > 0);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });

                fcrAxiosInstance.current
                    .get('/resource/regional-representatives/deputized')
                    .then(function (response: AxiosResponse<any>) {
                        setDeputized(response.data);
                        setIsVisibleDeputized(response.data.length > 0);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    }, [initialized, keycloak]);

    const [language, setLanguage] = useSafeState(myStorage.getItem('language'));
    i18n.load('hu', hu);
    i18n.load('en', en);
    i18n.activate(localStorage.getItem('language') ? localStorage.getItem('language') + '' : 'hu');

    const openWikiJs = () => {
        let pathname = window.location.pathname == '/' ? '/home' : window.location.pathname;

        window.open(process.env.REACT_APP_WIKI_URL + pathname, '_blank');
    };

    if (!initialized) {
        return <div>Loading...</div>;
    }

    const menu = (
        <Menu>
            <Menu.Item
                onClick={() =>
                    confirm({
                        title: 'Kérlek add meg a távolléted vég dátumát!',
                        icon: <ExclamationCircleOutlined />,
                        content: (
                            <Form form={form} style={{ marginTop: 30 }}>
                                <Form.Item
                                    name="endDate"
                                    label={antdContext('Vég dátum')}
                                    children={
                                        outOfOfficeEndDate != null ? (
                                            <DatePicker
                                                defaultValue={moment(outOfOfficeEndDate, 'YYYY-MM-DD')}
                                                format={'YYYY-MM-DD'}
                                                placeholder={'YYYY-MM-DD'}
                                            />
                                        ) : (
                                            <DatePicker />
                                        )
                                    }
                                    rules={[
                                        {
                                            type: 'date',
                                            required: true,
                                        },
                                    ]}
                                />
                            </Form>
                        ),
                        visible: true,
                        onOk() {
                            return new Promise((resolve, reject) => {
                                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);

                                if (fcrAxiosInstance.current != null && form.getFieldValue(['endDate']) !== undefined) {
                                    fcrAxiosInstance.current
                                        .put(
                                            '/resource/regional-representatives/set-out-of-office-end-date' +
                                                '?date=' +
                                                form.getFieldValue(['endDate']).format('YYYY-MM-DD') +
                                                '&username=' +
                                                localStorage.getItem('username'),
                                        )
                                        .then(() => {
                                            openNotification('customSuccess', 'Sikeres mentés!');
                                            window.location.reload();
                                        })
                                        .catch(function (error: any) {
                                            openNotification('error', error);
                                            console.log(error);
                                        });
                                }
                            }).catch(function (error: any) {
                                console.log(error);
                            });
                        },
                        onCancel() {},
                    })
                }
            >
                <span
                    style={{
                        fontSize: 'small',
                        cursor: 'pointer',
                        marginRight: 20,
                    }}
                >
                    <span style={{ marginRight: 5 }}>
                        <ContactsOutlined />
                    </span>
                    <span>
                        <Trans>Set out of office date</Trans>
                    </span>
                </span>
            </Menu.Item>
            <Menu.Item
                onClick={() => {
                    console.log(window.location.origin);
                    keycloak.redirectUri = window.location.origin;
                    keycloak.logout();
                    localStorage.clear();
                }}
            >
                <span
                    style={{
                        fontSize: 'small',
                        cursor: 'pointer',
                        marginRight: 20,
                    }}
                >
                    <span style={{ marginRight: 5 }}>
                        <LogoutOutlined />
                    </span>
                    <span>
                        <Trans>Logout</Trans>
                    </span>
                </span>
            </Menu.Item>
        </Menu>
    );

    return (
        <Router>
            <Layout style={{minHeight: '100vh' }}>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <Menu theme={'dark'} mode="inline" defaultSelectedKeys={['1']}>
                        <Menu.Item key="1" icon={<HomeOutlined />}>
                            <Link to="./">
                                <Trans>Kezdőoldal</Trans>
                            </Link>
                        </Menu.Item>
                        <>
                            <SubMenu
                                key="MasterDataSubMenu"
                                icon={<DatabaseOutlined />}
                                title={<Trans>Törzsadatok</Trans>}
                            >
                                {isAutherized(['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER', 'FCR_REGIONAL_REPRESENTATIVE', 'FCR_SALES_VAN']) ? (
                                    <Menu.Item key="shops">
                                        <Link to="/shops">
                                            <Trans>Boltok</Trans>
                                        </Link>
                                    </Menu.Item>
                                ) : (
                                    <></>
                                )}
                                {isAutherized(['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER', 'FCR_REGIONAL_REPRESENTATIVE', 'FCR_SALES_VAN']) ? (
                                    <>
                                    <Menu.Item key="warehouse-companies">
                                        <Link to="/warehouse-companies">
                                            <Trans>Warehouse Companies</Trans>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="service-companies">
                                        <Link to="/service-companies">
                                            <Trans>Service Companies</Trans>
                                        </Link>
                                    </Menu.Item>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {isAutherized(['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']) ? (
                                    <>
                                        <Menu.Item key="device-types">
                                            <Link to="/device-types">
                                                <Trans>Eszköz típusok</Trans>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="regional-representatives">
                                            <Link to="/regional-representatives">
                                                <Trans>Területi képviselők</Trans>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="representative-deputies">
                                            <Link to="/representative-deputies">
                                                <Trans>Deputies</Trans>
                                            </Link>
                                        </Menu.Item>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </SubMenu>
                            {isAutherized(['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']) ? (
                                <Menu.Item key="deviceRegister" icon={<FormOutlined />}>
                                    <Link to="/device-register">
                                        <Trans>Eszköz nyílvántartó</Trans>
                                    </Link>
                                </Menu.Item>
                            ) : (
                                <></>
                            )}
                            {isAutherized(['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']) ? (
                                <Menu.Item key="stockTaking" icon={<FileDoneOutlined />}>
                                    <Link to="/stock-taking">
                                        <Trans>Leltár</Trans>
                                    </Link>
                                </Menu.Item>
                            ) : (
                                <></>
                            )}
                        </>
                        {isAutherized(['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER', 'FCR_REGIONAL_REPRESENTATIVE', 'FCR_SALES_VAN']) ? (
                            <>
                                <Menu.Item key="operations" icon={<ShrinkOutlined />}>
                                    <Link to="/work-requests">
                                        <Trans>Műveletek</Trans>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="moveHist" icon={<HistoryOutlined />}>
                                    <Link to="/movement-history">
                                        <Trans>Mozgástörténet</Trans>
                                    </Link>
                                </Menu.Item>
                            </>
                        ) : (
                            <></>
                        )}

                        {isAutherized(['FCR_IT_ADMIN', 'FCR_ADMIN']) ? (
                            <>
                                <Menu.Item key="7" icon={<UserOutlined />}>
                                    <Link to="/user-management">
                                        <Trans>Felhasználó kezelés</Trans>
                                    </Link>
                                </Menu.Item>

                                <SubMenu
                                    key="IntefaceSubMenu"
                                    icon={<SwapOutlined />}
                                    title={<Trans>Külső adatkapcsolatok</Trans>}
                                >
                                    <Menu.Item key="elixir" icon={<ArrowDownOutlined />}>
                                        <Link to="/elixir-sync">
                                            <Trans>Elixir szinkron</Trans>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="6" icon={<ArrowUpOutlined />}>
                                        <Link to="/aurora-connection">
                                            <Trans>Auróra kapcsolat</Trans>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="primeSols" icon={<ShrinkOutlined />}>
                                        <Link to="/prime-sols-requests">
                                            <Trans>PrimeSols</Trans>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            </>
                        ) : (
                            <></>
                        )}
                        {isAutherized(['FCR_IT_ADMIN', 'FCR_ADMIN']) ? (
                            <>
                                <Menu.Item key="8" icon={<FlagOutlined />}>
                                    <Link to="/messages">
                                        <Trans>Fordítás kezelése</Trans>
                                    </Link>
                                </Menu.Item>
                            </>
                        ) : (
                            <></>
                        )}
                    </Menu>
                </Sider>
                <Layout className="site-layout ">
                    <Header className="site-layout-background" style={{ textAlign: 'center', padding: 0 }}>
                        <Row>
                            <Col span={10}>
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: toggleCollapse,
                                    style: { float: 'left' },
                                })}
                            </Col>
                            <Col span={4}>
                                <span style={{ textAlign: 'center', width: 125 }}>
                                    <img src={logo} />
                                </span>
                            </Col>
                            <Col span={10}>
                                <span style={{ float: 'right' }}>
                                    <Tooltip placement="bottom" title={<Trans>Help</Trans>}>
                                        <span style={{ verticalAlign: 'middle' }}>
                                            <QuestionCircleOutlined
                                                style={{ marginRight: 20, fontSize: 'x-large' }}
                                                onClick={() => openWikiJs()}
                                            />
                                        </span>
                                    </Tooltip>

                                    <Tooltip placement="bottom" title={<Trans>Support</Trans>}>
                                        <span style={{ verticalAlign: 'middle' }}>
                                            <a
                                                style={{ textDecoration: 'none', color: 'black' }}
                                                href={'mailto:support@logicloud.hu'}
                                            >
                                                <SupportIco style={{ marginRight: 20, fontSize: 'x-large' }} />
                                            </a>
                                        </span>
                                    </Tooltip>

                                    {isVisibleDeputies ? (
                                        <Tooltip
                                            placement="bottom"
                                            title={
                                                <>
                                                    <Trans>Helyetteseid:</Trans>
                                                    {deputies.map((dep: any) => (
                                                        <div>{dep ? dep['name'] : null}</div>
                                                    ))}
                                                </>
                                            }
                                        >
                                            <span style={{ verticalAlign: 'middle' }}>
                                                <ContactsOutlined style={{ marginRight: 20, fontSize: 'x-large' }} />
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <></>
                                    )}

                                    {isVisibleDeputized ? (
                                        <Tooltip
                                            placement="bottom"
                                            title={
                                                <>
                                                    <Trans>Őket helyettesíted:</Trans>
                                                    {deputized.map((dep: any) => (
                                                        <div>{dep ? dep['name'] : null}</div>
                                                    ))}
                                                </>
                                            }
                                        >
                                            <span style={{ verticalAlign: 'middle' }}>
                                                <UsergroupAddOutlined
                                                    style={{ marginRight: 20, fontSize: 'x-large' }}
                                                />
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <></>
                                    )}

                                    <span className={process.env.REACT_APP_ENVIRONMENT}>
                                        {process.env.REACT_APP_ENVIRONMENT}
                                    </span>

                                    <LanguageChange
                                        handleLanguageChange={e => {
                                            setLanguage(e.key);
                                            myStorage.setItem('language', e.key);
                                        }}
                                        activeLanguage={
                                            localStorage.getItem('language')
                                                ? localStorage.getItem('language') + ''
                                                : 'hu'
                                        }
                                        languages={languageModel}
                                    />

                                    <Dropdown overlay={menu} placement="bottomLeft">
                                        <Button style={{ border: 0 }}>
                                            <span style={{ fontSize: 'large' }}>{loggedUser} </span>
                                        </Button>
                                    </Dropdown>
                                </span>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '12px 8px',
                            padding: 20,
                        }}
                    >
                        <Switch>
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER', 'FCR_REGIONAL_REPRESENTATIVE', 'FCR_SALES_VAN']}
                                exact
                                path="/"
                                component={LandingScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER', 'FCR_REGIONAL_REPRESENTATIVE', 'FCR_SALES_VAN']}
                                exact
                                path="/shops"
                                component={ShopScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER', 'FCR_REGIONAL_REPRESENTATIVE', 'FCR_SALES_VAN']}
                                exact
                                path="/warehouse-companies"
                                component={WarehouseCompaniesScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER', 'FCR_REGIONAL_REPRESENTATIVE', 'FCR_SALES_VAN']}
                                exact
                                path="/service-companies"
                                component={ServiceCompaniesScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']}
                                exact
                                path="/regional-representatives"
                                component={RegionalRepresentativesScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']}
                                exact
                                path="/device-types"
                                component={DeviceTypesScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER', 'FCR_REGIONAL_REPRESENTATIVE', 'FCR_SALES_VAN']}
                                exact
                                path="/movement-history"
                                component={MovementHistoryScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER', 'FCR_REGIONAL_REPRESENTATIVE', 'FCR_SALES_VAN']}
                                exact
                                path="/work-requests"
                                component={WorkRequestsScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']}
                                exact
                                path="/device-register"
                                component={DeviceRegisterScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']}
                                exact
                                path="/stock-taking"
                                component={StockTakingScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']}
                                path="/stock-taking-lines/:id"
                                component={StockTakingLineScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']}
                                exact
                                path="/representative-deputies"
                                component={RepresentativeDeputiesScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN']}
                                exact
                                path="/elixir-sync"
                                component={ElixirSyncScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN']}
                                path="/elixir-sync-file/:id"
                                component={ElixirSyncFileScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN']}
                                exact
                                path="/aurora-connection"
                                component={AuroraConnectionScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN']}
                                exact
                                path="/prime-sols-requests"
                                component={PrimeSolsRequestScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN']}
                                exact
                                path="/user-management"
                                component={UserManagementScreen}
                            />
                            <PrivateRoute
                                roles={['FCR_IT_ADMIN', 'FCR_ADMIN']}
                                exact
                                path="/messages"
                                component={MessageScreen}
                            />
                        </Switch>
                    </Content>
                    <Footer
                        style={{
                            textAlign: 'center',
                            fontSize: 10,
                            paddingTop: 0,
                            paddingRight: 10,
                            paddingLeft: 10,
                            paddingBottom: 9,
                        }}
                    >
                        <Row>
                            <Col span={8}></Col>
                            <Col span={8}>
                                <CopyrightOutlined />{' '}
                                <span>
                                    {' '}
                                    2021 Copyright Friesland Hungária Zrt. | Created by{' '}
                                    <a href="https://www.logicloud.hu" target="_blank">
                                        LogiCloud Kft.
                                    </a>
                                </span>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                                {process.env.REACT_APP_VERSION + '-SNAPSHOT'}
                            </Col>
                        </Row>
                    </Footer>
                </Layout>
            </Layout>
        </Router>
    );
};
