import useSafeState from '../../utils/safe-state';
import React from 'react';
import { Form, Row, Col, Input, Button, Switch, message } from 'antd';
import { Trans } from '@lingui/macro';
import { UserManagementModel } from '../../models/user-management/user-management';
import { useAxios } from '../../utils/hooks';
import { openNotification } from '../../utils/openNotification';

interface Interface {
    data?: UserManagementModel[];
    mode?: 'Edit' | 'Add new';
    onClose?: () => void;
}

const UserManagementForm = (props: Interface) => {
    const axiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + '');

    const data = props.data || {};
    const [form] = Form.useForm();
    const [enabled, setEnabled] = useSafeState(true);
    form.resetFields();

    if (props.mode === 'Edit') {
        let fieldsValues = [];
        for (const [key, value] of Object.entries(data)) {
            fieldsValues.push({ name: key, value: value });
        }
        form.setFields(fieldsValues);
    } else {
        let fieldsValues = [];
        fieldsValues.push({ name: 'enabled', value: true });
        form.setFields(fieldsValues);
    }

    React.useEffect(() => {
        if (form.getFieldValue(['enabled']) != null && form.getFieldValue(['enabled']) != undefined) {
            setEnabled(form.getFieldValue(['enabled']));
        }
    }, []);

    const onReset = () => {
        form.resetFields();
    };

    const handleEdit = () => {
        if (!axiosInstance.current) {
            return;
        }
        axiosInstance.current
            .put('/auth/admin/realms/friesland-cooler-register/users/' + form.getFieldValue(['id']), {
                firstName: form.getFieldValue(['firstName']),
                lastName: form.getFieldValue(['lastName']),
                email: form.getFieldValue(['email']),
                enabled: form.getFieldValue(['enabled']),
            })
            .then(() => {
                openNotification('success', '', props);
            })
            .catch(function (error: any) {
                openNotification('error', error, props);
            });
    };

    const handleAddNew = () => {
        if (!axiosInstance.current) {
            return;
        }
        axiosInstance.current
            .post(process.env.REACT_APP_API_KEYCLOAK_URL + '/auth/admin/realms/friesland-cooler-register/users', {
                firstName: form.getFieldValue(['firstName']),
                lastName: form.getFieldValue(['lastName']),
                email: form.getFieldValue(['email']),
                enabled: form.getFieldValue(['enabled']),
                username: form.getFieldValue(['username']),
            })
            .then(function (response: any) {
                openNotification('success', '', props);
            })
            .catch(function (error: any) {
                openNotification('error', error, props);
            });
    };

    return (
        <Form
            id={props.mode}
            form={form}
            layout="vertical"
            onFinish={() => (props.mode === 'Edit' ? handleEdit() : handleAddNew())}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="username"
                        label={<Trans>Felhasználónév</Trans>}
                        children={props.mode === 'Edit' ? <Input disabled={true} /> : <Input />}
                        rules={[
                            {
                                type: 'string',
                                pattern: new RegExp(/^(?! +$).+$/),
                                required: true,
                                message: <Trans>Please enter a username</Trans>,
                            },
                        ]}
                    />
                </Col>
                <Col span={12}>
                    <div style={{ marginBottom: 8 }}>
                        <Form.Item
                            name="enabled"
                            label={<Trans>Aktív?</Trans>}
                            children={<Switch defaultChecked={enabled} />}
                        />
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        name="lastName"
                        label={<Trans>Last Name</Trans>}
                        children={<Input />}
                        rules={[
                            {
                                type: 'string',
                                pattern: new RegExp(/^(?! +$).+$/),
                                required: true,
                                message: <Trans>Please enter a last name</Trans>,
                            },
                        ]}
                    />
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="firstName"
                        label={<Trans>First Name</Trans>}
                        children={<Input />}
                        rules={[
                            {
                                type: 'string',
                                pattern: new RegExp(/^(?! +$).+$/),
                                required: true,
                                message: <Trans>Please enter a first name</Trans>,
                            },
                        ]}
                    />
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="email"
                        label={<Trans>Email</Trans>}
                        children={<Input />}
                        rules={[
                            {
                                type: 'string',
                                pattern: new RegExp(/^(?! +$).+$/),
                                required: true,
                                message: <Trans>Please enter a email address</Trans>,
                            },
                        ]}
                    />
                </Col>
            </Row>
            {props.mode === 'Edit' ? (
                <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
            ) : (
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() => onReset()}>
                        <Trans>Visszaállítás</Trans>
                    </Button>
                    <Button htmlType="button" type="primary" style={{ marginRight: 8 }} onClick={() => form.submit()}>
                        <Trans>Hozzáadás</Trans>
                    </Button>
                </>
            )}
        </Form>
    );
};
export default UserManagementForm;
