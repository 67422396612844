import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import { Tag } from 'antd';

const RegionalRepresentativesModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();
    columns.push({
        key: 'name',
        width: 200,
        sorter: true,
        title: <Trans>Név</Trans>,
        fixed: 'left',
        direction: 'asc',
        render: text => {
            return <b>{text}</b>;
        },
    });
    columns.push({ key: 'username', width: 200, sorter: true, title: <Trans>Felhasználónév</Trans> });
    columns.push({ key: 'email', width: 300, sorter: true, title: <Trans>Email</Trans> });
    columns.push({ key: 'phone', width: 120, sorter: true, title: <Trans>Tel.</Trans> });
    columns.push({
        key: 'region',
        width: 110,
        sorter: true,
        title: <Trans>Region</Trans>,
        render: text => {
            let color = 'gray';
            if (text === 'CENTRAL') {
                color = 'gold';
                return (
                    <Tag color={color} key={text}>
                        <Trans>CENTRAL</Trans>
                    </Tag>
                );
            } else if (text === 'SOUTH') {
                color = 'green';
                return (
                    <Tag color={color} key={text}>
                        <Trans>SOUTH</Trans>
                    </Tag>
                );
            } else if (text === 'NORTH') {
                color = 'magenta';
                return (
                    <Tag color={color} key={text}>
                        <Trans>NORTH</Trans>
                    </Tag>
                );
            } else if (text === 'EAST') {
                color = 'cyan';
                return (
                    <Tag color={color} key={text}>
                        <Trans>EAST</Trans>
                    </Tag>
                );
            } else if (text === 'WEST') {
                color = 'blue';
                return (
                    <Tag color={color} key={text}>
                        <Trans>WEST</Trans>
                    </Tag>
                );
            } else if (text === 'OOH') {
                color = 'purple';
                return (
                    <Tag color={color} key={text}>
                        <Trans>OOH</Trans>
                    </Tag>
                );
            } else if (text === 'RETAIL') {
                color = 'orange';
                return (
                    <Tag color={color} key={text}>
                        <Trans>RETAIL</Trans>
                    </Tag>
                );
            } else if (text === 'ALL') {
                color = 'red';
                return (
                    <Tag color={color} key={text}>
                        <Trans>ALL</Trans>
                    </Tag>
                );
            }

            return (
                <Tag color={color} key={text}>
                    {text}
                </Tag>
            );
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>CENTRAL</Trans>,
                value: 'CENTRAL',
            },
            {
                label: <Trans>SOUTH</Trans>,
                value: 'SOUTH',
            },
            {
                label: <Trans>NORTH</Trans>,
                value: 'NORTH',
            },
            {
                label: <Trans>EAST</Trans>,
                value: 'EAST',
            },
            {
                label: <Trans>WEST</Trans>,
                value: 'WEST',
            },
            {
                label: <Trans>OOH</Trans>,
                value: 'OOH',
            },
            {
                label: <Trans>RETAIL</Trans>,
                value: 'RETAIL',
            },
            {
                label: <Trans>ALL</Trans>,
                value: 'ALL',
            },
        ],
    });
    columns.push({
        key: 'rank',
        width: 280,
        sorter: true,
        title: <Trans>Rank</Trans>,
        render: text => {
            let color = 'gray';
            switch (text) {
                case 'ADMINISTRATOR': {
                    return (
                        <Tag color={'red'} key={text}>
                            <Trans>ADMINISTRATOR</Trans>
                        </Tag>
                    );
                }
                case 'FIELD_SALES_MANAGER': {
                    return (
                        <Tag color={'orange'} key={text}>
                            <Trans>FIELD_SALES_MANAGER</Trans>
                        </Tag>
                    );
                }
                case 'KEY_ACCOUNT_MANAGER': {
                    return (
                        <Tag color={'green'} key={text}>
                            <Trans>KEY_ACCOUNT_MANAGER</Trans>
                        </Tag>
                    );
                }
                case 'SHOPPER_MARKETING_MANAGER': {
                    return (
                        <Tag color={'purple'} key={text}>
                            <Trans>SHOPPER_MARKETING_MANAGER</Trans>
                        </Tag>
                    );
                }
                case 'SHOPPER_MARKETING_SPECIALIST': {
                    return (
                        <Tag color={'red'} key={text}>
                            <Trans>SHOPPER_MARKETING_SPECIALIST</Trans>
                        </Tag>
                    );
                }
                case 'REGIONAL_REPRESENTATIVE': {
                    return (
                        <Tag color={'green'} key={text}>
                            <Trans>REGIONAL_REPRESENTATIVE</Trans>
                        </Tag>
                    );
                }
                case 'REGIONAL_LEADER': {
                    return (
                        <Tag color={'magenta'} key={text}>
                            <Trans>REGIONAL_LEADER</Trans>
                        </Tag>
                    );
                }
                case 'SALES_REPRESENTATIVE': {
                    return (
                        <Tag color={'green'} key={text}>
                            <Trans>SALES_REPRESENTATIVE</Trans>
                        </Tag>
                    );
                }

                default:
                    return (
                        <Tag color={color} key={text}>
                            {text}
                        </Tag>
                    );
            }
        },
        uniqueSelectFilterData: [
            { label: <Trans>NONE</Trans>, value: null },
            { label: <Trans>ADMINISTRATOR</Trans>, value: 'ADMINISTRATOR' },
            { label: <Trans>FIELD_SALES_MANAGER</Trans>, value: 'FIELD_SALES_MANAGER' },
            { label: <Trans>SALES_REPRESENTATIVE</Trans>, value: 'SALES_REPRESENTATIVE' },
            { label: <Trans>KEY_ACCOUNT_MANAGER</Trans>, value: 'KEY_ACCOUNT_MANAGER' },
            { label: <Trans>SHOPPER_MARKETING_MANAGER</Trans>, value: 'SHOPPER_MARKETING_MANAGER' },
            { label: <Trans>SHOPPER_MARKETING_SPECIALIST</Trans>, value: 'SHOPPER_MARKETING_SPECIALIST' },
            { label: <Trans>REGIONAL_REPRESENTATIVE</Trans>, value: 'REGIONAL_REPRESENTATIVE' },
            { label: <Trans>REGIONAL_LEADER</Trans>, value: 'REGIONAL_LEADER' },
        ],
    });
    columns.push({ key: 'sfaCode', width: 80, sorter: true, title: <Trans>SFA</Trans> });
    columns.push({ key: 'createdBy', width: 150, sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({ key: 'createdDate', filter: false, width: 150, sorter: true, title: <Trans>Létrehozva</Trans> });
    columns.push({ key: 'updatedBy', width: 150, sorter: true, title: <Trans>Módosította</Trans> });
    columns.push({ key: 'updatedDate', filter: false, width: 150, sorter: true, title: <Trans>Módosítva</Trans> });

    return {
        columns: columns,
        url: '/resource/regional-representatives',
    };
};
export default RegionalRepresentativesModel();
