import useSafeState from '../../utils/safe-state';
import React from 'react';
import { Breadcrumb, Button, Divider } from 'antd';

import Drawer from '../../components/drawer/drawer';
import { Trans } from '@lingui/macro';
import { EditTwoTone, FileExcelOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons/lib';
import DtoTable from '../../components/dto-table/dto-table';
import MessageForm from '../../forms/message-form/message-drawer';
import MessageModel from '../../models/dto/message-model';
import { useAxios } from '../../utils/hooks';
import { DownloadOutlined } from '@ant-design/icons';
import ButtonWithIcon from "../../components/buttons/button-with-icon";

const MessageScreen = () => {
    const [exportLoading, setExportLoading] = useSafeState(false);
    const [templateLoading, setTemplateLoading] = useSafeState(false);
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const text = <Trans>Message</Trans>;
    const drawer = Drawer({
        title: text,
        children: <MessageForm />,
        customClose: () => setTrigger(!trigger),
    });

    const [trigger, setTrigger] = useSafeState(false);

    const handleDownloadTemplateXls = () => {
        setTemplateLoading(true);
        if (fcrAxiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            fcrAxiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/messages/' + lang + '/template/xls/',
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'message.xls');
                })
                .finally(() => setTemplateLoading(false));
        }
    };
    const handleDownloadXls = () => {
        if (fcrAxiosInstance.current != null) {
            setExportLoading(true);
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            fcrAxiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/messages/' + lang + '/xls/',
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'messages-' + new Date().toISOString().split('.')[0] + '.xls');
                })
                .finally(() => setExportLoading(false));
        }
    };

    const action = (text: string, record: any) => {
        return (
            <>
                <EditTwoTone
                    twoToneColor={'#40a9ff'}
                    className={'ActionButtons'}
                    onClick={() =>
                        drawer.open('Edit', {
                            language: record.messageId.language,
                            messageCode: record.messageId.messageCode,
                            translated: record.translated,
                            version: record.version,
                        })
                    }
                />
            </>
        );
    };

    const actions = {
        width: 80,
        render: (text: string, record: any) => action(text, record),
        title: <Trans>Actions</Trans>,
        fixed: 'right',
        align: 'center',
    };

    const header = (
        <>
            <Button loading={templateLoading} onClick={() => handleDownloadTemplateXls()} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<DownloadOutlined />}
                    text={<Trans>Excel Sablon</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button onClick={() => drawer.open('Upload File')} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<UploadOutlined />}
                    text={<Trans>Csv Feltöltés</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
        </>
    );

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Fordítások kezelése</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            {drawer.component}
            <DtoTable
                model={MessageModel}
                action={actions}
                apiUrl={'messages'}
                tableHeader={header}
                allowExport={true}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default MessageScreen;
