import { Trans } from '@lingui/macro';
import { useKeycloak } from '@react-keycloak/web';
import { Browser } from 'leaflet';
import useSafeState from '../../utils/safe-state';
import { ArrowDownOutlined, ArrowUpOutlined, LikeOutlined } from '@ant-design/icons/lib';
import { AxiosResponse } from 'axios';
import React, { useCallback } from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { useAxios } from '../../utils/hooks';

const LandingScreen = () => {
    const { keycloak } = useKeycloak();
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const [outsourcedDevices, setOutsourcedDevices] = useSafeState<number>(0);
    const [transportableDevices, setTransportableDevices] = useSafeState<number>(0);
    const [needToBeDiscardedDevices, setNeedToBeDiscardedDevices] = useSafeState<number>(0);
    const [allDevices, setAllDevices] = useSafeState<number>(0);
    const [requiredWorkRequests, setRequiredWorkRequests] = useSafeState<any>([]);

    React.useEffect(() => {
        getWorkRequest('REQUIRED');
        getDeviceRegister('');
        getDeviceRegister('NEED_TO_BE_DISCARDED');
        getDeviceRegister('OUTSOURCED');
        getDeviceRegister('TRANSPORTABLE');
    }, []);

    const getDeviceRegister = (status?: string) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .get('/resource/device-register/count-by-device-status?deviceStatus=' + status)
                .then(function (response: AxiosResponse<any>) {
                    if (status == 'OUTSOURCED') {
                        setOutsourcedDevices(response.data);
                    } else if (status == 'TRANSPORTABLE') {
                        setTransportableDevices(response.data);
                    } else if (status == 'NEED_TO_BE_DISCARDED') {
                        setNeedToBeDiscardedDevices(response.data);
                    }
                    if (status == '') {
                        setAllDevices(response.data);
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const getWorkRequest = (status?: string) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .post('/resource/work-requests/page', {
                    page: 0,
                    size: 10,
                    direction: 'asc',
                    sorted_field: 'requestStatus',
                    query: {
                        requestStatus: status === '' ? null : status,
                    },
                })
                .then(function (response: AxiosResponse<any>) {
                    if (status === 'REQUIRED') {
                        setRequiredWorkRequests(response.data.content);
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const setTransportablePerAll = () => {
        return allDevices > 0 ? (transportableDevices / allDevices) * 100 : 0;
    };

    const setOutsourcedPerAll = () => {
        return allDevices > 0 ? (outsourcedDevices / allDevices) * 100 : 0;
    };

    const setNeedToBeDiscardedPerAll = () => {
        return allDevices > 0 ? (needToBeDiscardedDevices / allDevices) * 100 : 0;
    };

    const redirectToWorkRequest = (requestType: string) => {
        window.location.replace(
            window.location.toString().replace('/home', '') +
                'work-requests?' +
                (requestType ? 'requestType=' + requestType : ''),
        );
    };

    const isAutherized = (roles: any[]) => {
        if (keycloak != null && keycloak.authenticated && keycloak && roles) {
            return roles.some(r => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    };

    return isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']) ? (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <h2>
                        <Trans>Eszközök</Trans>
                    </h2>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Card style={{ backgroundColor: '#d9f7be' }}>
                        <Statistic
                            title={<Trans>Szállítható / Összes</Trans>}
                            value={transportableDevices}
                            suffix={'/ ' + allDevices}
                        />
                        <Statistic
                            title={<Trans>Százalék</Trans>}
                            value={setTransportablePerAll()}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{ backgroundColor: '#fff1b8' }}>
                        <Statistic
                            title={<Trans>Kihelyezett / Összes</Trans>}
                            value={outsourcedDevices}
                            suffix={'/ ' + allDevices}
                        />
                        <Statistic
                            title={<Trans>Százalék</Trans>}
                            value={setOutsourcedPerAll()}
                            precision={2}
                            valueStyle={{ color: '#d48806' }}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{ backgroundColor: '#ffd8bf' }}>
                        <Statistic
                            title={<Trans>Selejtezendő / Összes</Trans>}
                            value={needToBeDiscardedDevices}
                            suffix={'/ ' + allDevices}
                        />
                        <Statistic
                            title={<Trans>Százalék</Trans>}
                            value={setNeedToBeDiscardedPerAll()}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={24}>
                <Col span={24}>
                    <h2>
                        <Trans>Műveletek</Trans>
                    </h2>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8} style={{ cursor: 'pointer' }}>
                    <Card onClick={() => redirectToWorkRequest('')}>
                        <Col span={12}>
                            <Statistic
                                title={<Trans>Jóváhagyásra vár</Trans>}
                                value={requiredWorkRequests.length}
                                prefix={<LikeOutlined />}
                            />
                        </Col>
                    </Card>
                </Col>
            </Row>
        </>
    ) : (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <h2>
                        <Trans>Műveletek</Trans>
                    </h2>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8} style={{ cursor: 'pointer' }}>
                    <Card style={{ backgroundColor: '#d9f7be' }} onClick={() => redirectToWorkRequest('DELIVERY')}>
                        <b>
                            <Trans>KISZÁLLÍTÁS INDÍTÁSA</Trans>
                        </b>
                    </Card>
                </Col>
                <Col span={8} style={{ cursor: 'pointer' }}>
                    <Card style={{ backgroundColor: '#fff1b8' }} onClick={() => redirectToWorkRequest('DISMANTLING')}>
                        <b>
                            <Trans>LESZERELÉS INDÍTÁSA</Trans>
                        </b>
                    </Card>
                </Col>
                <Col span={8} style={{ cursor: 'pointer' }}>
                    <Card style={{ backgroundColor: '#ffd8bf' }} onClick={() => redirectToWorkRequest('TRANSIT')}>
                        <b>
                            <Trans>ÁTSZÁLLÍTÁS INDÍTÁSA</Trans>
                        </b>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={24}>
                <Col span={8} style={{ cursor: 'pointer' }}>
                    <Card style={{ backgroundColor: '#e6f7ff' }} onClick={() => redirectToWorkRequest('REPLACEMENT')}>
                        <b>
                            <Trans>CSERE INDÍTÁSA</Trans>
                        </b>
                    </Card>
                </Col>
                <Col span={8} style={{ cursor: 'pointer' }}>
                    <Card style={{ backgroundColor: '#f9f0ff' }} onClick={() => redirectToWorkRequest('SERVICE')}>
                        <b>
                            <Trans>SZERVÍZ INDÍTÁSA</Trans>
                        </b>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
export default LandingScreen;
