import { useKeycloak } from '@react-keycloak/web';
import useSafeState from '../../utils/safe-state';
import React, { useEffect } from 'react';
import { Breadcrumb, Button, message, Popconfirm, Divider, Popover, Row, Col } from 'antd';
import Drawer from '../../components/drawer/drawer';
import { Trans } from '@lingui/macro';
import ShopsForm from '../../forms/shops-drawer/shops-drawer';
import { DeleteOutlined, DownloadOutlined, EditOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons/lib';
import DtoTable from '../../components/dto-table/dto-table';
import ShopModel from '../../models/dto/shop-model';
import { UploadOutlined } from '@ant-design/icons';
import { useAxios } from '../../utils/hooks';

import ButtonWithIcon from "../../components/buttons/button-with-icon";
import {openNotification} from "../../utils/openNotification";

const ShopsScreen = () => {
    const { keycloak } = useKeycloak();
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const isAutherized = (roles: any[]) => {
        if (keycloak != null && keycloak.authenticated && keycloak && roles) {
            return roles.some(r => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    };

    const text = <Trans>Bolt</Trans>;
    const drawer = Drawer({
        title: text,
        children: <ShopsForm />,
        customClose: () => setTrigger(!trigger),
    });

    const [trigger, setTrigger] = useSafeState(false);
    const [templateLoading, setTemplateLoading] = useSafeState(false);

    const handleDelete = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .delete('/resource/shops/id?id=' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott sor sikeresen törölve lett.')
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                    setTrigger(false);
                    setTrigger(!trigger);
                });
        }
    };

    const action = (text: string, record: any) => (
        <>
            {isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']) ? (
                <Popover
                    content={
                        <>
                            <EditOutlined
                                className={'ActionButtons'}
                                style={{ color: '#40a9ff' }}
                                onClick={() => {
                                    drawer.open('Edit', {
                                        code: record.code,
                                        name: record.name,
                                        postalCode: record.address.postalCode,
                                        city: record.address.city,
                                        streetHouseNumber: record.address.streetHouseNumber,
                                        groupCode: record.groupCode,
                                        chainName: record.chainName,
                                        regionalRepresentative: record.regionalRepresentative
                                            ? record.regionalRepresentative.id + '||' + record.regionalRepresentative.name + '||' + record.regionalRepresentative.sfaCode
                                            : null,
                                        transportDays: record.transportDays,
                                        location: record.location,
                                        transportPriority: record.transportPriority,
                                        shopStatus: record.shopStatus,
                                        remark: record.remark,
                                        needContactProtectionCertificate: record.needContactProtectionCertificate
                                            ? record.needContactProtectionCertificate
                                            : false,
                                        version: record.version,
                                        contactName: record.contactName,
                                        contactPhone: record.contactPhone,
                                        contactEmail: record.contactEmail,
                                    });
                                }}
                            />

                            <Popconfirm
                                title={<Trans>Are you sure to delete this shop?</Trans>}
                                onConfirm={() => handleDelete(record.code)}
                                okText={<Trans>Yes</Trans>}
                                cancelText={<Trans>No</Trans>}
                            >
                                <DeleteOutlined className={'ActionButtons'} style={{ color: '#ff4d4f' }} />
                            </Popconfirm>
                        </>
                    }
                    trigger="hover"
                >
                    <EllipsisOutlined style={{ fontSize: 'x-large' }} />
                </Popover>
            ) : (
                <></>
            )}
        </>
    );

    const handleDownloadTemplateXls = () => {
        setTemplateLoading(true);
        if (fcrAxiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            fcrAxiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/shops/' + lang + '/template/xls/',
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'shop-template.xls');
                    setTemplateLoading(false);
                });
        }
    };

    const actions = {
        width: 50,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center',
    };

    const header = isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']) ? (
        <>
            <Button type={'primary'} onClick={() => drawer.open('Add new')} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<PlusOutlined />}
                    text={<Trans>Új hozzáadása</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button loading={templateLoading} onClick={() => handleDownloadTemplateXls()} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<DownloadOutlined />}
                    text={<Trans>Excel Sablon</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button onClick={() => drawer.open('Upload File')} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<UploadOutlined />}
                    text={<Trans>Csv Feltöltés</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
        </>
    ) : (
        <></>
    );

    return (
        <Row gutter={24}>
            <Col span={24}>
                <Breadcrumb style={{ marginBottom: 10 }}>
                    <Breadcrumb.Item>
                        <Trans>Kezdőoldal</Trans>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Trans>Törzsadatok</Trans>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Trans>Boltok</Trans>
                    </Breadcrumb.Item>
                </Breadcrumb>
                {drawer.component}
                <DtoTable
                    model={ShopModel}
                    apiUrl={'shops'}
                    tableHeader={header}
                    allowExport={isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER'])}
                    action={actions}
                    trigger={trigger}
                    pageSize={100}
                    scroll={{ x: 1300, y: '65vh' }}
                />
            </Col>
        </Row>
    );
};
export default ShopsScreen;
