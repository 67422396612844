import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';

const RepresentativeDeputiesModel = (): ColumnMain => {
    const bgColor = '#fafafa';
    const columns: Array<Column> = new Array();
    columns.push({
        key: 'name',
        width: 200,
        sorter: true,
        title: <Trans>Név</Trans>,
        fixed: 'left',
        direction: 'asc',
        render: text => {
            return { props: { style: { background: bgColor } }, children: <b>{text}</b> };
        },
    });
    columns.push({
        key: 'outOfOfficeEndDate',
        width: 200,
        sorter: true,
        title: <Trans>Out Of Office End Date</Trans>,
        fixed: 'left',
        dateFilter: true,
        render: text => {
            return { props: { style: { background: bgColor } }, children: <b>{text}</b> };
        },
    });
    columns.push({
        key: 'deputy1.name',
        dataIndex: ['deputy1', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 1</Trans>,
    });
    columns.push({
        key: 'deputy2.name',
        dataIndex: ['deputy2', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 2</Trans>,
    });
    columns.push({
        key: 'deputy3.name',
        dataIndex: ['deputy3', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 3</Trans>,
    });
    columns.push({
        key: 'deputy4.name',
        dataIndex: ['deputy4', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 4</Trans>,
    });
    columns.push({
        key: 'deputy5.name',
        dataIndex: ['deputy5', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 5</Trans>,
    });
    columns.push({
        key: 'deputy6.name',
        dataIndex: ['deputy6', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 6</Trans>,
    });
    columns.push({
        key: 'deputy7.name',
        dataIndex: ['deputy7', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 7</Trans>,
    });
    columns.push({
        key: 'deputy8.name',
        dataIndex: ['deputy8', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 8</Trans>,
    });
    columns.push({
        key: 'deputy9.name',
        dataIndex: ['deputy9', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 9</Trans>,
    });
    columns.push({
        key: 'deputy10.name',
        dataIndex: ['deputy10', 'name'],
        filter: false,
        width: 120,
        sorter: true,
        title: <Trans>Deputy 10</Trans>,
    });

    columns.push({ key: 'createdBy', width: 150, sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({ key: 'createdDate', filter: false, width: 150, sorter: true, title: <Trans>Létrehozva</Trans> });
    columns.push({ key: 'updatedBy', width: 150, sorter: true, title: <Trans>Módosította</Trans> });
    columns.push({ key: 'updatedDate', filter: false, width: 150, sorter: true, title: <Trans>Módosítva</Trans> });

    return {
        columns: columns,
        url: '/resource/regional-representatives',
    };
};
export default RepresentativeDeputiesModel();
