import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import { Tag } from 'antd';

const ShopModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();
    columns.push({
        key: 'code',
        width: 110,
        sorter: true,
        title: <Trans>Boltkód</Trans>,
        fixed: 'left',
        direction: 'asc',
        render: text => {
            return <b>{text}</b>;
        },
    });
    columns.push({ key: 'name', width: 250, sorter: true, title: <Trans>Name</Trans> });
    columns.push({
        key: 'address.county',
        width: 108,
        dataIndex: ['address', 'county'],
        sorter: false,
        title: <Trans>County</Trans>,
        filter: false,
        render: text => {
            return <div>{text}</div>;
        },
    });
    columns.push({
        key: 'address.postalCode',
        width: 75,
        dataIndex: ['address', 'postalCode'],
        sorter: true,
        title: <Trans>Postal Code</Trans>,
    });
    columns.push({
        key: 'address.city',
        width: 140,
        dataIndex: ['address', 'city'],
        sorter: true,
        title: <Trans>City</Trans>,
    });
    columns.push({
        key: 'address.streetHouseNumber',
        width: 100,
        dataIndex: ['address', 'streetHouseNumber'],
        sorter: true,
        title: <Trans>Address</Trans>,
    });
    columns.push({ key: 'chainName', width: 130, sorter: true, title: <Trans>Lánc név</Trans> });
    columns.push({
        key: 'regionalRepresentative.name',
        dataIndex: ['regionalRepresentative', 'name'],
        width: 130,
        sorter: true,
        title: <Trans>Regional Representative</Trans>,
        render: text => {
            return <div>{text}</div>;
        },
    });
    columns.push({
        key: 'shopStatus',
        width: 200,
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let color = 'gray';

            if (text != null && text == 'EXPORT') {
                return (
                    <Tag color={'green'} key={text}>
                        <Trans>EXPORT</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'VISITED') {
                return (
                    <Tag color={'red'} key={text}>
                        <Trans>VISITED</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'CEASED') {
                return (
                    <Tag color={'blue'} key={text}>
                        <Trans>CEASED</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'NOT_VISITED') {
                return (
                    <Tag color={'orange'} key={text}>
                        <Trans>NOT_VISITED</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'OWN') {
                return (
                    <Tag color={'geekblue'} key={text}>
                        <Trans>OWN</Trans>
                    </Tag>
                );
            }

            return (
                <Tag color={color} key={text}>
                    {text}
                </Tag>
            );
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>EXPORT</Trans>,
                value: 'EXPORT',
            },
            {
                label: <Trans>VISITED</Trans>,
                value: 'VISITED',
            },
            {
                label: <Trans>CEASED</Trans>,
                value: 'CEASED',
            },
            {
                label: <Trans>NOT_VISITED</Trans>,
                value: 'NOT_VISITED',
            },
            {
                label: <Trans>OWN</Trans>,
                value: 'OWN',
            },
        ],
    });

    columns.push({ key: 'createdBy', width: 140, sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({
        key: 'createdDate',
        filter: false,
        width: 140,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Létrehozva</Trans>,
    });
    columns.push({ key: 'updatedBy', width: 140, sorter: true, title: <Trans>Módosította</Trans> });
    columns.push({
        key: 'updatedDate',
        filter: false,
        width: 140,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Módosítva</Trans>,
    });
    columns.push({ key: 'remark', width: 200, sorter: true, title: <Trans>Megjegyzés</Trans> });
    columns.push({
        key: 'needContactProtectionCertificate',
        width: 150,
        uniqueSelectFilterData: [
            {
                label: <Trans>Mind</Trans>,
                value: null,
            },
            {
                label: <Trans>Igen</Trans>,
                value: 'true',
            },
            {
                label: <Trans>Nem</Trans>,
                value: 'false',
            },
        ],
        sorter: true,
        title: <Trans>Need Contact Protection Certificate</Trans>,
    });

    const contactColumns: Column[] = [];

    contactColumns.push({ key: 'contactName', width: 160, sorter: true, title: <Trans>Name</Trans> });
    contactColumns.push({ key: 'contactPhone', width: 120, sorter: true, title: <Trans>Phone</Trans> });
    contactColumns.push({ key: 'contactEmail', width: 160, sorter: true, title: <Trans>Email</Trans> });

    columns.push({ key: 'contact', filter: false, sorter: false, title: <Trans>Contact</Trans>, children: contactColumns });

    return {
        columns: columns,
        url: '/resource/shops',
    };
};
export default ShopModel();
