import React from 'react';
import { Form, Row, Col } from 'antd';
import ReactJson from 'react-json-view';
import { AuroraConnectionModel } from '../../models/aurora-connection/aurora-connection';

interface Interface {
    data?: AuroraConnectionModel[];
    mode?: 'Edit' | 'Add new';
    onClose?: () => void;
}

const AuroraConnectionForm = (props: Interface) => {
    const data = props.data || {};
    const [form] = Form.useForm();

    let fieldsValues = [];
    for (const [key, value] of Object.entries(data)) {
        fieldsValues.push({ name: key, value: value });
    }
    form.setFields(fieldsValues);

    let url = form.getFieldValue(['requestUrl']);

    return (
        <Form id={props.mode} layout="vertical">
            <Row gutter={16}>
                <Col span={24}>
                    <div
                        style={{
                            fontFamily: 'monospace',
                            backgroundColor: 'rgb(50,41,49)',
                            position: 'relative',
                            padding: 10,
                            color: 'white',
                        }}
                    >
                        Request URL: <span style={{ color: 'rgb(253, 139, 25)' }}>{url}</span>
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    {form.getFieldValue(['requestBody']) != null && form.getFieldValue(['requestBody']) !== '' ? (
                        <ReactJson
                            src={JSON.parse(form.getFieldValue(['requestBody']))}
                            theme={'hopscotch'}
                            style={{ padding: 10 }}
                        />
                    ) : (
                        <></>
                    )}
                </Col>
            </Row>
        </Form>
    );
};
export default AuroraConnectionForm;
