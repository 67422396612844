import {ConfigProvider} from 'antd';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { ReactKeycloakProvider } from '@react-keycloak/web';

import keycloak from './keycloak';
import { AppRouter } from './routes';
import * as serviceWorker from './serviceWorker';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import hu from './locales/hu/messages.json';
import en from './locales/en/messages.json';

import 'moment/locale/hu';

import huLocale from 'antd/lib/locale/hu_HU';
import enLocale from 'antd/lib/locale/en_US';

import './routes/index.css';

const eventLogger = (event: unknown, error: unknown) => {
    console.log('onKeycloakEvent', event, error);
};

const tokenLogger = (tokens: unknown) => {
    console.log('onKeycloakTokens', tokens);
};

i18n.load('hu', hu);
i18n.load('en', en);
i18n.activate(localStorage.getItem('language') ? localStorage.getItem('language') + '' : 'hu');

ReactDOM.render(
    <ConfigProvider locale={'en' == localStorage.getItem('language') ? enLocale : huLocale}>
        <I18nProvider i18n={i18n}>
            <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger}>
                <AppRouter />
            </ReactKeycloakProvider>
        </I18nProvider>
    </ConfigProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
