import useSafeState from '../../utils/safe-state';
import { Form, Input, Modal } from 'antd';
import React, { ReactElement } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../utils/hooks';
import { antdContext } from '../../utils/antdContext';
import {openNotification} from "../../utils/openNotification";

const { confirm } = Modal;

interface Interface {
    title?: ReactElement;
    children: ReactElement;
    onCustomClose?: () => void;
    customClose?: () => void;
    name?: string;
}

const PasswordReset = (props: Interface) => {
    const axiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + '');

    const [visible, setVisible] = useSafeState(false);
    const [data, setData] = useSafeState([]);
    const [mode, setMode] = useSafeState('Edit');
    const [form] = Form.useForm();
    form.resetFields();

    const onClose = () => {
        if (props.customClose) {
            props.customClose();
        }
        setVisible(false);
    };

    const open = (mode?: 'Add new' | 'Edit' | '', recordData?: any) => {
        setMode(mode ? mode : '');
        setData(recordData);
        setVisible(true);
    };

    const resetCall = (id: any) => {
        if (!axiosInstance.current) {
            return;
        }
        axiosInstance.current
            .put('/auth/admin/realms/friesland-cooler-register/users/' + id + '/reset-password', {
                type: 'password',
                temporary: true,
                value: form.getFieldValue(['password']),
            })
            .then(() => {
                openNotification('success', 'Sikeres mentés!', props);
            })
            .catch(function (error: any) {
                openNotification('error', error);
            });
    };

    const showPromiseConfirm = (recordData?: any) => {
        setData(recordData);
        confirm({
            title: props.title,
            icon: <ExclamationCircleOutlined />,
            content: (
                <Form form={form}>
                    <Form.Item
                        name="password"
                        label={antdContext('Jelszó')}
                        children={<Input type="password" />}
                        rules={[
                            {
                                type: 'string',
                                required: true,
                                message: props.title,
                            },
                        ]}
                    />
                </Form>
            ),
            visible: visible,
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    resetCall(recordData.id);
                }).catch(() => console.log('Oops error!'));
            },
            onCancel() {},
        });
    };

    const createContent = (): React.ReactNode => {
        return React.cloneElement(props.children, {
            mode: mode,
            data: data,
            onClose: () => {
                onClose();
            },
        });
    };

    return { open, showPromiseConfirm };
};
export default PasswordReset;
