import useSafeState from '../../utils/safe-state';
import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, Select, message, Switch, Tooltip, Divider } from 'antd';
import { Trans } from '@lingui/macro';
import { AxiosResponse } from 'axios';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined, InfoCircleTwoTone } from '@ant-design/icons/lib';
import { DeviceTypeModel } from '../../models/device-types/device-type';
import { useAxios } from '../../utils/hooks';
import { openNotification } from '../../utils/openNotification';

interface Interface {
    data?: DeviceTypeModel[];
    mode?: 'Edit' | 'Add new' | 'Upload File';
    onClose?: () => void;
}

const DeviceTypeForm = (props: Interface) => {
    const [loading, setLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const data = props.data || {};
    const [form] = Form.useForm();
    const [deviceTypeCategory, setDeviceTypeCategory] = useSafeState<any>([
        { name: 'COOLER' },
        { name: 'OTHER' },
        { name: 'DECOR' },
    ]);

    const [deviceMethodOfRegistration, setDeviceMethodOfRegistration] = useSafeState<any>([
        { name: 'BASED_ON_SERIAL_NUMBER' },
        { name: 'BASED_ON_QUANTITY' },
        { name: 'NO_REGISTRATION_REQUIRED' },
    ]);

    const [fileList, setFileList] = useSafeState<Array<any>>([]);
    const [uploading, setUploading] = useSafeState(false);
    const [groupable, setGroupable] = useSafeState(false);
    const [enableGroupable, setEnableGroupable] = useSafeState(false);
    const [deviceTypes, setDeviceTypes] = useSafeState<any>([]);

    const [saveDeviceTypeIsSuccess, setSaveDeviceTypeIsSuccess] = useSafeState(false);

    const [selectedItems, setSelectedItems] = useSafeState<Array<string>>([]);

    let isEditMode = false;

    let options: string[] = [];

    type optionalType = {
        label: string;
        value: string;
    };

    const handleInputChange = (selected: any) => {
        setSelectedItems(selected);
    };

    const updateDeviceTypes = () => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .put(
                    (
                        '/resource/device-types/update-all-children?parentName=' +
                        encodeURIComponent(form.getFieldValue(['name'])) +
                        ''
                    ),
                    selectedItems,
                    config,
                )
                .then(() => openNotification('success', '', props))
                .catch(() => openNotification('error', '', props));
        }
    };

    useEffect(() => {
        if (saveDeviceTypeIsSuccess) {
            updateDeviceTypes();
        }
    }, [saveDeviceTypeIsSuccess]);

    const generateOptionsByLabelAndValue = (data: any, label: string, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { value: data[i][val], label: getLabel(data[i][label]) };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const getLabel = (label: any) => {
        switch (label) {
            case 'COOLER':
                return <Trans>COOLER</Trans>;
            case 'DECOR':
                return <Trans>DECOR</Trans>;
            case 'OTHER':
                return <Trans>OTHER</Trans>;
            case 'BASED_ON_SERIAL_NUMBER':
                return <Trans>BASED_ON_SERIAL_NUMBER</Trans>
            case 'BASED_ON_QUANTITY':
                return <Trans>BASED_ON_QUANTITY</Trans>
            case 'NO_REGISTRATION_REQUIRED':
                return <Trans>NO_REGISTRATION_REQUIRED</Trans>
            default:
                return label;
        }
    };

    useEffect(() => {
        form.resetFields();

        if (props.mode === 'Edit') {
            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({ name: key, value: value });
                if (key == 'name') {
                    if (axiosInstance.current != null) {
                        axiosInstance.current
                            .get(
                                ('/resource/device-types/get-children-by-parent-name?parentName=' + value).replace(
                                    '+',
                                    '%2B',
                                ),
                            )
                            .then(res => {
                                let tmpArray: string[] = [];
                                res.data.forEach((x: any) => {
                                    tmpArray.push(x.name);
                                });

                                setSelectedItems(tmpArray);
                            })
                            .catch(e => console.log(e));
                    }
                }
                if (key == 'groupable') {
                    setGroupable(value == true);
                }
                if (key == 'deviceTypeCategory') {
                    setEnableGroupable(value == 'COOLER');
                }
            }
            form.setFields(fieldsValues);

            isEditMode = true;
        } else if (props.mode === 'Add new') {
            isEditMode = false;
        }
        getDeviceTypes();
    }, []);

    const onReset = () => {
        form.resetFields();
    };

    const getDeviceTypes = () => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .get('/resource/device-types/get-all-not-cooler')
                .then(function (response: AxiosResponse<any>) {
                    setDeviceTypes(response.data);
                    response.data.forEach((x: any) => {
                        options.push(x.name);
                    });
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const config = { headers: { 'Content-Type': 'application/json' } };

    const handleEdit = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current
                .put(
                    ('/resource/device-types/id?id='
                        //+ form.getFieldValue(['name'])).replace('+', '%2B'),
                        + encodeURIComponent(form.getFieldValue(['name']))),
                    {
                        name: form.getFieldValue(['name']),
                        prodNum: form.getFieldValue(['prodNum']),
                        deviceTypeCategory: form.getFieldValue(['deviceTypeCategory']),
                        methodOfRegistration: form.getFieldValue(['methodOfRegistration']),
                        description: form.getFieldValue(['description']),
                        groupable: groupable,
                        version: form.getFieldValue(['version']),
                    },
                    config,
                )
                .then(() => {
                    setSaveDeviceTypeIsSuccess(true);

                    if (fileList.length > 0 && axiosInstance.current != null) {
                        const formData = new FormData();
                        fileList.forEach(file => {
                            formData.append('pic', file);
                            formData.append('deviceTypeId', form.getFieldValue(['name']));
                        });

                        axiosInstance.current
                            .post('/resource/device-types/upload-picture', formData)
                            .then(function (response: AxiosResponse<any>) {
                                setUploading(false);
                                setLoading(false);
                                setFileList([]);
                                openNotification('success','', props);
                            })
                            .catch(function (error: any) {
                                openNotification('error', error, props);
                                setUploading(false);
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                    }
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                    setSaveDeviceTypeIsSuccess(false);
                });
        }
    };

    const handleAddNew = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current
                .post(
                    '/resource/device-types',
                    {
                        name: form.getFieldValue(['name']),
                        prodNum: form.getFieldValue(['prodNum']),
                        deviceTypeCategory: form.getFieldValue(['deviceTypeCategory']),
                        methodOfRegistration: form.getFieldValue(['methodOfRegistration']),
                        description: form.getFieldValue(['description']),
                        groupable: groupable,
                    },
                    config,
                )
                .then(function (response: AxiosResponse<any>) {
                    setSaveDeviceTypeIsSuccess(true);
                    setUploading(true);

                    if (fileList.length > 0 && axiosInstance.current != null) {
                        const formData = new FormData();
                        fileList.forEach(file => {
                            formData.append('pic', file);
                            formData.append('deviceTypeId', response.data.name);
                        });

                        axiosInstance.current
                            .post('/resource/device-types/upload-picture', formData)
                            .then(function (response: AxiosResponse<any>) {
                                setUploading(false);
                                setFileList([]);
                                openNotification('success', '', props);
                                setLoading(false);
                            })
                            .catch(function (error: any) {
                                openNotification('error', error, props);
                                setUploading(false);
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                    }
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                    setSaveDeviceTypeIsSuccess(false);
                });
        }
    };

    const handleUploadCsv = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });

            axiosInstance.current
                .post('/resource/device-types/upload-csv', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const uploadProps = {
        name: 'pic',
        action: process.env.REACT_APP_API_BASE_URL + '/resource/device-types/upload-csv',
        multiple: false,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading', info.file, info.fileList);
            }
            if (status === 'done') {
                openNotification('customSuccess', `${info.file.name} "file uploaded successfully."`);
            } else if (status === 'error') {
                openNotification('customError', `${info.file.name} "file upload failed."`);
            }
        },
        beforeUpload(file: any) {
            let fileList2: Array<any> = fileList;
            fileList2.push(file);
            setFileList(fileList2);
            return false;
        },
    };

    const generateOptions = (data: any, label: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { value: data[i][label], label: data[i][label] };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const onChange = () => {
        setGroupable(!groupable);
    };

    const changeTypeCategory = (value: any) => {
        setEnableGroupable(value == 'COOLER');
        if (value != 'COOLER') {
            setGroupable(false);
        }
    };

    return (
        <Form
            id={props.mode}
            form={form}
            layout="vertical"
            onFinish={() => (props.mode === 'Edit' ? handleEdit() : handleAddNew())}
        >
            {props.mode === 'Edit' || props.mode === 'Add new' ? (
                <>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label={<Trans>Name</Trans>}
                                children={
                                    props.mode === 'Edit' ? (
                                        <Input disabled={true} type={'text'} />
                                    ) : (
                                        <Input type={'text'} />
                                    )
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please type a name</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="prodNum"
                                label={<Trans>ProdNum</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please type a name</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="description" label={<Trans>Description</Trans>} children={<Input />} />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="deviceTypeCategory"
                                label={<Trans>Device Type Category</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a Type</Trans>}
                                        options={generateOptionsByLabelAndValue(deviceTypeCategory, 'name', 'name')}
                                        onChange={value => changeTypeCategory(value)}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please select a type</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="methodOfRegistration"
                                label={<Trans>Method of Registration</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a Method</Trans>}
                                        options={generateOptionsByLabelAndValue(deviceMethodOfRegistration, 'name', 'name')}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please select a method</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                        </Col>
                    </Row>
                    <Row gutter={24} hidden={!enableGroupable}>
                        <Col span={12}>
                            <Form.Item
                                name="groupable"
                                label={<Trans>Groupable</Trans>}
                                children={<Switch onChange={onChange} checked={groupable} />}
                            />
                        </Col>
                    </Row>
                    {groupable ? (
                        <Divider orientation="left" style={{ marginTop: 0, fontSize: 'larger' }}>
                            Eszköz csoport
                        </Divider>
                    ) : (
                        <></>
                    )}
                    <Row gutter={24} hidden={!groupable}>
                        <Col span={24}>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                value={selectedItems}
                                placeholder={<Trans>Select a device type</Trans>}
                                onChange={handleInputChange}
                                options={generateOptions(deviceTypes, 'name')}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Form.Item
                        name="fenykep"
                        label={<Trans>Fénykép</Trans>}
                        children={
                            <Dragger {...uploadProps}>
                                <div></div>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    <Trans>Click or drag file to this area to upload</Trans>
                                </p>
                                <p className="ant-upload-hint">
                                    <Trans>
                                        Support for a single upload. Strictly prohibit from uploading company data or
                                        other band files
                                    </Trans>
                                </p>
                            </Dragger>
                        }
                    />
                    <br />
                </>
            ) : (
                <>
                    <Form.Item
                        name="fenykep"
                        children={
                            <Dragger {...uploadProps}>
                                <div></div>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    <Trans>Click or drag file to this area to upload</Trans>
                                </p>
                                <p className="ant-upload-hint">
                                    <Trans>
                                        Support for a single upload. Strictly prohibit from uploading company data or
                                        other band files
                                    </Trans>
                                </p>
                            </Dragger>
                        }
                    />
                    <br />
                </>
            )}
            {props.mode === 'Edit' ? (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
            ) : props.mode === 'Add new' ? (
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() => onReset()}>
                        <Trans>Vissza</Trans>
                    </Button>
                    <Button
                        loading={loading}
                        htmlType="button"
                        type="primary"
                        style={{ marginRight: 8 }}
                        onClick={() => form.submit()}
                    >
                        <Trans>Mentés</Trans>
                    </Button>
                </>
            ) : (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => handleUploadCsv()}>
                    <Trans>Feltöltés</Trans>
                </Button>
            )}
        </Form>
    );
};

export default DeviceTypeForm;
