import { useKeycloak } from '@react-keycloak/web';
import useSafeState from '../../utils/safe-state';
import React from 'react';
import { Breadcrumb, Button, Divider } from 'antd';
import { Trans } from '@lingui/macro';
import { FileExcelOutlined } from '@ant-design/icons';
import DtoTable from '../../components/dto-table/dto-table';
import MovementHistoryModel from '../../models/dto/movement-history-model';
import { useAxios } from '../../utils/hooks';

const MovementHistoryScreen = () => {
    const { keycloak } = useKeycloak();

    const [allInReportLoading, setAllInReportLoading] = useSafeState(false);

    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const isAutherized = (roles: any[]) => {
        if (keycloak != null && keycloak.authenticated && keycloak && roles) {
            return roles.some(r => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    };

    const handleDownloadAllInViewXls = () => {
        if (fcrAxiosInstance.current != null) {
            setAllInReportLoading(true);
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            fcrAxiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/work-request-history-view/' + lang + '/xls/',
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(
                        response.data,
                        'all-in-report-view-' + new Date().toISOString().split('.')[0] + '.xls',
                    );
                })
                .finally(() => {
                    setAllInReportLoading(false);
                });
        }
    };


    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Mozgástörténet</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable
                model={MovementHistoryModel}
                allowExport={isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER'])}
                apiUrl={'work-request-history'}
                pageSize={100}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default MovementHistoryScreen;
