import useSafeState from '../../utils/safe-state';
import React from 'react';
import { Breadcrumb, Button, Popconfirm, Divider, Popover } from 'antd';
import Drawer from '../../components/drawer/drawer';
import { Trans } from '@lingui/macro';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons/lib';
import DeviceRegisterForm from '../../forms/device-register-drawer/device-register-drawer';
import DeviceRegisterModel from '../../models/dto/device-register-model';
import DtoTable from '../../components/dto-table/dto-table';
import moment from 'moment';
import ModalWithButton from '../../components/modal-with-button/modal-with-button';
import ImageViewer from '../../components/image-viewer/image-viewer';
import { UploadOutlined } from '@ant-design/icons/lib';
import { useAxios } from '../../utils/hooks';
import { DownloadOutlined } from '@ant-design/icons';

import ButtonWithIcon from "../../components/buttons/button-with-icon";
import {openNotification} from "../../utils/openNotification";

const DeviceRegisterScreen = () => {
    const [templateLoading, setTemplateLoading] = useSafeState(false);
    const [auroraSyncLoading, setAuroraSyncLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const text = <Trans>Device Register</Trans>;
    const drawer = Drawer({
        title: text,
        children: <DeviceRegisterForm />,
        customClose: () => setTrigger(!trigger),
    });

    const [trigger, setTrigger] = useSafeState(false);

    const handleDownloadTemplateXls = () => {
        setTemplateLoading(true);
        if (axiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            axiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/device-register/' + lang + '/template/xls/',
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'device-register.xls');
                    setTemplateLoading(false);
                });
        }
    };
    const handleDelete = (id: any) => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .delete('/resource/device-register/id?id=' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott sor sikeresen törölve lett.')
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                    setTrigger(false);
                    setTrigger(!trigger);
                });
        }
    };

    const handleAuroraSync = () => {
        if (axiosInstance.current != null) {
            setAuroraSyncLoading(true);
            axiosInstance.current
                .put('/resource/device-register/sync')
                .then(() => {
                    setAuroraSyncLoading(false);
                    openNotification('customSuccess', 'Sikeres szinkronizálás.');
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                    setAuroraSyncLoading(false);
                    setTrigger(false);
                    setTrigger(!trigger);
                });
        }
    };

    const action = (text: string, record: any) => (
        <Popover
            content={
                <div style={{ clear: 'both', whiteSpace: 'nowrap' }}>
                    <ModalWithButton
                        children={<ImageViewer width={550} folder={record.folder} fileName={record.fileName} />}
                        buttonName={<Trans>View</Trans>}
                        title={<Trans>Picture</Trans>}
                        iconStyle={{ color: '#73d13d' }}
                    />
                    <EditOutlined
                        style={{ color: '#40a9ff' }}
                        className={'ActionButtons'}
                        onClick={() =>
                            drawer.open('Edit', {
                                id: record.id,
                                inventoryNumber: record.inventoryNumber,
                                serialNumber: record.serialNumber,
                                deviceNature: record.deviceNature,
                                deviceStatus: record.deviceStatus,
                                purchasingDate: record.purchasingDate != null ? moment(record.purchasingDate) : null,
                                warrantyDate: record.warrantyDate != null ? moment(record.warrantyDate) : null,
                                maintanedAt: record.maintanedAt != null ? moment(record.maintanedAt) : null,
                                childDevice1: record.childDevice1,
                                childDevice2: record.childDevice2,
                                childDevice3: record.childDevice3,
                                childDevice4: record.childDevice4,
                                decor: record.decor ? record.decor.name : null,
                                deviceType: record.deviceType ? record.deviceType.name : null,
                                methodOfRegistration: record.deviceType ? record.deviceType.methodOfRegistration : null,
                                deviceTypeChild1:
                                    record.deviceType && record.deviceType.childDeviceType1
                                        ? record.deviceType.childDeviceType1.name
                                        : null,
                                deviceTypeChild2:
                                    record.deviceType && record.deviceType.childDeviceType2
                                        ? record.deviceType.childDeviceType2.name
                                        : null,
                                deviceTypeChild3:
                                    record.deviceType && record.deviceType.childDeviceType3
                                        ? record.deviceType.childDeviceType3.name
                                        : null,
                                deviceTypeChild4:
                                    record.deviceType && record.deviceType.childDeviceType4
                                        ? record.deviceType.childDeviceType4.name
                                        : null,
                                groupable: record.deviceType ? record.deviceType.groupable : null,
                                needStockTaking: record.needStockTaking,
                                shop: record.shop ? record.shop.code : null,
                                serviceCompany: record.serviceCompany ? record.serviceCompany.code : null,
                                warehouseCompany: record.warehouseCompany ? record.warehouseCompany.code : null,
                                version: record.version,
                                quantity: record.quantity
                            })
                        }
                    />
                    <Popconfirm
                        title={<Trans>Are you sure to delete this device registration?</Trans>}
                        onConfirm={() => handleDelete(record.id)}
                        okText={<Trans>Yes</Trans>}
                        cancelText={<Trans>No</Trans>}
                    >
                        <DeleteOutlined style={{ color: '#ff4d4f' }} className={'ActionButtons'} />
                    </Popconfirm>
                </div>
            }
            trigger="hover"
        >
            <EllipsisOutlined style={{ fontSize: 'x-large' }} />
        </Popover>
    );

    const actions = {
        width: 50,
        fixed: 'right',
        align: 'center',
        render: (text: string, record: any) => action(text, record),
        title: '',
    };

    const header = (
        <>
            <Button type={'primary'} onClick={() => drawer.open('Add new')} style={{ float: 'left' }}>
                <ButtonWithIcon icon={<PlusOutlined />} text={<Trans>Új hozzáadása</Trans>} />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button loading={templateLoading} onClick={() => handleDownloadTemplateXls()} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<DownloadOutlined />}
                    text={<Trans>Excel Sablon</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button onClick={() => drawer.open('Upload File')} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<UploadOutlined />}
                    text={<Trans>Csv Feltöltés</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
        </>
    );

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Device Register</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            {drawer.component}
            <DtoTable
                model={DeviceRegisterModel}
                tableHeader={header}
                allowExport={true}
                apiUrl={'device-register'}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default DeviceRegisterScreen;
