import { Trans } from '@lingui/macro';
import React, { useEffect } from 'react';
import { Breadcrumb, Popconfirm, Popover, Space } from 'antd';
import DtoTable from '../../components/dto-table/dto-table';
import StockTakingLineModel from '../../models/dto/stock-taking-line-model';
import useSafeState from '../../utils/safe-state';
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DeleteTwoTone,
    EllipsisOutlined,
    EyeOutlined,
    FileImageOutlined,
    LeftOutlined,
} from '@ant-design/icons/lib';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useAxios } from '../../utils/hooks';
import { Image } from 'antd';

const StockTakingLineScreen = () => {
    const [trigger, setTrigger] = useSafeState(false);
    const [visible, setVisible] = useSafeState(false);
    const [photos, setPhotos] = useSafeState([]);
    const { keycloak } = useKeycloak();
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const isAutherized = (roles: any[]) => {
        if (keycloak != null && keycloak.authenticated && keycloak && roles) {
            return roles.some(r => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    };

    const action = (text: string, record: any) => {
        let isClosed = record.status === 'CLOSED';

        if (isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER'])) {
            return (
                <>
                    {record.photos.length > 0 ? (
                        <FileImageOutlined
                            onClick={() => {
                                setPhotos(record.photos);
                                setVisible(true);
                            }}
                            style={{ fontSize: 'large', color: 'green' }}
                        />
                    ) : (
                        <></>
                    )}
                </>
            );
        }
    };

    const actions = {
        width: 50,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center',
    };

    const createPhotosElement = () => {
        console.log(photos.length);
        return photos.length > 0 ? (
            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                    {photos.map((photo: any) => (
                        <Image
                            preview={{ visible: false }}
                            width={200}
                            src={
                                process.env.REACT_APP_API_BASE_URL +
                                '/public/file/get?folder=' +
                                photo.folder +
                                '&filename=' +
                                photo.fileName
                            }
                            onClick={() => setVisible(true)}
                            alt={'Nincs megjeleníthető kép'}
                        />
                    ))}
                </Image.PreviewGroup>
            </div>
        ) : (
            <></>
        );
    };

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Leltár</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            {createPhotosElement()}
            <DtoTable
                allowBackButton={true}
                backUrl={'/stock-taking'}
                query={{ headId: window.location.pathname.replace('/stock-taking-lines/', '') }}
                model={StockTakingLineModel}
                trigger={trigger}
                pageSize={100}
                action={actions}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default StockTakingLineScreen;
