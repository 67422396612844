import useSafeState from '../../utils/safe-state';
import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, message, Select } from 'antd';
import { Trans } from '@lingui/macro';
import { AxiosResponse } from 'axios';
import { RegionalRepresentativeModel } from '../../models/regional-representative/regional-representative';
import { useAxios } from '../../utils/hooks';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd/es';
import {openNotification} from '../../utils/openNotification';


interface Interface {
    data?: RegionalRepresentativeModel[];
    mode?: 'Edit' | 'Add new';
    onClose?: () => void;
}

const RegionalRepresentativeForm = (props: Interface) => {
    const data = props.data || {};
    const [users, setUsers] = useSafeState<any>([]);
    const [rank, setRank] = useSafeState<any>([
        { name: 'ADMINISTRATOR' },
        { name: 'FIELD_SALES_MANAGER' },
        { name: 'SALES_REPRESENTATIVE' },
        { name: 'KEY_ACCOUNT_MANAGER' },
        { name: 'SHOPPER_MARKETING_MANAGER' },
        { name: 'SHOPPER_MARKETING_SPECIALIST' },
        { name: 'REGIONAL_REPRESENTATIVE' },
        { name: 'REGIONAL_LEADER' },
    ]);
    const [region, setRegion] = useSafeState<any>([
        { name: 'CENTRAL' },
        { name: 'SOUTH' },
        { name: 'NORTH' },
        { name: 'EAST' },
        { name: 'WEST' },
        { name: 'OOH' },
        { name: 'RETAIL' },
        { name: 'ALL' },
    ]);
    const [loading, setLoading] = useSafeState(false);

    const [fileList, setFileList] = useSafeState<Array<any>>([]);
    let selectedUser = {
        username: '',
        email: '',
        name: '',
    };
    const [form] = Form.useForm();

    const uploadProps = {
        name: 'pic',
        action: process.env.REACT_APP_API_BASE_URL + '/resource/regional-representatives/upload-csv',
        multiple: false,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading', info.file, info.fileList);
            }
            if (status === 'done') {
                openNotification('customSuccess', `${info.file.name} "file uploaded successfully."`);
            } else if (status === 'error') {
                openNotification('customError', `${info.file.name} "file upload failed."`);
            }
        },
        beforeUpload(file: any) {
            let fileList2: Array<any> = fileList;
            fileList2.push(file);
            setFileList(fileList2);
            return false;
        },
    };
    const keycloakAxiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + '');
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    type optionalType = {
        label: string;
        value: string;
    };

    React.useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        if (keycloakAxiosInstance.current != null) {
            keycloakAxiosInstance.current
                .get('/auth/admin/realms/friesland-cooler-register/users')
                .then(function (response: AxiosResponse<any>) {
                    setUsers(response.data);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const generateOptionsByLabelAndValue = (data: any, label: string, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { value: data[i][val], label: getLabel(data[i][label]) };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const getLabel = (label: any) => {
        switch (label) {
            case 'ADMINISTRATOR':
                return <Trans>ADMINISTRATOR</Trans>;
            case 'FIELD_SALES_MANAGER':
                return <Trans>FIELD_SALES_MANAGER</Trans>;
            case 'SALES_REPRESENTATIVE':
                return <Trans>SALES_REPRESENTATIVE</Trans>;
            case 'KEY_ACCOUNT_MANAGER':
                return <Trans>KEY_ACCOUNT_MANAGER</Trans>;
            case 'SHOPPER_MARKETING_MANAGER':
                return <Trans>SHOPPER_MARKETING_MANAGER</Trans>;
            case 'SHOPPER_MARKETING_SPECIALIST':
                return <Trans>SHOPPER_MARKETING_SPECIALIST</Trans>;
            case 'REGIONAL_REPRESENTATIVE':
                return <Trans>REGIONAL_REPRESENTATIVE</Trans>;
            case 'REGIONAL_LEADER':
                return <Trans>REGIONAL_LEADER</Trans>;

            case 'CENTRAL':
                return <Trans>CENTRAL</Trans>;
            case 'SOUTH':
                return <Trans>SOUTH</Trans>;
            case 'NORTH':
                return <Trans>NORTH</Trans>;
            case 'EAST':
                return <Trans>EAST</Trans>;
            case 'WEST':
                return <Trans>WEST</Trans>;
            case 'OOH':
                return <Trans>OOH</Trans>;
            case 'RETAIL':
                return <Trans>RETAIL</Trans>;
            case 'ALL':
                return <Trans>ALL</Trans>;

            default:
                return label;
        }
    };

    const generateNameOptions = (data: any): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = {
                value:
                    '{"username": "' +
                    data[i]['username'] +
                    '", "email": "' +
                    data[i]['email'] +
                    '", "name": "' +
                    data[i]['lastName'] +
                    ' ' +
                    data[i]['firstName'] +
                    '"}',
                label: data[i]['lastName'] + ' ' + data[i]['firstName'],
            };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };
    useEffect(() => {
        form.resetFields();

        if (props.mode === 'Edit') {
            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({ name: key, value: value });
            }
            form.setFields(fieldsValues);
        }
    }, []);
    const onReset = () => {
        form.resetFields();
    };

    const handleOnChange = () => {
        selectedUser = JSON.parse(form.getFieldValue(['name']));

        let fieldsValues = [];
        fieldsValues.push({ name: 'username', value: selectedUser.username });
        fieldsValues.push({ name: 'email', value: selectedUser.email });
        form.setFields(fieldsValues);
    };

    const handleUploadCsv = () => {
        if (fcrAxiosInstance.current != null) {
            setLoading(true);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });
            fcrAxiosInstance.current
                .post('/resource/regional-representatives/upload-csv', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const handleEdit = () => {
        console.log(selectedUser.name);
        if (fcrAxiosInstance.current != null) {
            setLoading(true);
            fcrAxiosInstance.current
                .put('/resource/regional-representatives/id?id=' + form.getFieldValue(['id']), {
                    id: form.getFieldValue(['id']),
                    name: form.getFieldValue(['name']),
                    username: form.getFieldValue(['username']),
                    email: form.getFieldValue(['email']),
                    phone: form.getFieldValue(['phone']),
                    sfaCode: form.getFieldValue(['sfaCode']),
                    version: form.getFieldValue(['version']),
                    rank: form.getFieldValue(['rank']),
                    region: form.getFieldValue(['region']),
                })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const handleAddNew = () => {
        if (fcrAxiosInstance.current != null) {
            setLoading(true);
            fcrAxiosInstance.current
                .post('/resource/regional-representatives', {
                    name: selectedUser.name,
                    username: form.getFieldValue(['username']),
                    email: form.getFieldValue(['email']),
                    phone: form.getFieldValue(['phone']),
                    sfaCode: form.getFieldValue(['sfaCode']),
                    rank: form.getFieldValue(['rank']),
                    region: form.getFieldValue(['region']),
                })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    return (
        <Form
            id={props.mode}
            form={form}
            layout="vertical"
            onFinish={() => (props.mode === 'Edit' ? handleEdit() : handleAddNew())}
        >
            {props.mode === 'Edit' || props.mode === 'Add new' ? (
                <>
                    <Row gutter={24}>
                        <Col span={0}>
                            <Form.Item
                                name="id"
                                children={<InputNumber type={'hidden'} />}
                                rules={[
                                    {
                                        type: 'number',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please enter a name</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label={<Trans>Name</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                        onChange={() => handleOnChange()}
                                    />
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="username"
                                label={<Trans>Username</Trans>}
                                children={<Input disabled={true} />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please enter a username</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="rank"
                                label={<Trans>Rank</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a rank</Trans>}
                                        options={generateOptionsByLabelAndValue(rank, 'name', 'name')}
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <Trans>Please select a rank</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="region"
                                label={<Trans>Region</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a region</Trans>}
                                        options={generateOptionsByLabelAndValue(region, 'name', 'name')}
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <Trans>Please select a rank</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="email"
                                label={<Trans>Email</Trans>}
                                children={<Input disabled={true} />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please enter an email</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="sfaCode"
                                label={<Trans>Sfa Code</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please enter an sfa code</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="phone"
                                label={<Trans>Phone</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please enter a phone number</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Dragger {...uploadProps}>
                        <div></div>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            <Trans>Click or drag file to this area to upload</Trans>
                        </p>
                        <p className="ant-upload-hint">
                            <Trans>
                                Support for a single upload. Strictly prohibit from uploading company data or other band
                                files
                            </Trans>
                        </p>
                    </Dragger>
                    <br />
                </>
            )}
            {props.mode === 'Edit' ? (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
            ) : props.mode === 'Add new' ? (
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() => onReset()}>
                        <Trans>Visszaállítás</Trans>
                    </Button>
                    <Button
                        loading={loading}
                        htmlType="button"
                        type="primary"
                        style={{ marginRight: 8 }}
                        onClick={() => form.submit()}
                    >
                        <Trans>Mentés</Trans>
                    </Button>
                </>
            ) : (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => handleUploadCsv()}>
                    <Trans>Feltöltés</Trans>
                </Button>
            )}
        </Form>
    );
};
export default RegionalRepresentativeForm;
