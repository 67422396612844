import useSafeState from '../../utils/safe-state';
import React from 'react';
import { Breadcrumb, Button, message, Popconfirm, Divider, Popover } from 'antd';

import Drawer from '../../components/drawer/drawer';
import { Trans } from '@lingui/macro';
import { DeleteTwoTone, EditTwoTone, EllipsisOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons/lib';
import DtoTable from '../../components/dto-table/dto-table';
import DeviceTypeForm from '../../forms/device-type-form/device-type-drawer';
import DeviceTypeModel from '../../models/dto/device-type-model';
import ModalWithButton from '../../components/modal-with-button/modal-with-button';
import ImageViewer from '../../components/image-viewer/image-viewer';
import { useAxios } from '../../utils/hooks';
import { DownloadOutlined } from '@ant-design/icons';

import ButtonWithIcon from "../../components/buttons/button-with-icon";
import {openNotification} from "../../utils/openNotification";

const DeviceTypesScreen = () => {
    const [templateLoading, setTemplateLoading] = useSafeState(false);
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const text = <Trans>Device Type</Trans>;
    const drawer = Drawer({
        title: text,
        children: <DeviceTypeForm />,
        customClose: () => setTrigger(!trigger),
    });

    const [trigger, setTrigger] = useSafeState(false);

    const handleDownloadTemplateXls = () => {
        setTemplateLoading(true);
        if (fcrAxiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            fcrAxiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/device-types/' + lang + '/template/xls/',
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'device-types.xls');
                    setTemplateLoading(false);
                });
        }
    };
    const handleDelete = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .delete('/resource/device-types/id?id=' + encodeURIComponent(id))
                .then(() => {
                    openNotification('customSuccess', 'Az adott sor sikeresen törölve lett.')
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                });
        }
    };

    const action = (text: string, record: any) => {
        return (
            <Popover
                content={
                    <>
                        <ModalWithButton
                            children={<ImageViewer width={550} folder={record.folder} fileName={record.fileName} />}
                            buttonName={<Trans>View</Trans>}
                            title={<Trans>Picture</Trans>}
                            iconStyle={{ color: '#73d13d' }}
                        />

                        <EditTwoTone
                            twoToneColor={'#40a9ff'}
                            className={'ActionButtons'}
                            onClick={() =>
                                drawer.open('Edit', {
                                    id: record.id,
                                    name: record.name,
                                    prodNum: record.prodNum,
                                    deviceTypeCategory: record.deviceTypeCategory,
                                    methodOfRegistration: record.methodOfRegistration,
                                    description: record.description,
                                    groupable: record.groupable,
                                    version: record.version,
                                })
                            }
                        />
                        <Popconfirm
                            title={<Trans>Are you sure to delete this device type?</Trans>}
                            onConfirm={() => handleDelete(record.name)}
                            okText={<Trans>Yes</Trans>}
                            cancelText={<Trans>No</Trans>}
                        >
                            <DeleteTwoTone twoToneColor={'#ff4d4f'} className={'ActionButtons'} />
                        </Popconfirm>
                    </>
                }
                trigger="hover"
            >
                <EllipsisOutlined style={{ fontSize: 'x-large' }} />
            </Popover>
        );
    };

    const actions = {
        width: 50,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center',
    };

    const header = (
        <>
            <Button type={'primary'} onClick={() => drawer.open('Add new')} style={{ float: 'left' }}>
                <ButtonWithIcon icon={<PlusOutlined />} text={<Trans>Új hozzáadása</Trans>} />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button loading={templateLoading} onClick={() => handleDownloadTemplateXls()} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<DownloadOutlined />}
                    text={<Trans>Excel Sablon</Trans>}
                />

            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button onClick={() => drawer.open('Upload File')} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<UploadOutlined />}
                    text={<Trans>Csv Feltöltés</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
        </>
    );

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Törzsadatok</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Eszköz típusok</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            {drawer.component}
            <DtoTable
                model={DeviceTypeModel}
                tableHeader={header}
                allowExport={true}
                apiUrl={'device-types'}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default DeviceTypesScreen;
