import useSafeState from '../../utils/safe-state';
import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import {DatePicker, Form, Row, Col, Input, Button, Select, Tooltip, Divider, Switch, Card} from 'antd';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import { DeviceRegisterModel } from '../../models/device-register/device-register';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined, InfoCircleTwoTone } from '@ant-design/icons/lib';
import { useAxios } from '../../utils/hooks';
import { openNotification } from '../../utils/openNotification';


interface Interface {
    data?: DeviceRegisterModel[];
    mode?: 'Edit' | 'Add new';
    onClose?: () => void;
}

const DeviceRegisterForm = (props: Interface) => {
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const [loading, setLoading] = useSafeState(false);
    const data = props.data || {};
    const [form] = Form.useForm();
    const [devicesTypes, setDevicesTypes] = useSafeState<any>([]);
    const [decorDevicesTypes, setDecorDevicesTypes] = useSafeState<any>([]);
    const [fileList, setFileList] = useSafeState<Array<any>>([]);
    const [uploading, setUploading] = useSafeState(false);
    const [groupable, setGroupable] = useSafeState(false);
    const [basedOnSerialNumber, setBasedOnSerialNumber] = useSafeState(true);
    const [shops, setShops] = useSafeState<any>([]);
    const [shopSearch, setShopSearch] = useSafeState<any>();

    const [serviceCompanies, setServiceCompanies] = useSafeState<any>([]);
    const [serviceCompaniesSearch, setServiceCompaniesSearch] = useSafeState<any>();

    const [warehouseCompanies, setWarehouseCompanies] = useSafeState<any>([]);
    const [warehouseCompaniesSearch, setWarehouseCompaniesSearch] = useSafeState<any>();

    const [actDeviceId, setActDeviceId] = useSafeState<any>();
    const [actDeviceMethodOfRegistration, setActDeviceMethodOfRegistration] = useSafeState<any>();
    const [needStockTaking, setNeedStockTaking] = useSafeState(false);
    const [devStatus, setDevStatus] = useSafeState<any>([
        { name: 'NEED_TO_BE_EXAMINED' },
        { name: 'DURING_DELIVERY' },
        { name: 'WAIT_FOR_COMMISSIONING' },
        { name: 'DECOR_DAMAGED' },
        { name: 'WAIT_FOR_PERMISSION' },
        { name: 'USED' },
        { name: 'REQUIRED' },
        { name: 'INACTIVE' },
        { name: 'PUBLISHED' },
        { name: 'OUTSOURCED' },
        { name: 'NEED_TO_BE_DELIVERED' },
        { name: 'FOUND_AT_STOCK_TAKING' },
        { name: 'NEED_TO_BE_DISMANTLED' },
        { name: 'DISCARD' },
        { name: 'NEED_TO_BE_DISCARDED' },
        { name: 'TRANSPORTABLE' },
        { name: 'TRANSPORTABLE_FROM_SERVICE' },
        { name: 'SERVICED' },
    ]);

    const [disableDeviceGroups, setDisableDeviceGroups] = useSafeState(true);

    useEffect(() => {
        form.resetFields();

        if (props.mode === 'Edit') {
            setDisableDeviceGroups(false);

            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                if (key == 'groupable') {
                    setGroupable(value == true);
                }
                if (key == 'id') {
                    setActDeviceId(value);
                    fillSelectedItems(value);
                    fieldsValues.push({ name: key, value: value });
                } else if (key == 'purchasingDate' && value != null) {
                    fieldsValues.push({ name: key, value: value });
                } else if (key == 'warrantyDate' && value != null) {
                    fieldsValues.push({ name: key, value: value });
                } else if (key == 'maintanedAt' && value != null) {
                    fieldsValues.push({ name: key, value: value });
                } else if (key == 'needStockTaking') {
                    setNeedStockTaking(value == true);
                } else if (key == 'methodOfRegistration') {
                    setBasedOnSerialNumber(value == 'BASED_ON_SERIAL_NUMBER' ? true : false);
                } else {
                    fieldsValues.push({ name: key, value: value });
                }
            }
            form.setFields(fieldsValues);
        }

        getDevicesTypes();
        getDecorDevicesTypes();
        getShops('', '');
        getServiceCompanies('', '');
        getWarehouseCompanies('', '');
        fillOptions();
    }, []);

    const fillSelectedItems = (value: any) => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .get('/resource/device-register/get-children-by-parent-id?parentId=' + value)
                .then(res => {
                    let tmpArray: string[] = [];
                    res.data.forEach((x: any) => {

                        tmpArray.push(
                            x['deviceType']['name'] +
                            ' || ' +
                            x['serialNumber'] +
                            ' || ' +
                            x['inventoryNumber']
                        );
                    });

                    setSelectedItems(tmpArray);
                })
                .catch(e => console.log(e));
        }
    };

    useEffect(() => {
        if (shopSearch != '') {
            getShops(shopSearch);
        }
    }, [shopSearch]);

    useEffect(() => {
        if (serviceCompaniesSearch != '') {
            getServiceCompanies(serviceCompaniesSearch);
        }
    }, [serviceCompaniesSearch]);

    useEffect(() => {
        if (warehouseCompaniesSearch != '') {
            getWarehouseCompanies(warehouseCompaniesSearch);
        }
    }, [warehouseCompaniesSearch]);

    const getShops = (data?: any, code?: any) => {
        if (data !== '') {
            if (axiosInstance.current != null) {
                axiosInstance.current
                    .post('/resource/shops/page', {
                        page: 0,
                        size: 50,
                        direction: 'asc',
                        sorted_field: 'code',
                        query: {
                            code: data,
                        },
                    })
                    .then(function (response: AxiosResponse<any>) {
                        const shopOptions = generateOptionsByLabelAndValue(response.data.content, 'name', 'code');
                        setShops(shopOptions);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    };

    const getServiceCompanies = (data?: any, code?: any) => {
        if (data !== '') {
            if (axiosInstance.current != null) {
                axiosInstance.current
                    .post('/resource/service-companies/page', {
                        page: 0,
                        size: 50,
                        direction: 'asc',
                        sorted_field: 'code',
                        query: {
                            code: data,
                        },
                    })
                    .then(function (response: AxiosResponse<any>) {
                        const serviceCompOptions = generateOptionsByLabelAndValue(response.data.content, 'name', 'code');
                        setServiceCompanies(serviceCompOptions);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    };

    const getWarehouseCompanies = (data?: any, code?: any) => {
        if (data !== '') {
            if (axiosInstance.current != null) {
                axiosInstance.current
                    .post('/resource/warehouse-companies/page', {
                        page: 0,
                        size: 50,
                        direction: 'asc',
                        sorted_field: 'code',
                        query: {
                            code: data,
                        },
                    })
                    .then(function (response: AxiosResponse<any>) {
                        const warehouseCompOptions = generateOptionsByLabelAndValue(response.data.content, 'name', 'code');
                        setWarehouseCompanies(warehouseCompOptions);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    };

    const getDecorDevicesTypes = () => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .get('/resource/device-types/search?deviceTypeCategory=DECOR')
                .then(function (response: AxiosResponse<any>) {
                    setDecorDevicesTypes(response.data);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const getDevicesTypes = () => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .get('/resource/device-types/get-all-device-that-need-to-registration')
                .then(function (response: AxiosResponse<any>) {
                    setDevicesTypes(response.data);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    type optionalType = {
        key?: number;
        label: any;
        value: string;
    };

    const generateOptions = (data: any, label: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { key: i, value: data[i][label], label: data[i][label] };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const generateChildrenOptions = (data: any): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = {
                value:
                    data[i]['deviceType']['name'] +
                    ' || ' +
                    data[i]['serialNumber'] +
                    ' || ' +
                    data[i]['inventoryNumber'],
                label:
                    data[i]['deviceType']['name'] +
                    ' || ' +
                    data[i]['serialNumber'] +
                    ' || ' +
                    data[i]['inventoryNumber'],
            };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const generateStatusOptions = (data: any, label: string, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = {
                value: data[i][val],
                label: getLabel(data[i][label])
            };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const getLabel = (label: any) => {
        switch (label) {
            case 'NEED_TO_BE_EXAMINED':
                return <Trans>NEED_TO_BE_EXAMINED</Trans>;
            case 'NEED_TO_BE_DELIVERED':
                return <Trans>NEED_TO_BE_DELIVERED</Trans>;
            case 'NEED_TO_BE_DISMANTLED':
                return <Trans>NEED_TO_BE_DISMANTLED</Trans>;
            case 'NEED_TO_BE_DISCARDED':
                return <Trans>NEED_TO_BE_DISCARDED</Trans>;
            case 'DURING_DELIVERY':
                return <Trans>DURING_DELIVERY</Trans>;
            case 'DECOR_DAMAGED':
                return <Trans>DECOR_DAMAGED</Trans>;
            case 'USED':
                return <Trans>USED</Trans>;
            case 'INACTIVE':
                return <Trans>INACTIVE</Trans>;
            case 'PUBLISHED':
                return <Trans>PUBLISHED</Trans>;
            case 'OUTSOURCED':
                return <Trans>OUTSOURCED</Trans>;
            case 'TRANSPORTABLE':
                return <Trans>TRANSPORTABLE</Trans>;
            case 'TRANSPORTABLE_FROM_SERVICE':
                return <Trans>TRANSPORTABLE_FROM_SERVICE</Trans>;
            case 'SERVICED':
                return <Trans>SERVICED</Trans>;
            case 'WAIT_FOR_PERMISSION':
                return <Trans>WAIT_FOR_PERMISSION</Trans>;
            case 'DISCARD':
                return <Trans>DISCARD</Trans>;
            case 'REQUIRED':
                return <Trans>REQUIRED</Trans>;
            case 'WAIT_FOR_COMMISSIONING':
                return <Trans>WAIT_FOR_COMMISSIONING</Trans>;
            case 'FOUND_AT_STOCK_TAKING':
                return <Trans>FOUND_AT_STOCK_TAKING</Trans>;
            default:
                return label;
        }
    };

    const onReset = () => {
        form.resetFields();
    };

    const generateOptionsByLabelAndValue = (data: any, label: string, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { value: data[i][val], label: data[i][val] + ' || ' + data[i][label] };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const config = { headers: { 'Content-Type': 'application/json' } };

    const handleEdit = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current
                .put(
                    '/resource/device-register/id?id=' + form.getFieldValue(['id']),
                    {
                        id: form.getFieldValue(['id']),
                        inventoryNumber: form.getFieldValue(['inventoryNumber']),
                        serialNumber: form.getFieldValue(['serialNumber']),
                        deviceNature: form.getFieldValue(['deviceNature']),
                        deviceStatus: form.getFieldValue(['deviceStatus']),
                        purchasingDate:
                            form.getFieldValue(['purchasingDate']) != null
                                ? moment(form.getFieldValue(['purchasingDate'])).format('YYYY-MM-DD')
                                : null,
                        warrantyDate:
                            form.getFieldValue(['warrantyDate']) != null
                                ? moment(form.getFieldValue(['warrantyDate'])).format('YYYY-MM-DD')
                                : null,
                        maintanedAt:
                            form.getFieldValue(['maintanedAt']) != null
                                ? moment(form.getFieldValue(['maintanedAt'])).format('YYYY-MM-DD')
                                : null,
                        decor: form.getFieldValue(['decor'])
                            ? {
                                  name: form.getFieldValue(['decor']),
                              }
                            : null,
                        deviceType: form.getFieldValue(['deviceType'])
                            ? {
                                  name: form.getFieldValue(['deviceType']),
                                  methodOfRegistration: form.getFieldValue(['methodOfRegistration'])
                            }
                            : null,
                        shop: form.getFieldValue(['shop'])
                            ? {
                                  code: form.getFieldValue(['shop']),
                              }
                            : null,
                        serviceCompany: form.getFieldValue(['serviceCompany'])
                            ? {
                                code: form.getFieldValue(['serviceCompany']),
                            }
                            : null,
                        warehouseCompany: form.getFieldValue(['warehouseCompany'])
                            ? {
                                code: form.getFieldValue(['warehouseCompany']),
                            }
                            : null,
                        folder: form.getFieldValue(['folder']),
                        fileName: form.getFieldValue(['fileName']),
                        version: form.getFieldValue(['version']),
                        needStockTaking: needStockTaking,
                        quantity: form.getFieldValue(['quantity'])
                    },
                    config,
                )
                .then(function (response: AxiosResponse<any>) {
                    setSaveIsSuccess(true);
                    setUploading(true);
                    setLoading(false);
                    if (fileList.length > 0 && axiosInstance.current != null) {
                        const formData = new FormData();
                        fileList.forEach(file => {
                            formData.append('pic', file);
                            formData.append('deviceRegisterId', response.data.id);
                        });
                        setLoading(true);
                        axiosInstance.current
                            .post('/resource/device-register/upload-picture', formData)
                            .then(function (response: AxiosResponse<any>) {
                                setUploading(false);
                                setFileList([]);
                                setLoading(false);
                            })
                            .catch(function (error: any) {
                                openNotification('error', error, props);
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                    }
                })
                .catch(function (error: any) {
                    setSaveIsSuccess(false);
                    console.log(error);
                    setLoading(false);
                });
        }
    };

    const uploadProps = {
        name: 'pic',
        action: process.env.REACT_APP_API_BASE_URL + '/resource/device-register/upload-csv',
        multiple: false,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading', info.file, info.fileList);
            }
            if (status === 'done') {
                openNotification('customSuccess', `${info.file.name} "file uploaded successfully."`);
            } else if (status === 'error') {
                openNotification('customError', `${info.file.name} "file upload failed."`);
            }
        },
        beforeUpload(file: any) {
            let fileList2: Array<any> = fileList;
            fileList2.push(file);
            setFileList(fileList2);
            return false;
        },
    };

    const handleUploadCsv = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });
            axiosInstance.current
                .post('/resource/device-register/upload-csv', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    setLoading(false);
                    openNotification('error', error, props);
                });
        }
    };

    const handleAddNew = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current
                .post(
                    '/resource/device-register',
                    {
                        inventoryNumber: form.getFieldValue(['inventoryNumber']),
                        serialNumber: form.getFieldValue(['serialNumber']),
                        deviceNature: form.getFieldValue(['deviceNature']),
                        deviceStatus: form.getFieldValue(['deviceStatus']),
                        purchasingDate:
                            form.getFieldValue(['purchasingDate']) != null
                                ? moment(form.getFieldValue(['purchasingDate'])).format('YYYY-MM-DD')
                                : null,
                        warrantyDate:
                            form.getFieldValue(['warrantyDate']) != null
                                ? moment(form.getFieldValue(['warrantyDate'])).format('YYYY-MM-DD')
                                : null,
                        maintanedAt:
                            form.getFieldValue(['maintanedAt']) != null
                                ? moment(form.getFieldValue(['maintanedAt'])).format('YYYY-MM-DD')
                                : null,
                        decor: form.getFieldValue(['decor'])
                            ? {
                                  name: form.getFieldValue(['decor']),
                              }
                            : null,
                        deviceType: form.getFieldValue(['deviceType'])
                            ? {
                                  name: form.getFieldValue(['deviceType']),
                                  methodOfRegistration: actDeviceMethodOfRegistration
                              }
                            : null,
                        shop: form.getFieldValue(['shop'])
                            ? {
                                  code: form.getFieldValue(['shop']),
                              }
                            : null,
                        serviceCompany: form.getFieldValue(['serviceCompany'])
                            ? {
                                code: form.getFieldValue(['serviceCompany']),
                            }
                            : null,
                        warehouseCompany: form.getFieldValue(['warehouseCompany'])
                            ? {
                                code: form.getFieldValue(['warehouseCompany']),
                            }
                            : null,
                        folder: form.getFieldValue(['folder']),
                        fileName: form.getFieldValue(['fileName']),
                        needStockTaking: needStockTaking,
                        quantity: form.getFieldValue(['quantity'])
                    },
                    config,
                )
                .then(function (response: AxiosResponse<any>) {
                    setActDeviceId(response.data.id);
                    setSaveIsSuccess(true);
                    setUploading(true);
                    setLoading(false);
                    if (fileList.length > 0 && axiosInstance.current != null) {
                        const formData = new FormData();
                        fileList.forEach(file => {
                            formData.append('pic', file);
                            formData.append('deviceRegisterId', response.data.id);
                        });
                        setLoading(true);
                        axiosInstance.current
                            .post('/resource/device-register/upload-picture', formData)
                            .then(function (response: AxiosResponse<any>) {
                                setUploading(false);
                                setFileList([]);
                                setLoading(false);
                            })
                            .catch(function (error: any) {
                                setLoading(false);
                                openNotification('error', error, props);
                                setUploading(false);
                            });
                    } else {
                        setLoading(false);
                    }
                })
                .catch(function (error: any) {
                    setSaveIsSuccess(false);
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const { Option } = Select;

    const [childrenOptions, setChildrenOptions] = useSafeState<Array<any>>([]);
    const [children, setChildren] = useSafeState<Array<any>>([]);
    const [selectedItems, setSelectedItems] = useSafeState<Array<string>>([]);
    const [saveIsSuccess, setSaveIsSuccess] = useSafeState(false);

    const updateDeviceRegister = () => {
        if (axiosInstance.current != null) {

            let selectedArray: string[] = [];

            for(let i = 0; i < selectedItems.length; i++) {
                 let deviceTypeName = selectedItems[i].split(' || ')[0];
                 let serialNumber = selectedItems[i].split(' || ')[1];

                 for(let j = 0; j < children.length; j++) {

                     if(
                         children[j]['serialNumber'] == serialNumber &&
                         children[j]['deviceType']['name'] == deviceTypeName
                     ){
                         selectedArray.push(children[j]['id']);
                     }
                 }
             }

              axiosInstance.current
                  .put(
                      '/resource/device-register/update-all-children?parentDeviceId=' + actDeviceId,
                      selectedArray,
                      config,
                  )
                  .then(() => openNotification('success', props))
                  .catch(() => openNotification('error', props));
        }
    };

    const fillOptions = () => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .get(
                    (
                        '/resource/device-register/get-devices-by-parent-name?deviceTypeName=' +
                        encodeURIComponent(form.getFieldValue(['deviceType']))
                    ),
                )
                .then(res => {
                    setChildren(res.data);
                    setChildrenOptions(generateChildrenOptions(res.data));
                    setGroupable(res.data.length > 0);
                })
                .catch(e => {
                    console.log(e);
                    setGroupable(false);
                });
        }
    };

    const setMethodOfRegistration = () => {

        for(let i = 0; i < devicesTypes.length; i++){

            if(devicesTypes[i]['name'] == form.getFieldValue(['deviceType'])){
                setBasedOnSerialNumber(devicesTypes[i]['methodOfRegistration'] == 'BASED_ON_SERIAL_NUMBER' ? true : false);
                setActDeviceMethodOfRegistration(devicesTypes[i]['methodOfRegistration']);
                return;
            }
        }
    };

    useEffect(() => {
        if (saveIsSuccess) {
            updateDeviceRegister();
        }
    }, [saveIsSuccess]);

    const handleChildrenChange = (selected: any) => {
        setSelectedItems(selected);
    };

    const onChangeNeedStockTaking = () => {
        setNeedStockTaking(!needStockTaking);
    };

    return (
        <Form
            id={props.mode}
            form={form}
            layout="vertical"
            onFinish={() => (props.mode === 'Edit' ? handleEdit() : handleAddNew())}
        >
            {props.mode === 'Edit' || props.mode === 'Add new' ? (
                <>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="deviceType"
                                label={
                                    <>
                                        <Trans>Device Type</Trans>
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <span>
                                                    A lista maximum 50 elemet jelenít meg, és a tartalma a beírt szöveg
                                                    alapján módosul.
                                                </span>
                                            }
                                            children={
                                                <InfoCircleTwoTone twoToneColor="#1890ff" style={{ marginLeft: 5 }} />
                                            }
                                        />
                                    </>
                                }
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a device type</Trans>}
                                        onChange={() => {
                                            setSelectedItems([]);
                                            fillOptions();
                                            setMethodOfRegistration();
                                        }}
                                        options={generateOptions(devicesTypes, 'name')}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please enter a device type</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="decor"
                                label={
                                    <>
                                        <Trans>Decor</Trans>
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <span>
                                                    A lista maximum 50 elemet jelenít meg, és a tartalma a beírt szöveg
                                                    alapján módosul.
                                                </span>
                                            }
                                            children={
                                                <InfoCircleTwoTone twoToneColor="#1890ff" style={{ marginLeft: 5 }} />
                                            }
                                        />
                                    </>
                                }
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a decor</Trans>}
                                        options={generateOptions(decorDevicesTypes, 'name')}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please enter a decor</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={0}>
                            <Form.Item name="version" children={<Input type={'hidden'} />} />
                        </Col>
                        <Col span={0}>
                            <Form.Item name="id" children={<Input type={'hidden'} />} />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="serialNumber"
                                label={<Trans>Serial Number</Trans>}
                                children={<Input disabled={!basedOnSerialNumber} />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: basedOnSerialNumber,
                                        message: <Trans>Please enter an Serial number</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                name="inventoryNumber"
                                label={<Trans>Inventory Number</Trans>}
                                children={<Input disabled={!basedOnSerialNumber} />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: basedOnSerialNumber,
                                        message: <Trans>Please enter an inventory number</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="quantity"
                                label={<Trans>Quantity</Trans>}
                                children={<Input type={"number"} min={1} defaultValue={1} disabled={basedOnSerialNumber} />}
                                rules={[
                                    {
                                        required: !basedOnSerialNumber,
                                        message: <Trans>Please enter a quantity</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="deviceNature"
                                label={<Trans>Nature</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a Nature</Trans>}
                                    >
                                        <Option value="OOH">
                                            <Trans>OOH</Trans>
                                        </Option>
                                        <Option value="RETAIL">
                                            <Trans>RETAIL</Trans>
                                        </Option>
                                        <Option value="BM">
                                            <Trans>BM</Trans>
                                        </Option>
                                    </Select>
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please enter a Nature</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="deviceStatus"
                                label={<Trans>Device Status</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={false}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a Device Status</Trans>}
                                        options={generateStatusOptions(devStatus, 'name', 'name')}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: true,
                                        message: <Trans>Please enter a Device Status</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="purchasingDate"
                                label={<Trans>Purchase Date</Trans>}
                                children={<DatePicker style={{ width: '100%' }} />}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="warrantyDate"
                                label={<Trans>Warranty Date</Trans>}
                                children={<DatePicker style={{ width: '100%' }} />}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="maintanedAt"
                                label={<Trans>Maintened Date</Trans>}
                                children={<DatePicker style={{ width: '100%' }} />}
                            />
                        </Col>
                    </Row>

                    <Card style={{ backgroundColor: '#f0f5ff', marginBottom: 10}}>
                        <Divider orientation="left" style={{ marginTop: 0, fontSize: 'larger' }}>
                            <Trans>Location</Trans>
                        </Divider>

                        <Row gutter={24}>
                            <Col span={14}>
                                <Form.Item
                                    name="shop"
                                    label={
                                        <>
                                            <Trans>Shop</Trans>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt szöveg
                                                        alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone twoToneColor="#1890ff" style={{ marginLeft: 5 }} />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Select a Shop</Trans>}
                                            onSearch={value => setShopSearch(value)}
                                            onChange={() => form.resetFields(['warehouseCompany', 'serviceCompany'])}
                                            filterOption={false}
                                            options={shops}
                                        ></Select>
                                    }
                                    rules={[
                                        {
                                            required: false,
                                            message: <Trans>Please enter a shop</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={14}>
                                <Form.Item
                                    name="warehouseCompany"
                                    label={
                                        <>
                                            <Trans>Warehouse Company</Trans>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt szöveg
                                                        alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone twoToneColor="#1890ff" style={{ marginLeft: 5 }} />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Select a Warehouse Company</Trans>}
                                            onSearch={value => setWarehouseCompaniesSearch(value)}
                                            onChange={() => form.resetFields(['shop', 'serviceCompany'])}
                                            filterOption={false}
                                            options={warehouseCompanies}
                                        ></Select>
                                    }
                                    rules={[
                                        {
                                            required: false,
                                            message: <Trans>Please enter a warehouse company</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={14}>
                                <Form.Item
                                    name="serviceCompany"
                                    label={
                                        <>
                                            <Trans>Service Company</Trans>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt szöveg
                                                        alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone twoToneColor="#1890ff" style={{ marginLeft: 5 }} />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Select a Service Company</Trans>}
                                            onSearch={value => setServiceCompaniesSearch(value)}
                                            onChange={() => form.resetFields(['warehouseCompany', 'shop'])}
                                            filterOption={false}
                                            options={serviceCompanies}
                                        ></Select>
                                    }
                                    rules={[
                                        {
                                            required: false,
                                            message: <Trans>Please enter a service company</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>

                    </Card>

                    {groupable ? (
                        <Divider orientation="left" style={{ marginTop: 0, fontSize: 'larger' }}>
                            Eszköz csoport
                        </Divider>
                    ) : (
                        <></>
                    )}
                    <Row gutter={24} hidden={!groupable} style={{ marginBottom: 20 }}>
                        <Col span={24}>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                value={selectedItems}
                                placeholder={<Trans>Eszköz</Trans>}
                                onChange={handleChildrenChange}
                                options={childrenOptions}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={16}>
                            <div style={{ marginBottom: 5}}>
                                <Trans>Need Stock Taking</Trans>?
                            </div>
                            <div style={{ marginBottom: 15}}>
                                <Switch checked={needStockTaking} onChange={onChangeNeedStockTaking} />
                            </div>
                        </Col>
                    </Row>

                    <br />
                    <Form.Item
                        name="fenykep"
                        label={<Trans>Fénykép</Trans>}
                        children={
                            <Dragger {...uploadProps}>
                                <div></div>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    <Trans>Click or drag file to this area to upload</Trans>
                                </p>
                                <p className="ant-upload-hint">
                                    <Trans>
                                        Support for a single upload. Strictly prohibit from uploading company data or
                                        other band files
                                    </Trans>
                                </p>
                            </Dragger>
                        }
                    />
                    <br />
                </>
            ) : (
                <>
                    <Form.Item
                        name="fenykep"
                        children={
                            <Dragger {...uploadProps}>
                                <div></div>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    <Trans>Click or drag file to this area to upload</Trans>
                                </p>
                                <p className="ant-upload-hint">
                                    <Trans>
                                        Support for a single upload. Strictly prohibit from uploading company data or
                                        other band files
                                    </Trans>
                                </p>
                            </Dragger>
                        }
                    />
                    <br />
                </>
            )}
            {props.mode === 'Edit' ? (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
            ) : props.mode === 'Add new' ? (
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() => onReset()}>
                        <Trans>Visszaállítás</Trans>
                    </Button>
                    <Button
                        loading={loading}
                        htmlType="button"
                        type="primary"
                        style={{ marginRight: 8 }}
                        onClick={() => form.submit()}
                    >
                        <Trans>Mentés</Trans>
                    </Button>
                </>
            ) : (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => handleUploadCsv()}>
                    <Trans>Feltöltés</Trans>
                </Button>
            )}
        </Form>
    );
};
export default DeviceRegisterForm;
