import moment from 'moment';
import useSafeState from '../../utils/safe-state';
import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, message, Select, DatePicker } from 'antd';
import { Trans } from '@lingui/macro';
import { AxiosResponse } from 'axios';
import { RegionalRepresentativeModel } from '../../models/regional-representative/regional-representative';
import { useAxios } from '../../utils/hooks';
import { InputNumber } from 'antd/es';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { openNotification } from '../../utils/openNotification';


interface Interface {
    data?: RegionalRepresentativeModel[];
    mode?: 'Edit' | 'Add new';
    onClose?: () => void;
}

const RepresentativeDeputiesForm = (props: Interface) => {
    const data = props.data || {};
    const [users, setUsers] = useSafeState<any>([]);
    const [loading, setLoading] = useSafeState(false);
    const [fileList, setFileList] = useSafeState<Array<any>>([]);
    const [outOfOfficeEndDate, setOutOfOfficeEndDate] = useSafeState<any>('');

    type optionalType = {
        label: string;
        value: string;
    };

    const [form] = Form.useForm();

    const uploadProps = {
        name: 'pic',
        action: process.env.REACT_APP_API_BASE_URL + '/resource/representative-deputies/upload-csv',
        multiple: false,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading', info.file, info.fileList);
            }
            if (status === 'done') {
                openNotification('customSuccess', `${info.file.name} "file uploaded successfully."`);
            } else if (status === 'error') {
                openNotification('customError', `${info.file.name} "file upload failed."`);
            }
        },
        beforeUpload(file: any) {
            let fileList2: Array<any> = fileList;
            fileList2.push(file);
            setFileList(fileList2);
            return false;
        },
    };

    const handleCsvUpload = () => {
        if (fcrAxiosInstance.current != null) {
            setLoading(true);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });
            fcrAxiosInstance.current
                .post(process.env.REACT_APP_API_BASE_URL + '/resource/representative-deputies/upload-csv', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const keycloakAxiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + '');
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    React.useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .get('/resource/regional-representatives/get-ordered-list')
                .then(function (response: AxiosResponse<any>) {
                    setUsers(response.data);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const generateNameOptions = (data: any): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { value: data[i]['id'], label: data[i]['name'] };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    useEffect(() => {
        form.resetFields();

        if (props.mode === 'Edit') {
            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                if (key == 'deputyOne') {
                    console.log(value);
                    let deputy1: any = value;
                    form.setFieldsValue(deputy1 ? { deputyOne: deputy1['id'] } : null);
                } else if (key == 'deputyTwo') {
                    let deputy2: any = value;
                    form.setFieldsValue(deputy2 ? { deputyTwo: deputy2['id'] } : null);
                } else if (key == 'deputyThree') {
                    let deputy3: any = value;
                    form.setFieldsValue(deputy3 ? { deputyThree: deputy3['id'] } : null);
                } else if (key == 'deputyFour') {
                    let deputy4: any = value;
                    form.setFieldsValue(deputy4 ? { deputyFour: deputy4['id'] } : null);
                } else if (key == 'deputyFive') {
                    let deputy5: any = value;
                    form.setFieldsValue(deputy5 ? { deputyFive: deputy5['id'] } : null);
                } else if (key == 'deputySix') {
                    let deputy6: any = value;
                    form.setFieldsValue(deputy6 ? { deputySix: deputy6['id'] } : null);
                } else if (key == 'deputySeven') {
                    let deputy7: any = value;
                    form.setFieldsValue(deputy7 ? { deputySeven: deputy7['id'] } : null);
                } else if (key == 'deputyEight') {
                    let deputy8: any = value;
                    form.setFieldsValue(deputy8 ? { deputyEight: deputy8['id'] } : null);
                } else if (key == 'deputyNine') {
                    let deputy9: any = value;
                    form.setFieldsValue(deputy9 ? { deputyNine: deputy9['id'] } : null);
                } else if (key == 'deputyTen') {
                    let deputy10: any = value;
                    form.setFieldsValue(deputy10 ? { deputyTen: deputy10['id'] } : null);
                } else if (key == 'outOfOfficeEndDate') {
                    setOutOfOfficeEndDate(value);
                } else {
                    fieldsValues.push({ name: key, value: value });
                }
            }
            form.setFields(fieldsValues);
        }
    }, []);

    const onReset = () => {
        form.resetFields();
    };

    const handleEdit = () => {
        if (props.mode === 'Edit') {
            if (fcrAxiosInstance.current != null) {
                setLoading(true);
                fcrAxiosInstance.current
                    .put('/resource/regional-representatives/id?id=' + form.getFieldValue(['id']), {
                        id: form.getFieldValue(['id']),
                        name: form.getFieldValue(['name']),
                        username: form.getFieldValue(['username']),
                        email: form.getFieldValue(['email']),
                        phone: form.getFieldValue(['phone']),
                        sfaCode: form.getFieldValue(['sfaCode']),
                        rank: form.getFieldValue(['rank']),
                        region: form.getFieldValue(['region']),
                        outOfOfficeEndDate:
                            form.getFieldValue(['outOfOfficeEndDate']) != null
                                ? form.getFieldValue(['outOfOfficeEndDate']).format('YYYY-MM-DD')
                                : null,
                        deputy1: form.getFieldValue(['deputyOne']) ? { id: form.getFieldValue(['deputyOne']) } : null,
                        deputy2: form.getFieldValue(['deputyTwo']) ? { id: form.getFieldValue(['deputyTwo']) } : null,
                        deputy3: form.getFieldValue(['deputyThree'])
                            ? { id: form.getFieldValue(['deputyThree']) }
                            : null,
                        deputy4: form.getFieldValue(['deputyFour']) ? { id: form.getFieldValue(['deputyFour']) } : null,
                        deputy5: form.getFieldValue(['deputyFive']) ? { id: form.getFieldValue(['deputyFive']) } : null,
                        deputy6: form.getFieldValue(['deputySix']) ? { id: form.getFieldValue(['deputySix']) } : null,
                        deputy7: form.getFieldValue(['deputySeven'])
                            ? { id: form.getFieldValue(['deputySeven']) }
                            : null,
                        deputy8: form.getFieldValue(['deputyEight'])
                            ? { id: form.getFieldValue(['deputyEight']) }
                            : null,
                        deputy9: form.getFieldValue(['deputyNine']) ? { id: form.getFieldValue(['deputyNine']) } : null,
                        deputy10: form.getFieldValue(['deputyTen']) ? { id: form.getFieldValue(['deputyTen']) } : null,
                        version: form.getFieldValue(['version']),
                    })
                    .then(() => {
                        openNotification('success', '', props);
                        setLoading(false);
                    })
                    .catch(function (error: any) {
                        openNotification('error', error, props);
                        setLoading(false);
                    });
            }
        }
    };

    return (
        <Form id={props.mode} form={form} layout="vertical" onFinish={() => handleEdit()}>
            {props.mode === 'Edit' ? (
                <>
                    <Row gutter={24}>
                        <Col span={0}>
                            <Form.Item
                                name="id"
                                children={<InputNumber type={'hidden'} />}
                                rules={[
                                    {
                                        type: 'number',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Id</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label={<Trans>Területi Képviselő</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="outOfOfficeEndDate"
                                label={<Trans>Vég dátum</Trans>}
                                children={
                                    outOfOfficeEndDate != null ? (
                                        <DatePicker
                                            defaultValue={moment(outOfOfficeEndDate, 'YYYY-MM-DD')}
                                            format={'YYYY-MM-DD'}
                                            placeholder={'YYYY-MM-DD'}
                                        />
                                    ) : (
                                        <DatePicker />
                                    )
                                }
                                rules={[
                                    {
                                        type: 'date',
                                        required: false,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="deputyOne"
                                label={<Trans>Deputy1</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="deputyTwo"
                                label={<Trans>Deputy 2</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="deputyThree"
                                label={<Trans>Deputy 3</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="deputyFour"
                                label={<Trans>Deputy 4</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="deputyFive"
                                label={<Trans>Deputy 5</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="deputySix"
                                label={<Trans>Deputy 6</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="deputySeven"
                                label={<Trans>Deputy 7</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="deputyEight"
                                label={<Trans>Deputy 8</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="deputyNine"
                                label={<Trans>Deputy 9</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="deputyTen"
                                label={<Trans>Deputy 10</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a user</Trans>}
                                        options={generateNameOptions(users)}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                        <Trans>Mentés</Trans>
                    </Button>
                </>
            ) : (
                <>
                    <Dragger {...uploadProps}>
                        <div></div>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            <Trans>Click or drag file to this area to upload</Trans>
                        </p>
                        <p className="ant-upload-hint">
                            <Trans>
                                Support for a single upload. Strictly prohibit from uploading company data or other band
                                files
                            </Trans>
                        </p>
                    </Dragger>
                    <br />
                    <Button loading={loading} htmlType="button" type="primary" onClick={() => handleCsvUpload()}>
                        <Trans>Mentés</Trans>
                    </Button>
                </>
            )}
        </Form>
    );
};
export default RepresentativeDeputiesForm;
