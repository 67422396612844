import {CopyOutlined} from '@ant-design/icons';
import {EyeOutlined} from '@ant-design/icons/lib';
import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import {Button, Divider, Popover, Tag} from 'antd';

const AuroraConnectionModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();
    columns.push({ key: 'id', sorter: true, title: <Trans>ID</Trans>, fixed: 'left', direction: 'desc' });
    columns.push({
        key: 'requestType',
        uniqueSelectFilterData: [
            {
                label: <Trans>Mind</Trans>,
                value: null,
            },
            {
                label: <Trans>Create</Trans>,
                value: 'POST',
            },
            {
                label: <Trans>Update</Trans>,
                value: 'PUT',
            },
            {
                label: <Trans>Delete</Trans>,
                value: 'DELETE',
            },
        ],
        sorter: true,
        title: <Trans>Kérés típusa</Trans>,
        render: text => {
            let color = 'gray';
            let transText;
            if (text === 'PUT') {
                color = 'gold';
                transText = <Trans>Update</Trans>;
            } else if (text === 'POST') {
                color = 'green';
                transText = <Trans>Create</Trans>;
            } else if (text === 'DELETE') {
                color = 'red';
                transText = <Trans>Delete</Trans>;
            } else if (text === 'GET') {
                color = 'blue';
                transText = <Trans>Get</Trans>;
            }
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });
    columns.push({
        key: 'responseHttpStatusCode',
        sorter: true,
        title: <Trans>Válasz kód</Trans>,
        render: text => {
            let color = 'gray';
            if (text != null && text.startsWith('2')) {
                color = 'green';
            } else {
                color = 'red';
            }
            return (
                <Tag color={color} key={text}>
                    {text}
                </Tag>
            );
        },
    });
    columns.push({
        key: 'requestUri',
        sorter: true,
        title: <Trans>Request Body</Trans>,
        render: text => {

            return <Popover title={<Trans>Request Body in Base64 (Encoded from Uri) </Trans>} content={
                <div style={{width: '60vh',wordBreak: 'break-all'}}>{text}
                    <Divider />
                    <div style={{display:'flex',justifyContent:'center'}}>
                       <Button icon={<CopyOutlined />} type="primary" onClick={() => {navigator.clipboard.writeText(text)}}><Trans>Copy</Trans></Button>
                    </div>
                </div>
            }>
                <Button type="primary">
                    <EyeOutlined />
                </Button>
            </Popover>
        },
    });
    columns.push({
        key: 'requestBody',
        sorter: true,
        title: <Trans>Request Uri</Trans>,
        render: text => {

            return <Popover title={<Trans>Request Uri</Trans>} content={
                <div style={{width: '60vh',wordBreak: 'break-all'}}>{text}
                    <Divider />
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <Button icon={<CopyOutlined />} type="primary" onClick={() => {navigator.clipboard.writeText(text)}}><Trans>Copy</Trans></Button>
                    </div>
                </div>
            }>
                <Button type="primary">
                    <EyeOutlined />
                </Button>
            </Popover>
        },
    });
    columns.push({ key: 'requestUrl', sorter: true, title: <Trans>Request Url</Trans> , width:300});
    columns.push({ key: 'createdDate', filter: false, sorter: true, title: <Trans>Created date</Trans> });

    return {
        columns: columns,
        url: '/resource/prime-sols-requests',
    };
};
export default AuroraConnectionModel();
