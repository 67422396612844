import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import { Tag } from 'antd';

const StockTakingLineModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();

    const device1Columns: Array<Column> = new Array();
    device1Columns.push({
        key: 'deviceRegister.deviceType.name',
        dataIndex: ['deviceRegister', 'deviceType', 'name'],
        filter: true,
        sorter: true,
        title: <Trans>Device 1 Name</Trans>,
        width: 250,
        render: text => {
            return { props: { style: { background: '#fffbe6' } }, children: <div>{text}</div> };
        },
    });
    device1Columns.push({
        key: 'deviceRegister.serialNumber',
        dataIndex: ['deviceRegister', 'serialNumber'],
        filter: true,
        sorter: true,
        title: <Trans>Device 1 Serial Number</Trans>,
        width: 200,
        render: text => {
            return { props: { style: { background: '#fffbe6' } }, children: <div>{text}</div> };
        },
    });
    device1Columns.push({
        key: 'deviceRegister.inventoryNumber',
        dataIndex: ['deviceRegister', 'inventoryNumber'],
        filter: true,
        sorter: true,
        title: <Trans>Device 1 Inventory Number</Trans>,
        width: 150,
        render: text => {
            return { props: { style: { background: '#fffbe6' } }, children: <div>{text}</div> };
        },
    });
    device1Columns.push({
        key: 'deviceRegister.decor.name',
        dataIndex: ['deviceRegister', 'decor', 'name'],
        filter: true,
        sorter: true,
        title: <Trans>Device 1 Decoration</Trans>,
        width: 200,
        render: text => {
            return { props: { style: { background: '#fffbe6' } }, children: <div>{text}</div> };
        },
    });
    columns.push({
        key: 'device',
        filter: false,
        sorter: false,
        title: <Trans>Device</Trans>,
        children: device1Columns,
    });

    columns.push({
        key: 'status',
        width: 200,
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let color = 'gray';

            if (text != null && text == 'INIT') {
                return (
                    <Tag color={'blue'} key={text}>
                        <Trans>INIT</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'APPROVED') {
                return (
                    <Tag color={'green'} key={text}>
                        <Trans>VAN</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'DENIED') {
                return (
                    <Tag color={'red'} key={text}>
                        <Trans>NINCS</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'EXTRA') {
                return (
                    <Tag color={'orange'} key={text}>
                        <Trans>PLUSSZ</Trans>
                    </Tag>
                );
            }
            return (
                <Tag color={color} key={text}>
                    {text}
                </Tag>
            );
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>INIT</Trans>,
                value: 'INIT',
            },
            {
                label: <Trans>VAN</Trans>,
                value: 'APPROVED',
            },
            {
                label: <Trans>NINCS</Trans>,
                value: 'DENIED',
            },
            {
                label: <Trans>PLUSSZ</Trans>,
                value: 'EXTRA',
            },
        ],
    });

    columns.push({
        key: 'hasQrCode',
        width: 100,
        uniqueSelectFilterData: [
            {
                label: <Trans>Mind</Trans>,
                value: null,
            },
            {
                label: <Trans>VAN</Trans>,
                value: 'true',
            },
            {
                label: <Trans>NINCS</Trans>,
                value: 'false',
            },
        ],
        sorter: true,
        title: <Trans>Qr code</Trans>,
    });

    columns.push({
        key: 'typeOfPlacement',
        width: 150,
        sorter: true,
        title: <Trans>Type of placement</Trans>,
        render: text => {
            let color = 'white';

            if (text != null && text == 'PERSONAL') {
                return (
                    <Tag color={'cyan'} key={text}>
                        <Trans>PERSONAL PLACEMENT</Trans>
                    </Tag>
                );
            }
            if (text != null && text == 'CENTRAL') {
                return (
                    <Tag color={'purple'} key={text}>
                        <Trans>CENTRAL PLACEMENT</Trans>
                    </Tag>
                );
            }
            return (
                <Tag color={color} key={text}>
                    {text}
                </Tag>
            );
        },
        uniqueSelectFilterData: [
            {
                label: <Trans>NONE</Trans>,
                value: null,
            },
            {
                label: <Trans>PERSONAL PLACEMENT</Trans>,
                value: 'PERSONAL',
            },
            {
                label: <Trans>CENTRAL PLACEMENT</Trans>,
                value: 'CENTRAL',
            },
        ],
    });

    columns.push({ key: 'remark', width: 200, sorter: true, title: <Trans>Remark</Trans> });

    columns.push({ key: 'createdBy', width: 200, sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({
        key: 'createdDate',
        filter: false,
        width: 200,
        sorter: true,
        direction: 'desc',
        title: <Trans>Létrehozva</Trans>,
    });

    return {
        columns: columns,
        url: '/resource/stock-taking-lines',
    };
};
export default StockTakingLineModel();
