import React, { useCallback, useRef } from 'react';
import { AxiosInstance, AxiosResponse } from 'axios';

const userListController = (
    axiosInstance: AxiosInstance,
    url: string,
    setData: (data: any) => void,
    id?: number | string,
) => {
    axiosInstance
        .get(url)
        .then(function (response: AxiosResponse<any>) {
            if (Array.isArray(response.data)) setData(response.data);
        })
        .catch(function (error: any) {
            console.log(error); //TODO::EndIt
        });
};
export default userListController;
