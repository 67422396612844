import { AxiosInstance, AxiosResponse } from 'axios';

const paginationController = (
    query: any,
    url: string,
    size: number,
    direction: string,
    sortedField: string,
    setData: (data: any) => void,
    SetTotalElement: (data: any) => void,
    pagination: number,
    axiosInstance: AxiosInstance,
) => {
    let page = pagination - 1;

    axiosInstance
        .post(
            url + '/page',
            {
                page: page,
                size: size,
                direction: direction,
                sorted_field: sortedField,
                query: query,
            },
            { withCredentials: true },
        )
        .then(function (response: AxiosResponse<any>) {
            setData(response.data.content);
            SetTotalElement(response.data.totalElements);
        })
        .catch(function (error: any) {
            console.log(error); //TODO::EndIt
        });
};
export default paginationController;
