import useSafeState from '../../utils/safe-state';
import React, { ReactElement, ReactNode } from 'react';
import { Modal } from 'antd';
import { Trans } from '@lingui/macro';
import { FileImageOutlined } from '@ant-design/icons/lib';

interface Interface {
    title?: ReactNode;
    drawerTitle?: string;
    width?: number | string;
    onCustomClose?: () => void;
    paddingBottom?: number;
    bodyStyle?: React.CSSProperties;
    submitText?: string;
    cancelText?: string;
    children: ReactElement;
    customClose?: () => void;
    buttonName?: ReactNode;
    iconStyle?: React.CSSProperties;
}

const ModalWithButton = (props: Interface): ReactElement => {
    const [visible, setVisible] = useSafeState(false);

    const onClose = () => {
        if (props.customClose) {
            props.customClose();
        }
        setVisible(false);
    };

    return (
        <>
            <Modal
                title={props.title ? <Trans>{props.title}</Trans> : ''}
                width={props.width ? props.width : 'fit-content'}
                bodyStyle={props.bodyStyle ? props.bodyStyle : { padding: 5, textAlign: 'center' }}
                visible={visible}
                onOk={onClose}
                onCancel={onClose}
                footer={[]}
            >
                {props.children}
            </Modal>
            <FileImageOutlined
                className={'ActionButtons'}
                onClick={() => setVisible(!visible)}
                style={props.iconStyle}
            />
        </>
    );
};
export default ModalWithButton;
