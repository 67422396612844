import Drawer from '../../components/drawer/drawer';
import RepresentativeDeputiesForm from '../../forms/representative-deputies-drawer/representative-deputies-drawer';
import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    EllipsisOutlined,
    PlusOutlined,
    UploadOutlined,
} from '@ant-design/icons/lib';
import useSafeState from '../../utils/safe-state';
import React from 'react';
import { Breadcrumb, Button, Divider, Popconfirm, Popover } from 'antd';
import { Trans } from '@lingui/macro';
import { FileExcelOutlined } from '@ant-design/icons';
import DtoTable from '../../components/dto-table/dto-table';
import RepresentativeDeputiesModel from '../../models/dto/representative-deputies-model';
import { useAxios } from '../../utils/hooks';
import ButtonWithIcon from "../../components/buttons/button-with-icon";

const RepresentativeDeputiesScreen = () => {
    const [allInReportLoading, setAllInReportLoading] = useSafeState(false);
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');
    const [trigger, setTrigger] = useSafeState(false);
    const [templateLoading, setTemplateLoading] = useSafeState(false);

    const text = <Trans>Képviselő helyettesek</Trans>;
    const drawer = Drawer({
        title: text,
        children: <RepresentativeDeputiesForm />,
        customClose: () => setTrigger(!trigger),
    });

    const handleDownloadTemplateXls = () => {
        setTemplateLoading(true);
        if (fcrAxiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            fcrAxiosInstance
                .current({
                    url:
                        process.env.REACT_APP_API_BASE_URL +
                        '/resource/representative-deputies/' +
                        lang +
                        '/template/xls/',
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'representative-deputies.xls');
                    setTemplateLoading(false);
                });
        }
    };

    const header = (
        <>
            <Button loading={templateLoading} onClick={() => handleDownloadTemplateXls()} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<DownloadOutlined />}
                    text={<Trans>Excel Sablon</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button onClick={() => drawer.open('Upload File')} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<UploadOutlined />}
                    text={<Trans>Csv Feltöltés</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
        </>
    );

    const actions = {
        width: 50,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center',
    };

    const action = (text: string, record: any) => (
        <Popover
            content={
                <>
                    <EditOutlined
                        style={{ color: '#40a9ff' }}
                        className={'ActionButtons'}
                        onClick={() =>
                            drawer.open('Edit', {
                                id: record.id,
                                name: record.name,
                                username: record.username,
                                password: record.password,
                                rank: record.rank,
                                region: record.region,
                                sfaCode: record.sfaCode,
                                email: record.email,
                                phone: record.phone,
                                outOfOfficeEndDate: record.outOfOfficeEndDate,
                                deputyOne: record.deputy1,
                                deputyTwo: record.deputy2,
                                deputyThree: record.deputy3,
                                deputyFour: record.deputy4,
                                deputyFive: record.deputy5,
                                deputySix: record.deputy6,
                                deputySeven: record.deputy7,
                                deputyEight: record.deputy8,
                                deputyNine: record.deputy9,
                                deputyTen: record.deputy10,
                                version: record.version,
                            })
                        }
                    />
                </>
            }
            trigger="hover"
        >
            <EllipsisOutlined style={{ fontSize: 'x-large' }} />
        </Popover>
    );

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Törzsadatok</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Képviselő helyettesítések</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            {drawer.component}
            <DtoTable
                model={RepresentativeDeputiesModel}
                tableHeader={header}
                allowExport={true}
                apiUrl={'representative-deputies'}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default RepresentativeDeputiesScreen;
