import QrModal from '../../components/qr-modal/qr-modal';
import Paragraph from 'antd/lib/typography/Paragraph';
import useSafeState from '../../utils/safe-state';
import React, { useEffect } from 'react';
import {Form, Row, Col, Input, Button, Select, Divider, Card, Tooltip, Switch, DatePicker, Upload} from 'antd';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { AxiosResponse } from 'axios';
import { WorkRequestsModel } from '../../models/work-requests/work-requests';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined, InfoCircleTwoTone, QrcodeOutlined } from '@ant-design/icons/lib';
import { useAxios } from '../../utils/hooks';

import QrReader from 'react-qr-reader';
import { openNotification } from '../../utils/openNotification';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/hu_HU';

interface Interface {
    data?: WorkRequestsModel;
    mode?: 'Edit' | 'Add new' | 'Upload File';
    onClose?: () => void;
}

const WorkRequestsForm = (props: Interface) => {
    const data: any = props.data || undefined;
    const [loading, setLoading] = useSafeState(false);
    const [form] = Form.useForm();

    const [selectedToShop, setSelectedToShop] = useSafeState<any>();
    const [selectedFromShop, setSelectedFromShop] = useSafeState<any>();

    const [childrenOption, setChildrenOtion] = useSafeState<any>([]);
    const [fromShops, setFromShops] = useSafeState<any>([]);
    const [fromShopCodeSearch, setFromShopCodeSearch] = useSafeState<any>();
    const [fromShopNameSearch, setFromShopNameSearch] = useSafeState<any>();
    const [fromShopCodes, setFromShopCodes] = useSafeState<any>([]);
    const [fromShopNames, setFromShopNames] = useSafeState<any>([]);

    const [toShops, setToShops] = useSafeState<any>([]);
    const [toShopCodes, setToShopCodes] = useSafeState<any>([]);
    const [toShopNames, setToShopNames] = useSafeState<any>([]);
    const [toShopCodeSearch, setToShopCodeSearch] = useSafeState<any>();
    const [toShopNameSearch, setToShopNameSearch] = useSafeState<any>();

    const [fromDeviceBasedOnSerialNumber, setFromDeviceBasedOnSerialNumber] = useSafeState(true);
    const [toDeviceBasedOnSerialNumber, setToDeviceBasedOnSerialNumber] = useSafeState(true);

    const [toDecors, setToDecors] = useSafeState<any>([]);
    const [toDecorNameSearch, setToDecorNameSearch] = useSafeState<any>();

    const [toDeviceTypeSearch, setToDeviceTypeSearch] = useSafeState<any>();
    const [fromDeviceTypeSearch, setFromDeviceTypeSearch] = useSafeState<any>();

    const [selectedDeviceTo, setSelectedDeviceTo] = useSafeState<any>();
    const [selectedDeviceFrom, setSelectedDeviceFrom] = useSafeState<any>();

    const [selectedToDecorName, setSelectedToDecorName] = useSafeState<any>();

    const [selectedDeviceTypeTo, setSelectedDeviceTypeTo] = useSafeState<any>();
    const [selectedDeviceTypeFrom, setSelectedDeviceTypeFrom] = useSafeState<any>();

    const [transportableDevices, setTransportableDevices] = useSafeState<any>([]);
    const [transDistinctDeviceNames, setTransDistinctDeviceNames] = useSafeState<any>([]);
    const [transDeviceSerialSearch, setTransDeviceSerialSearch] = useSafeState<any>();
    const [transDeviceInventorySearch, setTransDeviceInventorySearch] = useSafeState<any>();
    const [transportableDevicesSerial, setTransportableDevicesSerial] = useSafeState<any>([]);
    const [transportableDevicesInventory, setTransportableDevicesInventory] = useSafeState<any>([]);

    const [deviceTypes, setDeviceTypes] = useSafeState<any>([]);

    const [outDevices, setOutDevices] = useSafeState<any>([]);
    const [outDistinctDeviceNames, setOutDistinctDeviceNames] = useSafeState<any>([]);
    const [outDeviceSerialSearch, setOutDeviceSerialSearch] = useSafeState<any>();
    const [outDeviceInventorySearch, setOutDeviceInventorySearch] = useSafeState<any>();
    const [outDevicesSerial, setOutDevicesSerial] = useSafeState<any>([]);
    const [outDevicesInventory, setOutDevicesInventory] = useSafeState<any>([]);

    const [groupableTo, setGroupableTo] = useSafeState(false);
    const [selectedDeviceToChildItems, setSelectedDeviceToChildItems] = useSafeState<Array<string>>([]);

    const [requestStatuses, setRequestStatuses] = useSafeState<optionalType[]>([
        { label: <Trans>REQUIRED</Trans>, value: 'REQUIRED' },
        { label: <Trans>APPROVED</Trans>, value: 'APPROVED' },
        { label: <Trans>DENIED</Trans>, value: 'DENIED' },
        { label: <Trans>COMPLETED</Trans>, value: 'COMPLETED' },
        { label: <Trans>IN_PROGRESS</Trans>, value: 'IN_PROGRESS' },
        { label: <Trans>DELETED</Trans>, value: 'DELETED' },
        { label: <Trans>ARRIVED</Trans>, value: 'ARRIVED' },
        { label: <Trans>LEAVED</Trans>, value: 'LEAVED' },
        { label: <Trans>REPAIR_OFFER_ARRIVED</Trans>, value: 'REPAIR_OFFER_ARRIVED' },
        { label: <Trans>REPAIR_OFFER_APPROVED</Trans>, value: 'REPAIR_OFFER_APPROVED' },
        { label: <Trans>REPAIR_OFFER_DENIED</Trans>, value: 'REPAIR_OFFER_DENIED' },
        { label: <Trans>ACCEPTED_DEADLINE_MODIFIED</Trans>, value: 'ACCEPTED_DEADLINE_MODIFIED' },
        { label: <Trans>MODIFIED</Trans>, value: 'MODIFIED' }
    ]);

    const [requestTypes, setRequestTypes] = useSafeState<optionalType[]>([
        { label: <Trans>DELIVERY</Trans>, value: 'DELIVERY' },
        { label: <Trans>DISMANTLING</Trans>, value: 'DISMANTLING' },
        { label: <Trans>TRANSIT</Trans>, value: 'TRANSIT' },
        { label: <Trans>REPLACEMENT</Trans>, value: 'REPLACEMENT' },
        { label: <Trans>SERVICE</Trans>, value: 'SERVICE' },
    ]);
    const [fileList, setFileList] = useSafeState<Array<any>>([]);

    const [fromShopDisabled, setFromShopDisabled] = useSafeState(true);
    const [toShopDisabled, setToShopDisabled] = useSafeState(true);

    const [deviceFromDisabled, setDeviceFromDisabled] = useSafeState(true);
    const [deviceToDisabled, setDeviceToDisabled] = useSafeState(true);
    const [qtyDisabled, setQtyDisabled] = useSafeState(true);
    const [decorFromIsSameWarningVisible, setDecorFromIsSameWarningVisible] = useSafeState(false);
    const [fromBulkSave, setFromBulkSave] = useSafeState(false);
    const [toBulkSave, setToBulkSave] = useSafeState(false);

    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const [delay, setDelay] = useSafeState(1000);

    const [childrenTo, setChildrenTo] = useSafeState<Array<any>>([]);

    const qrStyle = {
        height: 240,
        width: 320,
    };

    const handleScanFrom = (data: any) => {
        if (data != null) {
            let result = data.split(/\r?\n/);
            form.setFieldsValue({
                deviceRegisterFrom: result[1],
                deviceRegisterFromInventory: result[2],
            });
            {
                qrModalFrom.toggleVisible();
            }
        }
    };

    const handleErrorFrom = (error: any) => {
        console.log(error);
        alert('Hiba történt a scannelés közben!');
    };

    const handleScanTo = (data: any) => {
        if (data != null) {
            let result = data.split(/\r?\n/);
            form.setFieldsValue({
                deviceRegisterTo: result[1],
                deviceRegisterToInventory: result[2],
            });
            {
                qrModalTo.toggleVisible();
            }
        }
    };

    const handleErrorTo = (error: any) => {
        console.log(error);
        alert('Hiba történt a scannelés közben!');
    };

    const qrModalFrom = QrModal({
        title: 'QR Scan',
        children: <QrReader delay={delay} style={qrStyle} onError={handleErrorFrom} onScan={handleScanFrom} />,
    });

    const qrModalTo = QrModal({
        title: 'QR Scan',
        children: <QrReader delay={delay} style={qrStyle} onError={handleErrorTo} onScan={handleScanTo} />,
    });

    useEffect(() => {
        form.resetFields();
        let fieldsValues: any = [];
        if (props.mode === 'Edit' && data !== undefined) {
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({ name: key, value: value });
            }
        } else if (props.mode === 'Add new') {
            fieldsValues = [{ name: 'requestStatus', value: 'REQUIRED' }];
        }
        fieldsValues.push({ name: 'qty', value: 1 });

        form.setFields(fieldsValues);
        handleRequestTypeOnChange(form.getFieldValue('requestType'));
    }, []);

    useEffect(() => {
        getShops(fromShopCodeSearch, fromShopNameSearch, true);
    }, [fromShopCodeSearch, fromShopNameSearch]);
    useEffect(() => {
        getShops(toShopCodeSearch, toShopNameSearch, false);
    }, [toShopCodeSearch, toShopNameSearch]);

    useEffect(() => {
        getDeviceRegister(
            outDeviceSerialSearch,
            outDeviceInventorySearch,
            'OUTSOURCED',
            selectedFromShop,
            selectedDeviceTypeFrom,
            '',
        );
    }, [outDeviceSerialSearch, outDeviceInventorySearch, selectedFromShop, selectedDeviceTypeFrom]);

    useEffect(() => {
        getDeviceRegister(
            transDeviceSerialSearch,
            transDeviceInventorySearch,
            'TRANSPORTABLE',
            '',
            selectedDeviceTypeTo,
            selectedToDecorName,
        );
    }, [transDeviceSerialSearch, transDeviceInventorySearch, selectedDeviceTypeTo, selectedToDecorName]);

    useEffect(() => {
        createFromAddressElement(selectedFromShop),
            createFromRepresentativeElement(selectedFromShop),
            setFromShopSelections(selectedFromShop);
    }, [selectedFromShop]);

    useEffect(() => {
        createAddressElement(selectedToShop),
            createRepresentativeElement(selectedToShop),
            setToShopSelections(selectedToShop);
    }, [selectedToShop]);

    useEffect(() => {
        getDeviceTypes('OUTSOURCED', fromDeviceTypeSearch, selectedFromShop);
    }, [selectedFromShop, fromDeviceTypeSearch]);

    useEffect(() => {
        getDeviceTypes('TRANSPORTABLE', toDeviceTypeSearch, null);
    }, [toDeviceTypeSearch]);

    const jsonConcat = (o1: any, o2: any) => {
        for (var key in o2) {
            o1[key] = o2[key];
        }
        return o1;
    };

    const getShops = (code: any, name: any, isFrom?: boolean) => {
        if (code !== '' || name !== '') {
            if (fcrAxiosInstance.current != null) {
                let shopQuery = code ? { code: code } : { name: name };

                let sQ = jsonConcat({}, shopQuery);

                fcrAxiosInstance.current
                    .post('/resource/shops/page', {
                        page: 0,
                        size: 50,
                        direction: 'asc',
                        sorted_field: 'code',
                        query: sQ,
                    })
                    .then(function (response: AxiosResponse<any>) {
                        if (isFrom != null && isFrom == true) {
                            setFromShops(response.data.content);
                            setFromShopCodes(generateOptions(response.data.content, 'code', 'code'));
                            setFromShopNames(generateOptions(response.data.content, 'name', 'name'));
                        } else if (isFrom != null && isFrom == false) {
                            setToShops(response.data.content);
                            setToShopCodes(generateOptions(response.data.content, 'code', 'code'));
                            setToShopNames(generateOptions(response.data.content, 'name', 'name'));
                        } else {
                            setFromShops(response.data.content);
                            setToShops(response.data.content);
                            setFromShopCodes(generateOptions(response.data.content, 'code', 'code'));
                            setFromShopNames(generateOptions(response.data.content, 'name', 'name'));
                            setToShopCodes(generateOptions(response.data.content, 'code', 'code'));
                            setToShopNames(generateOptions(response.data.content, 'name', 'name'));
                        }
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    };

    const setSelectedDeviceTypeStart = (value: any) => {
        setSelectedDeviceTypeFrom(value);
        form.resetFields(['deviceRegisterFrom', 'deviceRegisterFromInventory']);
        setSelectedDeviceFrom({
            purchasingDate: '-',
        });

        getOutsourcedQty(value);

        for(let i = 0; i < deviceTypes.length; i++){

            if(deviceTypes[i]['deviceType']['name'] == value) {
                setFromDeviceBasedOnSerialNumber(deviceTypes[i]['deviceType']['methodOfRegistration'] == 'BASED_ON_SERIAL_NUMBER');
                return;
            }
        }
    };

    const handleChangeSelectedToDecorName = (value: any) => {
        setSelectedToDecorName(value);
        form.resetFields(['deviceRegisterTo', 'deviceRegisterToInventory']);
        setSelectedDeviceTo({
            purchasingDate: '-',
        });
    };

    const setSelectedDeviceTypeTarget = (value: any) => {
        setSelectedDeviceTypeTo(value);
        form.resetFields(['decorTo', 'deviceRegisterTo', 'deviceRegisterToInventory', 'qty', 'remark']);
        setSelectedDeviceTo({
            name: '-',
            purchasingDate: '-',
        });

        fillDeviceToChildrenOptions();

        for(let i = 0; i < deviceTypes.length; i++){

            if(deviceTypes[i]['deviceType']['name'] == value) {
                setToDeviceBasedOnSerialNumber(deviceTypes[i]['deviceType']['methodOfRegistration'] == 'BASED_ON_SERIAL_NUMBER');
                return;
            }
        }
    };

    const getOutsourcedQty = (device: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .get(
                    (
                        '/resource/work-requests/get-qty-by-shop-and-device?shop=' +
                        encodeURIComponent(form.getFieldValue(['shopFrom']))
                        + "&device=" +
                        encodeURIComponent(device)
                    ),
                )
                .then(res => {
                    form.setFieldsValue({ fromDeviceQty: res.data });
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };

    const fillDeviceToChildrenOptions = () => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .get(
                    (
                        '/resource/device-types/get-all-transportable-children-by-parent-name?parentName=' +
                        encodeURIComponent(form.getFieldValue(['deviceTo']))
                    ),
                )
                .then(res => {
                    setChildrenOtion(generateChildrenOptions(res.data))
                    //setChildrenTo(res.data);
                    setGroupableTo(res.data.length > 0);
                })
                .catch(e => {
                    console.log(e);
                    setGroupableTo(false);
                });
        }
    };

    const getDeviceTypes = (deviceStatus: string, deviceTypeName: string, shop: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .get(
                    '/resource/device-register/distinct-list?deviceStatus=' +
                        deviceStatus +
                        '&deviceTypeName=' +
                        (deviceTypeName ? deviceTypeName : '') +
                        '&pageSize=50&shopCode=' +
                        (shop ? encodeURIComponent(shop['code']) : ''),
                )
                .then(function (response: AxiosResponse<any>) {

                    setDeviceTypes(response.data);
                    if (deviceStatus == 'OUTSOURCED') {
                        setOutDistinctDeviceNames(generateDeviceTypeOptions(response.data, 'name', 'name'));
                    }

                    if (deviceStatus == 'TRANSPORTABLE') {
                        setTransDistinctDeviceNames(generateDeviceTypeOptions(response.data, 'name', 'name'));
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    };

    const getDeviceRegister = (
        serial: any,
        inventory: any,
        status: string,
        shop: any,
        selectedDevType: any,
        decorName: any,
    ) => {
        if (serial || inventory || status || shop || selectedDevType) {
            if (fcrAxiosInstance.current != null) {
                let queryJson: string = '{';
                queryJson += serial ? '"serialNumber": "' + serial + '", ' : '';
                queryJson += inventory ? '"inventoryNumber": "' + inventory + '", ' : '';
                queryJson += status ? '"deviceStatus": "' + status + '", ' : '';
                queryJson += selectedDevType ? '"deviceType": { "name": "' + selectedDevType + '"} , ' : '';
                queryJson += shop ? '"shop": { "code": "' + shop['code'] + '"} , ' : '';
                queryJson += decorName ? '"decor": { "name": "' + decorName + '"} , ' : '';
                queryJson += '}';

                if (queryJson.length > 2) {
                    queryJson =
                        queryJson.substr(0, queryJson.length - 3) +
                        queryJson.substr(queryJson.length - 2, queryJson.length);
                }

                fcrAxiosInstance.current
                    .post('/resource/device-register/page', {
                        page: 0,
                        size: 200,
                        direction: 'asc',
                        sorted_field: 'deviceType.name',
                        query: JSON.parse(queryJson),
                    })
                    .then(function (response: AxiosResponse<any>) {
                        if (status === 'OUTSOURCED') {

                            setOutDevices(response.data.content);

                            setOutDevicesSerial(generateOptions(response.data.content, 'serialNumber', 'serialNumber'));

                            setOutDevicesInventory(
                                generateOptions(response.data.content, 'inventoryNumber', 'inventoryNumber'),
                            );

                        } else if (status === 'TRANSPORTABLE') {
                            setTransportableDevices(response.data.content);

                            setTransportableDevicesSerial(
                                generateOptions(response.data.content, 'serialNumber', 'serialNumber'),
                            );

                            setTransportableDevicesInventory(
                                generateOptions(response.data.content, 'inventoryNumber', 'inventoryNumber'),
                            );

                            setToDecors(generateDecorOptions(response.data.content, 'name', 'name'));
                        }
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    };

    type optionalType = {
        key?: number;
        label: any;
        value: string;
    };

    const generateOptions = (data: any, label: string, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { key: i, value: data[i][val], label: data[i][label] };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const generateDeviceTypeOptions = (data: any, label: string, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = {
                key: i,
                value: data[i]['deviceType'][val],
                label: data[i]['deviceType'][label],
            };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    const generateDecorOptions = (data: any, label: string, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            if (data[i]['decor'] == null) {
                continue;
            }

            let currentItem: optionalType = { key: i, value: data[i]['decor'][val], label: data[i]['decor'][label] };
            if (!(arrayToOptions.filter(e => e.value === currentItem['value']).length > 0)) {
                arrayToOptions.push(currentItem);
            }
        }
        return arrayToOptions;
    };

    const onReset = (data: any) => {
        setSelectedFromShop(null);
        setSelectedToShop(null);
        let reqType = form.getFieldValue('requestType');
        let reqStat = form.getFieldValue('requestStatus');
        form.resetFields();
        form.setFieldsValue({ requestType: reqType });
        form.setFieldsValue({ requestStatus: reqStat });
    };

    const config = { headers: { 'Content-Type': 'application/json' } };

    const handleEdit = () => {
        setLoading(true);
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .put(
                    '/resource/work-requests/update/id?id=' + form.getFieldValue(['id']),
                    {
                        id: form.getFieldValue(['id']),
                        requestStatus: form.getFieldValue(['requestStatus']),
                        requestType: form.getFieldValue(['requestType']),

                        shopFrom: form.getFieldValue(['shopFrom']) ? { code: form.getFieldValue(['shopFrom']) } : null,
                        shopTo: form.getFieldValue(['shopTo']) ? { code: form.getFieldValue(['shopTo']) } : null,
                        deviceRegisterFrom: form.getFieldValue(['deviceFrom'])
                            ? {
                                deviceType: { name: form.getFieldValue(['deviceFrom']) },
                                serialNumber: form.getFieldValue(['deviceRegisterFrom'])
                            }
                            : null,
                        deviceRegisterTo: form.getFieldValue(['deviceTo'])
                            ? {
                                deviceType: { name: form.getFieldValue(['deviceTo']) },
                                serialNumber: form.getFieldValue(['deviceRegisterTo'])
                            }
                            : null,
                        decorFrom:
                            decorFromIsSameWarningVisible || !selectedDeviceFrom
                                ? null
                                : selectedDeviceFrom['decor'] && selectedDeviceFrom['decor']['name'] != ''
                                ? { name: selectedDeviceFrom['decor']['name'] }
                                : null,
                        decorTo: form.getFieldValue(['decorTo']) ? { name: form.getFieldValue(['decorTo']) } : null,

                        qty: 1,
                        fromDeviceQty: form.getFieldValue(['fromDeviceQty']),
                        toDeviceQty: form.getFieldValue(['toDeviceQty']),
                        remark: form.getFieldValue(['remark']),
                        auroraNumber: form.getFieldValue(['auroraNumber']),
                        primeSolsNumber: form.getFieldValue(['primeSolsNumber']),
                        documentsUrl: form.getFieldValue(['documentsUrl']),
                        version: form.getFieldValue(['version']),
                        projectId: form.getFieldValue(['projectId']),
                        requestedDate:
                            form.getFieldValue(['requestedDate']) != null
                                ? moment(form.getFieldValue(['requestedDate'])).format('YYYY-MM-DD')
                                : null,
                        acceptedDate:
                            form.getFieldValue(['acceptedDate']) != null
                                ? moment(form.getFieldValue(['acceptedDate'])).format('YYYY-MM-DD')
                                : null,
                        completedDate:
                            form.getFieldValue(['completedDate']) != null
                                ? moment(form.getFieldValue(['completedDate'])).format('YYYY-MM-DD')
                                : null,

                    },
                    config,
                )
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const handleAddNew = () => {
        setLoading(true);
        if (fcrAxiosInstance.current != null) {
            let url = '/resource/work-requests/bulk-save';
            if (fromBulkSave && toBulkSave) {
                url = '/resource/work-requests/bulk-both';
            } else if (fromBulkSave) {
                url = '/resource/work-requests/bulk-from';
            } else if (toBulkSave) {
                url = '/resource/work-requests/bulk-to';
            }

            fcrAxiosInstance.current
                .post(
                    url,
                    {
                        workRequest: {
                            requestStatus: form.getFieldValue(['requestStatus']),
                            requestType: form.getFieldValue(['requestType']),

                            shopFrom: form.getFieldValue(['shopFrom'])
                                ? { code: form.getFieldValue(['shopFrom']) }
                                : null,
                            shopTo: form.getFieldValue(['shopTo']) ? { code: form.getFieldValue(['shopTo']) } : null,
                            deviceRegisterFrom: form.getFieldValue(['deviceFrom'])
                                ? {
                                    deviceType: { name: form.getFieldValue(['deviceFrom']) },
                                    serialNumber: form.getFieldValue(['deviceRegisterFrom'])
                                }
                                : null,
                            deviceRegisterTo: form.getFieldValue(['deviceTo'])
                                ? {
                                    deviceType: { name: form.getFieldValue(['deviceTo']) },
                                    serialNumber: form.getFieldValue(['deviceRegisterTo'])
                                }
                                : null,
                            decorFrom:
                                decorFromIsSameWarningVisible || !selectedDeviceFrom
                                    ? null
                                    : selectedDeviceFrom['decor'] && selectedDeviceFrom['decor']['name'] != ''
                                    ? { name: selectedDeviceFrom['decor']['name'] }
                                    : null,
                            decorTo: form.getFieldValue(['decorTo']) ? { name: form.getFieldValue(['decorTo']) } : null,

                            qty: 1,
                            fromDeviceQty: form.getFieldValue(['fromDeviceQty']),
                            toDeviceQty: form.getFieldValue(['toDeviceQty']),
                            remark: form.getFieldValue(['remark']),
                            requestedDate:
                                form.getFieldValue(['requestedDate']) != null
                                    ? moment(form.getFieldValue(['requestedDate'])).format('YYYY-MM-DD')
                                    : null,
                        },
                        childDevices: selectedDeviceToChildItems,
                    },
                    config,
                )
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const handleUploadCsv = () => {
        setLoading(true);
        if (fcrAxiosInstance.current != null) {
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });

            let uploadUrl = '';
            switch(form.getFieldValue(['csvRequestType'])){
                case 'DELIVERY': {
                    uploadUrl = 'upload-delivery-csv';
                    break;
                }
                case 'DISMANTLING': {
                    uploadUrl = 'upload-dismantling-csv';
                    break;
                }
                case 'TRANSIT': {
                    uploadUrl = 'upload-transit-csv';
                    break;
                }
                case 'REPLACEMENT': {
                    uploadUrl = 'upload-replacement-csv';
                    break;
                }
                case 'SERVICE': {
                    uploadUrl = 'upload-service-csv';
                    break;
                }
            }

            fcrAxiosInstance.current
                .post(
                    '/resource/work-requests/' + uploadUrl,
                    formData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data'
                    },
                    timeout: 600000
                })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const uploadProps = {
        name: 'pic',
        //action: process.env.REACT_APP_API_BASE_URL + '/resource/work-requests/upload-csv',
        multiple: false,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading');
            }
            if (status === 'done') {
                openNotification('customSuccess', `${info.file.name} "file uploaded successfully."`);
            } else if (status === 'error') {
                const isCSV = info.file.type === 'text/csv';
                if (isCSV) {
                    openNotification('customError', `${info.file.name} "file upload failed."`);
                } else {
                    openNotification('customError', `${info.file.name}  is not a .csv file`);
                }
            }
        },
        beforeUpload(file: any) {

            const isCSV = file.type === 'text/csv';
            if (!isCSV) {
                return Upload.LIST_IGNORE;
            } else {
                let fileList2: Array<any> = fileList;
                fileList2.push(file);
                setFileList(fileList2);
                return false;
            }
        },
    };

    const handleToShopOnChange = (value: any) => {
        for (var i = 0; i < toShops.length; i++) {
            if (toShops[i]['code'] == value || toShops[i]['name'] == value) {
                setSelectedToShop(toShops[i]);
                break;
            }
        }
    };

    const setToShopSelections = (value: any) => {
        if (value != null) {
            form.setFieldsValue({ shopTo: value['code'] });
            form.setFieldsValue({ shopToName: value['name'] });
        }
    };

    const handleFromShopOnChange = (value: any) => {
        for (var i = 0; i < fromShops.length; i++) {
            if (fromShops[i]['code'] == value || fromShops[i]['name'] == value) {
                setSelectedFromShop(fromShops[i]);
                break;
            }
        }
    };

    const setFromShopSelections = (value: any) => {
        if (value != null) {
            form.setFieldsValue({ shopFrom: value['code'] });
            form.setFieldsValue({ shopFromName: value['name'] });
        }
    };

    const handleTransDeviceToOnChange = (value: any) => {
        let actDevice: any;
        for (var i = 0; i < transportableDevices.length; i++) {
            if (
                transportableDevices[i]['serialNumber'] == value ||
                transportableDevices[i]['inventoryNumber'] == value
            ) {
                actDevice = transportableDevices[i];
                setSelectedDeviceTo(actDevice);
                break;
            }
        }

        if (actDevice) {
            form.setFieldsValue({ deviceRegisterTo: actDevice['serialNumber'] });
            form.setFieldsValue({ deviceRegisterToInventory: actDevice['inventoryNumber'] });
        }
    };

    const handleOutDeviceToOnChange = (value: any) => {
        let actDevice: any;
        for (var i = 0; i < outDevices.length; i++) {
            if (outDevices[i]['serialNumber'] == value || outDevices[i]['inventoryNumber'] == value) {
                actDevice = outDevices[i];
                setSelectedDeviceFrom(actDevice);
                break;
            }
        }

        if (actDevice) {
            form.setFieldsValue({ deviceRegisterFrom: actDevice['serialNumber'] });
            form.setFieldsValue({ deviceRegisterFromInventory: actDevice['inventoryNumber'] });
        }
    };

    const handleRequestTypeOnChange = (value: any) => {
        setDecorFromIsSameWarningVisible(false);
        switch (value) {
            case 'DISMANTLING':
            case 'SERVICE': {
                setDismantlingOrServiceVisibility();
                break;
            }
            case 'DELIVERY': {
                setDeliveryVisibility();
                break;
            }
            case 'TRANSIT': {
                setTransitVisibility();
                break;
            }
            case 'REPLACEMENT': {
                setReplacementVisibility();
                break;
            }

            default:
                setAllDisabled();
                break;
        }
    };

    const setAllDisabled = () => {
        setFromShopDisabled(true);
        setToShopDisabled(true);
        setDeviceFromDisabled(true);
        setDeviceToDisabled(true);
    };

    const setDismantlingOrServiceVisibility = () => {
        setFromShopDisabled(false);
        setToShopDisabled(true);
        setDeviceFromDisabled(false);
        setDeviceToDisabled(true);
    };

    const setDeliveryVisibility = () => {
        setFromShopDisabled(true);
        setToShopDisabled(false);
        setDeviceFromDisabled(true);
        setDeviceToDisabled(false);
    };

    const setTransitVisibility = () => {
        setFromShopDisabled(false);
        setToShopDisabled(false);
        setDeviceFromDisabled(false);
        setDeviceToDisabled(true);
    };

    const setReplacementVisibility = () => {
        setFromShopDisabled(false);
        setToShopDisabled(true);
        setDeviceFromDisabled(false);
        setDeviceToDisabled(false);
    };

    const createAddressElement = (shop: any) => {
        let shopAddress = shop ? shop['address'] : data && data['shopToAddress'] ? data['shopToAddress'] : null;

        if (!shopAddress) {
            return (
                <>
                    <div>
                        <b>
                            <Trans>Bolt címe</Trans>:
                        </b>{' '}
                    </div>
                    <span>-</span>
                </>
            );
        } else {
            return (
                <>
                    <div>
                        <b>
                            <Trans>Bolt címe</Trans>:
                        </b>{' '}
                    </div>
                    <span>
                        {shopAddress['county']}, {shopAddress['postalCode']}, {shopAddress['city']},{' '}
                        {shopAddress['streetHouseNumber']}
                    </span>
                </>
            );
        }
    };

    const createRepresentativeElement = (shop: any) => {
        let shopRepresentativeName =
            shop && shop['regionalRepresentative']
                ? shop['regionalRepresentative']['name']
                : data && data['shopToRepresentative']
                ? data['shopToRepresentative']
                : null;

        if (shopRepresentativeName) {
            return (
                <>
                    <div>
                        <b>
                            <Trans>Képviselő</Trans>:
                        </b>
                    </div>
                    <span>{shopRepresentativeName}</span>
                </>
            );
        } else {
            return (
                <>
                    <span>
                        <b>
                            <Trans>Képviselő</Trans>:
                        </b>
                    </span>
                    <div>-</div>
                </>
            );
        }
    };

    const createFromAddressElement = (shop: any) => {
        let shopAddress = shop ? shop['address'] : data && data['shopFromAddress'] ? data['shopFromAddress'] : null;
        if (!shopAddress) {
            return (
                <>
                    <div>
                        <b>
                            <Trans>Bolt címe</Trans>:
                        </b>{' '}
                    </div>
                    <div>-</div>
                </>
            );
        } else {
            return (
                <>
                    <div>
                        <b>
                            <Trans>Bolt címe</Trans>:
                        </b>{' '}
                    </div>
                    <span>
                        {shopAddress['county']}, {shopAddress['postalCode']}, {shopAddress['city']},{' '}
                        {shopAddress['streetHouseNumber']}
                    </span>
                </>
            );
        }
    };

    const createFromRepresentativeElement = (shop: any) => {
        let shopRepresentativeName =
            shop && shop['regionalRepresentative']
                ? shop['regionalRepresentative']['name']
                : data && data['shopFromRepresentative']
                ? data['shopFromRepresentative']
                : null;

        if (shopRepresentativeName) {
            return (
                <>
                    <div>
                        <b>
                            <Trans>Képviselő</Trans>:
                        </b>
                    </div>
                    <span>{shopRepresentativeName}</span>
                </>
            );
        } else {
            return (
                <>
                    <span>
                        <b>
                            <Trans>Képviselő</Trans>:
                        </b>
                    </span>
                    <div>-</div>
                </>
            );
        }
    };

    const createDeviceRegisterFromDecorField = () => {
        if (selectedDeviceFrom) {
            return (
                <>
                    {selectedDeviceFrom['decor'] && selectedDeviceFrom['decor']['name'] != ''
                        ? selectedDeviceFrom['decor']['name']
                        : '-'}
                </>
            );
        } else {
            return <>{data && data['deviceRegisterFromDecor'] ? data['deviceRegisterFromDecor'] : '-'}</>;
        }
    };

    const createDeviceRegisterFromPurchaseDateField = () => {
        if (selectedDeviceFrom) {
            return <>{selectedDeviceFrom['purchasingDate'] != '' ? selectedDeviceFrom['purchasingDate'] : '-'}</>;
        } else {
            return (
                <>{data && data['deviceRegisterFromPurchasingDate'] ? data['deviceRegisterFromPurchasingDate'] : '-'}</>
            );
        }
    };

    const createDeviceRegisterToPurchaseDateField = () => {
        if (selectedDeviceTo) {
            return <>{selectedDeviceTo['purchasingDate'] != '' ? selectedDeviceTo['purchasingDate'] : '-'}</>;
        } else {
            return <>{data && data['deviceRegisterToPurchasingDate'] ? data['deviceRegisterToPurchasingDate'] : '-'}</>;
        }
    };

    const decorFromIsSameChanged = (value: any) => {
        setDecorFromIsSameWarningVisible(!value);
    };

    const fromBulkSaveChanged = (value: any) => {
        setFromBulkSave(value);
        if (form.getFieldValue(['requestType']) == 'REPLACEMENT') {
            setToBulkSave(value);
        }
    };

    const handleChildrenChange = (selected: any) => {
        setSelectedDeviceToChildItems(selected);
        setToBulkSave(selected.length > 0);
    };

    const generateChildrenOptions = (data: any): optionalType[] => {

        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = {
                value: data[i]['name'],
                label: data[i]['name'],
            };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    return (
        <Form
            id={props.mode}
            form={form}
            layout="vertical"
            onFinish={() => (props.mode === 'Edit' ? handleEdit() :
                props.mode === 'Add new' ? handleAddNew() : handleUploadCsv())}
        >
            {props.mode === 'Edit' || props.mode === 'Add new' ? (
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="requestType"
                                label={<Trans>Request Type</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={false}
                                        onChange={value => {
                                            handleRequestTypeOnChange(value);
                                            onReset(data);
                                        }}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a type</Trans>}
                                        options={requestTypes}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: <Trans>Please enter a type</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="requestStatus"
                                label={<Trans>Request Status</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        disabled={true}
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a status</Trans>}
                                        options={requestStatuses}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: <Trans>Please enter a status</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Card className={fromShopDisabled ? '' : 'activeCard'} style={{ backgroundColor: '#f0f5ff' }}>
                        <Divider orientation="left" style={{ marginTop: 0, fontSize: 'larger' }}>
                            <Trans>Kiinduló</Trans>
                        </Divider>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    name="shopFrom"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Bolt kód</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={fromShopDisabled}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Shop Code</Trans>}
                                            onSearch={value => setFromShopCodeSearch(value)}
                                            onChange={value => handleFromShopOnChange(value)}
                                            filterOption={false}
                                            options={fromShopCodes}
                                        />
                                    }
                                    rules={[
                                        {
                                            required: !fromShopDisabled,
                                            message: <Trans>Please enter a shop</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={18}>
                                <Form.Item
                                    name="shopFromName"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Bolt neve</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={fromShopDisabled}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Shop Name</Trans>}
                                            onSearch={value => setFromShopNameSearch(value)}
                                            onChange={value => handleFromShopOnChange(value)}
                                            filterOption={false}
                                            options={fromShopNames}
                                        />
                                    }
                                    rules={[
                                        {
                                            required: !fromShopDisabled,
                                            message: <Trans>Please enter a shop</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={16}>
                                <Form.Item
                                    name="shopFromAddress"
                                    label={''}
                                    children={createFromAddressElement(selectedFromShop)}
                                />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="shopFromRepresentative"
                                    label={''}
                                    children={createFromRepresentativeElement(selectedFromShop)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={10}>
                                <Form.Item
                                    name="deviceFrom"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Device Type</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={deviceFromDisabled}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Select a device</Trans>}
                                            onSearch={value => setFromDeviceTypeSearch(value)}
                                            onChange={value => setSelectedDeviceTypeStart(value)}
                                            options={outDistinctDeviceNames}
                                        />
                                    }
                                    rules={[
                                        {
                                            type: 'string',
                                            required: !deviceFromDisabled,
                                            message: <Trans>Please enter a device type</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="deviceRegisterFrom"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Serial Number</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={deviceFromDisabled || !fromDeviceBasedOnSerialNumber}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Serial Number</Trans>}
                                            onSearch={value => setOutDeviceSerialSearch(value)}
                                            onChange={value => handleOutDeviceToOnChange(value)}
                                            options={outDevicesSerial}
                                        />
                                    }
                                    rules={[
                                        {
                                            required: !deviceFromDisabled && fromDeviceBasedOnSerialNumber,
                                            message: <Trans>Please select a serial number</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="deviceRegisterFromInventory"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Inventory Number</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={deviceFromDisabled || !fromDeviceBasedOnSerialNumber}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Inventory Number</Trans>}
                                            onSearch={value => setOutDeviceInventorySearch(value)}
                                            onChange={value => handleOutDeviceToOnChange(value)}
                                            options={outDevicesInventory}
                                        />
                                    }
                                    rules={[
                                        {
                                            required: !deviceFromDisabled && fromDeviceBasedOnSerialNumber,
                                            message: <Trans>Please select a inventory number</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    name="fromDeviceQty"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Quantity</Trans>
                                            </b>
                                        </>
                                    }
                                    children={<Input type={"number"} min={0} defaultValue={0} disabled={deviceFromDisabled || fromDeviceBasedOnSerialNumber} />}
                                    rules={[
                                        {
                                            required: !deviceFromDisabled && !fromDeviceBasedOnSerialNumber,
                                            message: <Trans>Please add quantity</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="deviceRegisterFromPurchasingDate"
                                    label={
                                        <b>
                                            <Trans>Purchasing Date</Trans>:
                                        </b>
                                    }
                                    children={createDeviceRegisterFromPurchaseDateField()}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="deviceRegisterFromDecor"
                                    label={
                                        <b>
                                            <Trans>Decor</Trans>:
                                        </b>
                                    }
                                    children={createDeviceRegisterFromDecorField()}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="decorFromIsSame"
                                    valuePropName="checked"
                                    label={
                                        <b>
                                            <Trans>Egyezik a dekor?</Trans>*
                                        </b>
                                    }
                                    children={
                                        <Switch
                                            disabled={deviceFromDisabled}
                                            defaultChecked={true}
                                            onChange={checked => decorFromIsSameChanged(checked)}
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <b>
                                    <Trans>Teljes eszköz csoportra értelmezett a művelet?</Trans>
                                </b>
                            </Col>
                        </Row>
                        <Row gutter={24} style={{ marginTop: 10 }}>
                            <Col span={24}>
                                <Switch
                                    disabled={deviceFromDisabled}
                                    defaultChecked={false}
                                    checked={fromBulkSave}
                                    onChange={checked => fromBulkSaveChanged(checked)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} hidden={!decorFromIsSameWarningVisible}>
                                <Paragraph style={{ fontStyle: 'italic' }}>
                                    *Kérlek a megjegyzés rovatban add meg a jelenlegi dekort!
                                </Paragraph>
                            </Col>
                            <Col span={24} hidden={form.getFieldValue(['requestType']) != 'SERVICE'}>
                                <Paragraph style={{ fontStyle: 'italic' }}>
                                    *Kérlek a megjegyzés rovatban add meg a hiba leírást!
                                </Paragraph>
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    <Card
                        className={toShopDisabled && deviceToDisabled ? '' : 'activeCard'}
                        style={{ backgroundColor: '#fffbe6' }}
                    >
                        <Divider orientation="left" style={{ marginTop: 0, fontSize: 'larger' }}>
                            <Trans>Cél</Trans>
                        </Divider>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    name="shopTo"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Bolt kód</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={toShopDisabled}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Shop Code</Trans>}
                                            onSearch={value => setToShopCodeSearch(value)}
                                            onChange={value => handleToShopOnChange(value)}
                                            filterOption={false}
                                            options={toShopCodes}
                                        />
                                    }
                                    rules={[
                                        {
                                            required: !toShopDisabled,
                                            message: <Trans>Please enter a shop</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={18}>
                                <Form.Item
                                    name="shopToName"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Bolt neve</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={toShopDisabled}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Shop Name</Trans>}
                                            onSearch={value => setToShopNameSearch(value)}
                                            onChange={value => handleToShopOnChange(value)}
                                            filterOption={false}
                                            options={toShopNames}
                                        />
                                    }
                                    rules={[
                                        {
                                            required: !toShopDisabled,
                                            message: <Trans>Please enter a shop</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={16}>
                                <Form.Item
                                    name="shopToAddress"
                                    label={''}
                                    children={createAddressElement(selectedToShop)}
                                />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="shopToRepresentative"
                                    label={''}
                                    children={createRepresentativeElement(selectedToShop)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="deviceTo"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Device Type</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={deviceToDisabled}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Select a device</Trans>}
                                            onSearch={value => setToDeviceTypeSearch(value)}
                                            onChange={value => setSelectedDeviceTypeTarget(value)}
                                            options={transDistinctDeviceNames}
                                        />
                                    }
                                    rules={[
                                        {
                                            type: 'string',
                                            required: !deviceToDisabled,
                                            message: <Trans>Please enter a device type</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="decorTo"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Decor</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={deviceToDisabled || !toDeviceBasedOnSerialNumber}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Select a decor</Trans>}
                                            options={toDecors}
                                            onSearch={value => setToDecorNameSearch(value)}
                                            onChange={value => handleChangeSelectedToDecorName(value)}
                                        />
                                    }
                                    rules={[
                                        {
                                            type: 'string',
                                            required: !deviceToDisabled && toDeviceBasedOnSerialNumber,
                                            message: <Trans>Please enter a decor</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    name="deviceRegisterTo"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Serial Number</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={deviceToDisabled || !toDeviceBasedOnSerialNumber}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Serial Number</Trans>}
                                            onSearch={value => setTransDeviceSerialSearch(value)}
                                            onChange={value => handleTransDeviceToOnChange(value)}
                                            options={transportableDevicesSerial}
                                        />
                                    }
                                    rules={[
                                        {
                                            required: !deviceToDisabled && toDeviceBasedOnSerialNumber,
                                            message: <Trans>Please select a serial number</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="deviceRegisterToInventory"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Inventory Number</Trans>
                                            </b>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <span>
                                                        A lista maximum 50 elemet jelenít meg, és a tartalma a beírt
                                                        szöveg alapján módosul.
                                                    </span>
                                                }
                                                children={
                                                    <InfoCircleTwoTone
                                                        twoToneColor="#1890ff"
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                }
                                            />
                                        </>
                                    }
                                    children={
                                        <Select
                                            allowClear
                                            disabled={deviceToDisabled || !toDeviceBasedOnSerialNumber}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder={<Trans>Inventory Number</Trans>}
                                            onSearch={value => setTransDeviceInventorySearch(value)}
                                            onChange={value => handleTransDeviceToOnChange(value)}
                                            options={transportableDevicesInventory}
                                        />
                                    }
                                    rules={[
                                        {
                                            required: !deviceToDisabled && toDeviceBasedOnSerialNumber,
                                            message: <Trans>Please select a inventory number</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="toDeviceQty"
                                    label={
                                        <>
                                            <b>
                                                <Trans>Quantity</Trans>
                                            </b>
                                        </>
                                    }
                                    children={<Input type={"number"} min={1} defaultValue={1} disabled={deviceToDisabled || toDeviceBasedOnSerialNumber} />}
                                    rules={[
                                        {
                                            required: !deviceToDisabled && !toDeviceBasedOnSerialNumber,
                                            message: <Trans>Please add quantity</Trans>,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="deviceRegisterToPurchasingDate"
                                    label={
                                        <b>
                                            <Trans>Purchasing Date</Trans>:
                                        </b>
                                    }
                                    children={createDeviceRegisterToPurchaseDateField()}
                                />
                            </Col>
                        </Row>
                        {groupableTo ? (
                            <Divider orientation="left" style={{ marginTop: 0, fontSize: 'larger' }}>
                                Eszköz csoport
                            </Divider>
                        ) : (
                            <></>
                        )}
                        <Row gutter={24} hidden={!groupableTo}>
                            <Col span={24}>
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder={<Trans>Eszköz</Trans>}
                                    onChange={handleChildrenChange}
                                    options={childrenOption}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                name="qty"
                                hidden={true}
                                label={<Trans>Menny. (db) </Trans>}
                                children={<Input disabled={qtyDisabled} type={'number'} min={1} />}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="requestedDate"
                                label={<Trans>Requested Deadline</Trans>}
                                children={<DatePicker locale={locale} format={'YYYY-MM-DD'} placeholder={i18n._('dateFormat')}  />}
                                rules={[
                                    {
                                        type: 'date',
                                        required: true,
                                        message: <Trans>Please select a date</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="remark"
                                label={<Trans>Remark</Trans>}
                                children={<Input type={'text'} />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?!\s+$)[A-Za-z0-9áéíóöőúüűÁÉÍÓÖŐÚÜŰ_@!.:/\\#&+-,\s-]+$/),
                                        ///^[ A-Za-z0-9_@./#&+-]*$/
                                        min: 6,
                                        required:
                                            decorFromIsSameWarningVisible ||
                                            form.getFieldValue(['requestType']) == 'SERVICE',
                                        message: <Trans>Please write min. 6 character remark</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="csvRequestType"
                                label={<Trans>CSV Request Type</Trans>}
                                children={
                                    <Select
                                        allowClear
                                        showSearch={false}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select a type</Trans>}
                                        options={requestTypes}
                                    />
                                }
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: <Trans>Please enter a type</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            <Trans>Click or drag file to this area to upload</Trans>
                        </p>
                        <p className="ant-upload-hint">
                            <Trans>
                                Support for a single upload. Strictly prohibit from uploading company data or other band
                                files
                            </Trans>
                        </p>
                    </Dragger>
                    <br />
                </>
            )}

            {props.mode === 'Edit' ? (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
            ) : props.mode === 'Add new' ? (
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() => onReset(data)}>
                        <Trans>Vissza</Trans>
                    </Button>
                    <Button
                        loading={loading}
                        htmlType="button"
                        type="primary"
                        style={{ marginRight: 8 }}
                        onClick={() => form.submit()}
                    >
                        <Trans>Mentés</Trans>
                    </Button>
                </>
            ) : (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Feltöltés</Trans>
                </Button>
            )}
        </Form>
    );
};
export default WorkRequestsForm;
