import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { FolderViewOutlined } from '@ant-design/icons/lib';

const ElixirFileContentModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();
    columns.push({
        title: <Trans>Csatorna</Trans>,
        sorter: true,
        key: 'partnerErtCsatorna',
        width: 200,
        direction: 'asc',
    });
    columns.push({ title: <Trans>Csatorna Neve</Trans>, sorter: true, key: 'ertCsatornaNeve', width: 200 });
    columns.push({ title: <Trans>Partner Név</Trans>, sorter: true, key: 'partnerNev', width: 320 });
    columns.push({ title: <Trans>Irányítószám</Trans>, sorter: true, key: 'partnerIrsz', width: 200 });
    columns.push({ title: <Trans>Település</Trans>, sorter: true, key: 'partnerTelepules', width: 200 });
    columns.push({ title: <Trans>Cím</Trans>, sorter: true, key: 'partnerCim', width: 200 });
    columns.push({ title: <Trans>Vevői blokk</Trans>, sorter: true, key: 'partnerVevoiBlokk', width: 200 });
    columns.push({ title: <Trans>FizMód</Trans>, sorter: true, key: '6', width: 200 });
    columns.push({ title: <Trans>FizFeltétel</Trans>, sorter: true, key: 'partnerFizFeltetel', width: 200 });
    columns.push({ title: <Trans>SzamlazasGyak</Trans>, sorter: true, key: 'partnerSzamlazasGyak', width: 200 });
    columns.push({ title: <Trans>SzamlaOsszevon</Trans>, sorter: true, key: 'partnerSzamlaOsszevon', width: 200 });
    columns.push({ title: <Trans>SzamlaTipus</Trans>, sorter: true, key: 'partnerSzamlaTipus', width: 200 });
    columns.push({ title: <Trans>Lanc1</Trans>, sorter: true, key: 'partnerLanc1', width: 200 });
    columns.push({ title: <Trans>lanc1Nev</Trans>, sorter: true, key: 'lanc1Nev', width: 200 });
    columns.push({ title: <Trans>partnerLanc2</Trans>, sorter: true, key: 'partnerLanc2', width: 200 });
    columns.push({ title: <Trans>lanc2Nev</Trans>, sorter: true, key: 'lanc2Nev', width: 300 });
    columns.push({ title: <Trans>partnerLanc3</Trans>, sorter: true, key: 'partnerLanc3', width: 200 });
    columns.push({ title: <Trans>lanc3Nev</Trans>, sorter: true, key: 'lanc3Nev', width: 200 });
    columns.push({ title: <Trans>boltKod</Trans>, sorter: true, key: 'boltKod', width: 200 });
    columns.push({ title: <Trans>boltNev</Trans>, sorter: true, key: 'boltNev', width: 300 });
    columns.push({ title: <Trans>boltIrsz</Trans>, sorter: true, key: 'boltIrsz', width: 200 });
    columns.push({ title: <Trans>boltTelepules</Trans>, sorter: true, key: 'boltTelepules', width: 200 });
    columns.push({ title: <Trans>boltCim</Trans>, sorter: true, key: 'boltCim', width: 300 });
    columns.push({ title: <Trans>boltBoltiBlokk</Trans>, sorter: true, key: 'boltBoltiBlokk', width: 200 });
    columns.push({ title: <Trans>boltBoltcsoportkod</Trans>, sorter: true, key: 'boltBoltcsoportkod', width: 200 });
    columns.push({ title: <Trans>boltTerKepviselo</Trans>, sorter: true, key: 'boltTerKepviselo', width: 200 });
    columns.push({ title: <Trans>boltSzallNapok</Trans>, sorter: true, key: 'boltSzallNapok', width: 200 });
    columns.push({ title: <Trans>boltTelephely</Trans>, sorter: true, key: 'boltTelephely', width: 200 });
    columns.push({ title: <Trans>boltSzallPrioritas</Trans>, sorter: true, key: 'boltSzallPrioritas', width: 200 });
    columns.push({ title: <Trans>boltCsatorna</Trans>, sorter: true, key: 'boltCsatorna', width: 200 });
    columns.push({ title: <Trans>boltCsatornaNeve</Trans>, sorter: true, key: 'boltCsatornaNeve', width: 200 });
    columns.push({ title: <Trans>boltBoltKategoria</Trans>, sorter: true, key: 'boltBoltKategoria', width: 200 });
    columns.push({ title: <Trans>boltKategoriaNeve</Trans>, sorter: true, key: 'boltKategoriaNeve', width: 200 });
    columns.push({ title: <Trans>boltBoltBesorol</Trans>, sorter: true, key: 'boltBoltBesorol', width: 200 });
    columns.push({ title: <Trans>boltRendelesMod</Trans>, sorter: true, key: 'boltRendelesMod', width: 200 });
    columns.push({ title: <Trans>boltMinRendeles</Trans>, sorter: true, key: 'boltMinRendeles', width: 200 });
    columns.push({ title: <Trans>boltKategoria2</Trans>, sorter: true, key: 'boltKategoria2', width: 200 });
    columns.push({ title: <Trans>boltKategoria3</Trans>, sorter: true, key: 'boltKategoria3', width: 200 });
    columns.push({ title: <Trans>listazottCsop</Trans>, sorter: true, key: 'listazottCsop', width: 200 });
    columns.push({ key: 'createdBy', width: 200, sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({ key: 'createdDate', width: 200, sorter: true, title: <Trans>Létrehozva</Trans> });

    return {
        columns: columns,
        url: '/resource/elixir-file-contents',
    };
};
export default ElixirFileContentModel();
