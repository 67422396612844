import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';

const WarehouseCompanyModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();
    columns.push({
        key: 'code',
        width: 110,
        sorter: true,
        title: <Trans>Code</Trans>,
        fixed: 'left',
        direction: 'asc',
        render: text => {
            return <b>{text}</b>;
        },
    });
    columns.push({ key: 'name', width: 250, sorter: true, title: <Trans>Name</Trans> });
    columns.push({
        key: 'address.county',
        width: 108,
        dataIndex: ['address', 'county'],
        sorter: false,
        title: <Trans>County</Trans>,
        filter: false,
        render: text => {
            return <div>{text}</div>;
        },
    });
    columns.push({
        key: 'address.postalCode',
        width: 75,
        dataIndex: ['address', 'postalCode'],
        sorter: true,
        title: <Trans>Postal Code</Trans>,
    });
    columns.push({
        key: 'address.city',
        width: 140,
        dataIndex: ['address', 'city'],
        sorter: true,
        title: <Trans>City</Trans>,
    });
    columns.push({
        key: 'address.streetHouseNumber',
        width: 100,
        dataIndex: ['address', 'streetHouseNumber'],
        sorter: true,
        title: <Trans>Address</Trans>,
    });

    columns.push({ key: 'createdBy', width: 140, sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({
        key: 'createdDate',
        filter: false,
        width: 140,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Létrehozva</Trans>,
    });
    columns.push({ key: 'updatedBy', width: 140, sorter: true, title: <Trans>Módosította</Trans> });
    columns.push({
        key: 'updatedDate',
        filter: false,
        width: 140,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Módosítva</Trans>,
    });
    columns.push({ key: 'remark', width: 200, sorter: true, title: <Trans>Megjegyzés</Trans> });

    return {
        columns: columns,
        url: '/resource/warehouse-companies',
    };
};
export default WarehouseCompanyModel();
