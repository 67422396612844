import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import { Tag } from 'antd';

const MessageModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();
    columns.push({
        key: 'messageId.language',
        dataIndex: ['messageId', 'language'],
        width: 80,
        sorter: true,
        title: <Trans>Language</Trans>,
        fixed: 'left',
        uniqueSelectFilterData: [
            {
                label: <Trans>Mind</Trans>,
                value: null,
            },
            {
                label: <Trans>HU</Trans>,
                value: 'HU',
            },
            {
                label: <Trans>EN</Trans>,
                value: 'EN',
            },
        ],

        direction: 'asc',
        render: text => {
            return <b>{text}</b>;
        },
    });
    columns.push({
        key: 'messageId.messageCode',
        dataIndex: ['messageId', 'messageCode'],
        width: 400,
        sorter: true,
        title: <Trans>Message code</Trans>,
    });
    columns.push({ key: 'translated', width: 400, sorter: true, title: <Trans>Translate</Trans> });

    return {
        columns: columns,
        url: '/resource/messages',
    };
};
export default MessageModel();
