import {Link} from 'react-router-dom';
import useSafeState from '../../utils/safe-state';
import React from 'react';
import {Breadcrumb, Button, Popconfirm, Divider, Popover, Tooltip, Spin, Dropdown, Menu} from 'antd';

import Drawer from '../../components/drawer/drawer';
import { Trans } from '@lingui/macro';
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DeleteTwoTone, DollarTwoTone,
    EditTwoTone,
    EllipsisOutlined,
    FlagTwoTone,
    PlusOutlined,
    UploadOutlined,
} from '@ant-design/icons/lib';
import DtoTable from '../../components/dto-table/dto-table';
import WorkRequestsForm from '../../forms/work-requests-drawer/work-requests-drawer';
import WorkRequestsModel from '../../models/dto/work-requests-model';
import { useAxios } from '../../utils/hooks';
import { DownloadOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import ButtonWithIcon from "../../components/buttons/button-with-icon";
import {openNotification} from "../../utils/openNotification";

const WorkRequestsScreen = () => {
    const [templateLoading, setTemplateLoading] = useSafeState(false);
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');
    const { keycloak } = useKeycloak();
    const [isVisibleActionsPopover, setIsVisibleActionsPopover] = useSafeState(true);

    const isAutherized = (roles: any[]) => {
        if (keycloak != null && keycloak.authenticated && keycloak && roles) {
            return roles.some(r => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    };

    const text = <Trans>Work Request</Trans>;
    const drawer = Drawer({
        title: text,
        children: <WorkRequestsForm />,
        customClose: () => setTrigger(!trigger),
    });

    const [trigger, setTrigger] = useSafeState(false);

    const handleDelete = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .put('/resource/work-requests/deleted/id?id=' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott sor sikeresen törölve lett.')
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                });
        }
    };

    const handleComplete = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .put('/resource/work-requests/own-complete/id?id=' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott művelet sikeresen teljesítve lett.');
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                });
        }
    };

    const handleApproveRepairOffer = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .put('/resource/work-requests/approve-repair-offer/id?id=' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az árajánlat sikeresen jóváhagyva.');
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                });
        }
    };

    const handleDenyRepairOffer = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .put('/resource/work-requests/deny-repair-offer/id?id=' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az árajánlat sikeresen elutasítva.');
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                });
        }
    };

    const handleApprove = (id: any) => {

        if (fcrAxiosInstance.current != null) {
            setIsVisibleActionsPopover(false);
            fcrAxiosInstance.current
                .put('/resource/work-requests/approve/id?id=' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott elem sikeresen jóvá lett hagyva.');
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                })
                .finally(() => {
                    setIsVisibleActionsPopover(true);
                });
        }
    };

    const handleDeny = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .put('/resource/work-requests/deny/id?id=' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott elem sikeresen el lett utasítva.');
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('customError', error);
                });
        }
    };
    const handleDownloadTemplateXls = (type: string) => {
        setTemplateLoading(true);
        if (fcrAxiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            fcrAxiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/work-requests/' + lang + '/' + type + '-template/xls/',
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'work-request.xls');
                    setTemplateLoading(false);
                });
        }
    };

    const action = (text: string, record: any) => {
        let isRequired = record.requestStatus === 'REQUIRED' || record.requestStatus === 'MODIFIED';
        let isApproved = record.requestStatus === 'APPROVED';
        let isRequiredOrApproved = isRequired || isApproved;
        let isRepairOfferIsArrived = record.requestStatus === 'REPAIR_OFFER_ARRIVED';

        if (isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER'])) {
            return (
                <>
                    <Popover
                        content={
                            <>
                                <Popconfirm
                                    title={<Trans>Are you sure to approve this work request?</Trans>}
                                    onConfirm={() => (isRequired ? handleApprove(record.id) : null)}
                                    okText={<Trans>Yes</Trans>}
                                    cancelText={<Trans>No</Trans>}
                                    disabled={!isRequired}
                                >
                                    <CheckCircleTwoTone
                                        className={'ActionButtons'}
                                        twoToneColor={isRequired ? '#73d13d' : '#cccdce'}
                                    />

                                </Popconfirm>

                                <Popconfirm
                                    title={<Trans>Are you sure to deny this work request?</Trans>}
                                    onConfirm={() => (isRequired ? handleDeny(record.id) : null)}
                                    okText={<Trans>Yes</Trans>}
                                    cancelText={<Trans>No</Trans>}
                                    disabled={!isRequired}
                                >
                                    <CloseCircleTwoTone
                                        className={'ActionButtons'}
                                        twoToneColor={isRequired ? '#ff7a45' : '#cccdce'}
                                    />
                                </Popconfirm>

                                <EditTwoTone
                                    twoToneColor={isRequired ? '#40a9ff' : '#cccdce'}
                                    className={'ActionButtons'}
                                    onClick={() =>
                                        isRequired
                                            ? drawer.open('Edit', {
                                                  id: record.id,
                                                  shopFrom: record.shopFrom ? record.shopFrom.code : null,
                                                  shopFromName: record.shopFrom ? record.shopFrom.name : null,
                                                  shopFromAddress: record.shopFrom ? record.shopFrom.address : null,
                                                  shopFromRepresentative:
                                                      record.shopFrom && record.shopFrom.regionalRepresentative
                                                          ? record.shopFrom.regionalRepresentative.name
                                                          : null,
                                                  deviceFrom: record.deviceRegisterFrom
                                                      ? record.deviceRegisterFrom.deviceType.name
                                                      : null,
                                                  decorFrom: record.decorFrom ? record.decorFrom.name : null,
                                                  deviceRegisterFrom: record.deviceRegisterFrom
                                                      ? record.deviceRegisterFrom.serialNumber
                                                      : null,
                                                  deviceRegisterFromInventory: record.deviceRegisterFrom
                                                      ? record.deviceRegisterFrom.inventoryNumber
                                                      : null,
                                                  deviceRegisterFromDecor:
                                                      record.deviceRegisterFrom && record.deviceRegisterFrom.decor
                                                          ? record.deviceRegisterFrom.decor.name
                                                          : null,
                                                  deviceRegisterFromPurchasingDate: record.deviceRegisterFrom
                                                      ? record.deviceRegisterFrom.purchasingDate
                                                      : null,

                                                  shopTo: record.shopTo ? record.shopTo.code : null,
                                                  shopToName: record.shopTo ? record.shopTo.name : null,
                                                  shopToAddress: record.shopTo ? record.shopTo.address : null,
                                                  shopToRepresentative:
                                                      record.shopTo && record.shopTo.regionalRepresentative
                                                          ? record.shopTo.regionalRepresentative.name
                                                          : null,
                                                  deviceTo: record.deviceRegisterTo
                                                      ? record.deviceRegisterTo.deviceType.name
                                                      : null,
                                                  decorTo: record.decorTo ? record.decorTo.name : null,
                                                  deviceRegisterTo: record.deviceRegisterTo
                                                      ? record.deviceRegisterTo.serialNumber
                                                      : null,
                                                  deviceRegisterToInventory: record.deviceRegisterTo
                                                      ? record.deviceRegisterTo.inventoryNumber
                                                      : null,
                                                  deviceRegisterToDecor:
                                                      record.deviceRegisterTo && record.deviceRegisterTo.decor
                                                          ? record.deviceRegisterTo.decor.name
                                                          : null,
                                                  deviceRegisterToPurchasingDate: record.deviceRegisterTo
                                                      ? record.deviceRegisterTo.purchasingDate
                                                      : null,

                                                  qty: record.qty,
                                                  requestType: record.requestType,
                                                  requestStatus: record.requestStatus,
                                                  version: record.version,
                                                  projectId: record.projectId,
                                                  remark: record.remark,
                                                    documentsUrl: record.documentsUrl,
                                                auroraNumber: record.auroraNumber,
                                                primeSolsNumber: record.primesolsNumber,
                                                  requestedDate:
                                                      record.requestedDate != null
                                                          ? moment(record.requestedDate)
                                                          : null,
                                                    acceptedDate:
                                                        record.acceptedDate != null
                                                            ? moment(record.acceptedDate)
                                                            : null,
                                                completedDate:
                                                    record.completedDate != null
                                                        ? moment(record.completedDate)
                                                        : null,

                                              })
                                            : null
                                    }
                                />

                                <Popconfirm
                                    title={<Trans>Are you sure to delete this work request?</Trans>}
                                    onConfirm={() => (isRequiredOrApproved ? handleDelete(record.id) : null)}
                                    okText={<Trans>Yes</Trans>}
                                    cancelText={<Trans>No</Trans>}
                                    disabled={!isRequiredOrApproved}
                                >
                                    <DeleteTwoTone
                                        twoToneColor={isRequiredOrApproved ? '#ff4d4f' : '#cccdce'}
                                        className={'ActionButtons'}
                                    />
                                </Popconfirm>
                                <Popconfirm
                                    title={<Trans>Are you sure to complete this work request?</Trans>}
                                    onConfirm={() => (isApproved ? handleComplete(record.id) : null)}
                                    okText={<Trans>Yes</Trans>}
                                    cancelText={<Trans>No</Trans>}
                                    disabled={!isApproved}
                                >
                                    <Tooltip
                                        placement="top"
                                        title={<Trans>COMPLETED</Trans>}
                                        children={
                                            <FlagTwoTone
                                                twoToneColor={isApproved ? '#ff4d4f' : '#cccdce'}
                                                className={'ActionButtons'}
                                            />
                                        }
                                    />
                                </Popconfirm>
                                <Popconfirm
                                    title={<Trans>Are you sure to approve the repair offer on this work request?</Trans>}
                                    onConfirm={() =>
                                        (isRepairOfferIsArrived ?
                                            handleApproveRepairOffer(record.id)
                                            : null)}
                                    okText={<Trans>Yes</Trans>}
                                    cancelText={<Trans>No</Trans>}
                                    disabled={!isRepairOfferIsArrived}
                                >
                                    <Tooltip
                                        placement="top"
                                        title={<Trans>Approve repair offer</Trans>}
                                        children={
                                            <DollarTwoTone
                                                twoToneColor={isRepairOfferIsArrived ? '#73d13d' : '#cccdce'}
                                                className={'ActionButtons'}
                                            />
                                        }
                                    />
                                </Popconfirm>
                                <Popconfirm
                                    title={<Trans>Are you sure to deny the repair offer on this work request?</Trans>}
                                    onConfirm={() =>
                                        (isRepairOfferIsArrived ?
                                            handleDenyRepairOffer(record.id)
                                            : null)}
                                    okText={<Trans>Yes</Trans>}
                                    cancelText={<Trans>No</Trans>}
                                    disabled={!isRepairOfferIsArrived}
                                >
                                    <Tooltip
                                        placement="top"
                                        title={<Trans>Deny repair offer</Trans>}
                                        children={
                                            <DollarTwoTone
                                                twoToneColor={isRepairOfferIsArrived ? '#ff7a45' : '#cccdce'}
                                                className={'ActionButtons'}
                                            />
                                        }
                                    />
                                </Popconfirm>
                            </>
                        }
                        trigger="hover"
                    >
                        <EllipsisOutlined style={{ fontSize: 'x-large' }} />
                    </Popover>
                </>
            );
        }
    };

    const actions = {
        width: 50,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center',
    };

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => handleDownloadTemplateXls('delivery')}>
                    <Trans>Kiszállítás</Trans>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleDownloadTemplateXls('dismantling')}>
                    <Trans>Leszerelés</Trans>
            </Menu.Item>
            <Menu.Item key="3" onClick={() => handleDownloadTemplateXls('transit')}>
                    <Trans>Átszállítás</Trans>
            </Menu.Item>
            <Menu.Item key="4" onClick={() => handleDownloadTemplateXls('replacement')}>
                    <Trans>Csere</Trans>
            </Menu.Item>
            <Menu.Item key="5" onClick={() => handleDownloadTemplateXls('service')}>
                    <Trans>Szervíz</Trans>
            </Menu.Item>
        </Menu>
    );

    const header = (
        <>
            <Button type={'primary'} onClick={() => drawer.open('Add new')} style={{ float: 'left', marginBottom: 10 }}>
                <ButtonWithIcon icon={<PlusOutlined />} text={<Trans>Új hozzáadása</Trans>} />
            </Button>
            {isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER']) ? (
                <>
                    <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />


                    <Dropdown
                        overlay={menu}
                        placement="bottomCenter"
                    >
                        <Button
                            style={{ float: 'left' }}
                            loading={templateLoading}
                        ><DownloadOutlined /><span><Trans>Excel Sablonok</Trans></span></Button>
                    </Dropdown>


                    <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
                    <Button onClick={() => drawer.open('Upload File')} style={{ float: 'left' }}>
                        <ButtonWithIcon
                            icon={<UploadOutlined />}
                            text={<Trans>Csv Feltöltés</Trans>}
                        />
                    </Button>
                    <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
                </>
            ) : (
                <></>
            )}
        </>
    );

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Műveletek</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            {drawer.component}
            <Spin spinning={!isVisibleActionsPopover}>
                <DtoTable
                    model={WorkRequestsModel}
                    tableHeader={header}
                    allowExport={isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER'])}
                    apiUrl={'work-requests'}
                    action={actions}
                    trigger={trigger}
                    pageSize={100}
                    scroll={{ x: 1300, y: '65vh' }}
                />
            </Spin>
        </>
    );
};
export default WorkRequestsScreen;
