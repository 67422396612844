import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { FolderViewOutlined } from '@ant-design/icons/lib';

const ElixirFileModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();
    columns.push({
        key: 'filePath',
        width: 300,
        fixed: 'left',
        sorter: true,
        title: <Trans>Fájl</Trans>,
        render: text => {
            return <b>{text}</b>;
        },
    });

    columns.push({
        key: 'incomingDate',
        width: 200,
        sorter: true,
        title: <Trans>Érkezés időpontja</Trans>,
        direction: 'desc',
    });
    columns.push({ key: 'expirationDate', width: 200, sorter: true, title: <Trans>Érvényesség</Trans> });
    columns.push({ key: 'createdBy', width: 200, sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({ key: 'createdDate', filter: false, width: 200, sorter: true, title: <Trans>Létrehozva</Trans> });

    return {
        columns: columns,
        url: '/resource/elixir-files',
    };
};
export default ElixirFileModel();
