import useSafeState from '../../utils/safe-state';
import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, Switch, message, Select } from 'antd';
import { Trans } from '@lingui/macro';
import { ShopsModel } from '../../models/shops/shops';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { useAxios } from '../../utils/hooks';
import { AxiosResponse } from 'axios';
import { openNotification } from '../../utils/openNotification';


interface Interface {
    data?: ShopsModel[];
    mode?: 'Edit' | 'Add new';
    onClose?: () => void;
}

const ServiceCompaniesForm = (props: Interface) => {
    const data = props.data || {};
    const [form] = Form.useForm();

    const [loading, setLoading] = useSafeState(false);

    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    useEffect(() => {
        form.resetFields();

        if (props.mode === 'Edit') {
            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({ name: key, value: value });
            }
            form.setFields(fieldsValues);
        }
    }, []);

    const onReset = () => {
        form.resetFields();
    };

    const config = { headers: { 'Content-Type': 'application/json' } };

    const handleEdit = () => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .put(
                    '/resource/service-companies/id?id=' + form.getFieldValue(['code']),
                    {
                        code: form.getFieldValue(['code']),
                        name: form.getFieldValue(['name']),
                        address: {
                            postalCode: form.getFieldValue(['postalCode']),
                            city: form.getFieldValue(['city']),
                            streetHouseNumber: form.getFieldValue(['streetHouseNumber']),
                        },
                        remark: form.getFieldValue(['remark']),
                        version: form.getFieldValue(['version']),
                    },
                    config,
                )
                .then(() => {
                    openNotification('success', '', props);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                });
        }
    };

    const handleAddNew = () => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .post(
                    '/resource/service-companies/',
                    {
                        code: form.getFieldValue(['code']),
                        name: form.getFieldValue(['name']),
                        address: {
                            postalCode: form.getFieldValue(['postalCode']),
                            city: form.getFieldValue(['city']),
                            streetHouseNumber: form.getFieldValue(['streetHouseNumber']),
                        },
                        remark: form.getFieldValue(['remark']),
                        version: form.getFieldValue(['version']),
                    },
                    config,
                )
                .then(() => {
                    openNotification('success', '', props);
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                });
        }
    };

    return (
        <Form
            id={props.mode}
            form={form}
            layout="vertical"
            onFinish={() => (props.mode === 'Edit' ? handleEdit() : handleAddNew())}
        >
            {props.mode === 'Edit' || props.mode === 'Add new' ? (
                <>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="code"
                                label={<Trans>Code</Trans>}
                                children={
                                    props.mode === 'Edit' ? (
                                        <Input disabled={true} type={'text'} />
                                    ) : (
                                        <Input type={'text'} />
                                    )
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <Trans>Please enter a code</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label={<Trans>Name</Trans>}
                                children={<Input type={'text'} />}
                                rules={[
                                    {
                                        required: true,
                                        message: <Trans>Please enter a name</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item
                                label={<Trans>Postal Code</Trans>}
                                name="postalCode"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        message: <Trans>Please enter a postal code</Trans>,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={<Trans>Település</Trans>}
                                name="city"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        message: <Trans>Please enter a city</Trans>,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<Trans>Street House Number</Trans>}
                                name="streetHouseNumber"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        message: <Trans>Please enter a street house number</Trans>,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="remark"
                                label={<Trans>Remark</Trans>}
                                children={<Input />}
                                rules={[
                                    {
                                        type: 'string',
                                        pattern: new RegExp(/^(?! +$).+$/),
                                        required: false,
                                        message: <Trans>Please enter a remark</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <br />
                </>
            ) : (
                <>
                    <br />
                </>
            )}
            {props.mode === 'Edit' ? (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
            ) : props.mode === 'Add new' ? (
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() => onReset()}>
                        <Trans>Visszaállítás</Trans>
                    </Button>
                    <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                        <Trans>Hozzáadás</Trans>
                    </Button>
                </>
            ) : (<></>
            )}
        </Form>
    );
};
export default ServiceCompaniesForm;
