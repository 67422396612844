import { Trans } from '@lingui/macro';
import React from 'react';
import { ColumnMain } from '../column-main';
import { Column } from '../column';
import { Tag } from 'antd';

const AuroraConnectionModel = (): ColumnMain => {
    const columns: Array<Column> = new Array();
    columns.push({ key: 'id', sorter: true, title: <Trans>ID</Trans>, fixed: 'left', direction: 'desc' });
    columns.push({
        key: 'requestType',
        uniqueSelectFilterData: [
            {
                label: <Trans>Mind</Trans>,
                value: null,
            },
            {
                label: <Trans>Create</Trans>,
                value: 'POST',
            },
            {
                label: <Trans>Update</Trans>,
                value: 'PUT',
            },
            {
                label: <Trans>Delete</Trans>,
                value: 'DELETE',
            },
        ],
        sorter: true,
        title: <Trans>Kérés típusa</Trans>,
        render: text => {
            let color = 'gray';
            let transText;
            if (text === 'PUT') {
                color = 'gold';
                transText = <Trans>Update</Trans>;
            } else if (text === 'POST') {
                color = 'green';
                transText = <Trans>Create</Trans>;
            } else if (text === 'DELETE') {
                color = 'red';
                transText = <Trans>Delete</Trans>;
            } else if (text === 'GET') {
                color = 'blue';
                transText = <Trans>Get</Trans>;
            }
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });
    columns.push({
        key: 'responseHttpStatusCode',
        sorter: true,
        title: <Trans>Válasz kód</Trans>,
        render: text => {
            let color = 'gray';
            if (text != null && text.startsWith('2')) {
                color = 'green';
            } else {
                color = 'red';
            }
            return (
                <Tag color={color} key={text}>
                    {text}
                </Tag>
            );
        },
    });
    columns.push({ key: 'responseMessage', sorter: true, title: <Trans>Válasz üzenet</Trans> });
    columns.push({ key: 'createdBy', sorter: true, title: <Trans>Létrehozta</Trans> });
    columns.push({ key: 'createdDate', filter: false, sorter: true, title: <Trans>Létrehozva</Trans> });
    columns.push({ key: 'updatedBy', sorter: true, title: <Trans>Módosította</Trans> });
    columns.push({ key: 'updatedDate', filter: false, sorter: true, title: <Trans>Módosítva</Trans> });

    return {
        columns: columns,
        url: '/resource/aurora-request-que-entries',
    };
};
export default AuroraConnectionModel();
