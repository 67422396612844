import { Image } from 'antd';
import React from 'react';

interface Interface {
    height?: number;
    width?: number;
    folder: string;
    fileName: string;
}

const ImageViewer = (props: Interface) => {
    return (
        <Image
            src={
                process.env.REACT_APP_API_BASE_URL +
                '/public/file/get?folder=' +
                props.folder +
                '&filename=' +
                props.fileName
            }
            height={props.height}
            width={props.width}
            alt={'Nincs megjeleníthető kép'}
        >
            {' '}
        </Image>
    );
};
export default ImageViewer;
