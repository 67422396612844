import useSafeState from '../../utils/safe-state';
import { Trans } from '@lingui/macro';
import React from 'react';
import { Breadcrumb, message, Popconfirm, Popover, Space } from 'antd';
import DtoTable from '../../components/dto-table/dto-table';
import StockTakingHeadModel from '../../models/dto/stock-taking-head-model';
import { Link } from 'react-router-dom';
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    EllipsisOutlined,
    EyeOutlined,
} from '@ant-design/icons/lib';
import { useKeycloak } from '@react-keycloak/web';
import { useAxios } from '../../utils/hooks';
import {openNotification} from "../../utils/openNotification";


const StockTakingScreen = () => {
    const [trigger, setTrigger] = useSafeState(false);
    const { keycloak } = useKeycloak();
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + '');

    const isAutherized = (roles: any[]) => {
        if (keycloak != null && keycloak.authenticated && keycloak && roles) {
            return roles.some(r => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    };

    const action = (text: string, record: any) => {
        let isClosed = record.status === 'CLOSED';

        if (isAutherized(['FCR_ADMIN', 'FCR_REGIONAL_LEADER', 'FCR_RETAIL_LEADER'])) {
            return (
                <>
                    <Popover
                        content={
                            <>
                                <Space size="middle">
                                    <Link to={'/stock-taking-lines/' + record.id}>
                                        <EyeOutlined className={'ActionButtons'} style={{ color: '#40a9ff' }} />
                                    </Link>
                                </Space>

                                <Popconfirm
                                    title={<Trans>Are you sure to approve this work request?</Trans>}
                                    onConfirm={() => (isClosed ? handleApprove(record.id) : null)}
                                    okText={<Trans>Yes</Trans>}
                                    cancelText={<Trans>No</Trans>}
                                    disabled={!isClosed}
                                >
                                    <CheckCircleTwoTone
                                        className={'ActionButtons'}
                                        twoToneColor={isClosed ? '#73d13d' : '#cccdce'}
                                    />
                                </Popconfirm>

                                <Popconfirm
                                    title={<Trans>Are you sure to deny this work request?</Trans>}
                                    onConfirm={() => (isClosed ? handleDeny(record.id) : null)}
                                    okText={<Trans>Yes</Trans>}
                                    cancelText={<Trans>No</Trans>}
                                    disabled={!isClosed}
                                >
                                    <CloseCircleTwoTone
                                        className={'ActionButtons'}
                                        twoToneColor={isClosed ? '#ff7a45' : '#cccdce'}
                                    />
                                </Popconfirm>

                                <Popconfirm
                                    title={<Trans>Are you sure to delete this work request?</Trans>}
                                    onConfirm={() => (isClosed ? handleDelete(record.id) : null)}
                                    okText={<Trans>Yes</Trans>}
                                    cancelText={<Trans>No</Trans>}
                                    disabled={!isClosed}
                                >
                                    <DeleteTwoTone
                                        twoToneColor={isClosed ? '#ff4d4f' : '#cccdce'}
                                        className={'ActionButtons'}
                                    />
                                </Popconfirm>
                            </>
                        }
                        trigger="hover"
                    >
                        <EllipsisOutlined style={{ fontSize: 'x-large' }} />
                    </Popover>
                </>
            );
        }
    };

    const handleDelete = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .delete('/resource/stock-taking-heads/' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott sor sikeresen törölve lett.')
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('customError', 'Hiba a mentés közben')
                });
        }
    };

    const handleApprove = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .put('/resource/stock-taking-heads/approve/' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott elem sikeresen jóvá lett hagyva.');
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                });
        }
    };

    const handleDeny = (id: any) => {
        if (fcrAxiosInstance.current != null) {
            fcrAxiosInstance.current
                .put('/resource/stock-taking-heads/deny/' + id)
                .then(() => {
                    openNotification('customSuccess', 'Az adott elem sikeresen el lett utasítva.');
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                });
        }
    };

    const actions = {
        width: 50,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center',
    };

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Kezdőoldal</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Leltár</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable
                model={StockTakingHeadModel}
                allowExport={true}
                apiUrl={'stock-taking-heads'}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default StockTakingScreen;
